import { useDaily, useDevices, useLocalSessionId, useMeetingSessionState, useMeetingState, useParticipantIds, useParticipantProperty, useScreenShare } from "@daily-co/daily-react"
import { useContext, useEffect, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Excalidraw } from "@excalidraw/excalidraw"
import api from "../../api"
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg"
import { darkPalette } from "../../common/constants"
import { Each } from "../../common/Each"
import MainContext from "../../common/MainContext"
import Button from "../Button"
import DailyChat from "./DailyChat"
import DailyControls from "./DailyControls"
import DailyParticipant from "./DailyParticipant"
import styles from "./DailyRoom.module.css"
import DailySpotlighted from "./DailySpotlighted"

const DailyRoom = ({ lesson_id, whiteboard, onWhiteboardChange }) => {

    const context = useContext(MainContext)
    const call = useDaily()
    const devices = useDevices()
    const meetingState = useMeetingState()
    const localSessionId = useLocalSessionId()
    const participants = useParticipantIds({ filter: 'remote' })
    const { screens } = useScreenShare()
    const ownersIds = useParticipantIds({ filter: (p) => p.owner })
    const owner = useParticipantProperty(ownersIds[0], ['user_id','userData', 'video', 'audio'])
    const { data: sessionData } = useMeetingSessionState()

    const [spotlightedId, setSpotlightedId] = useState(null)
    const [showParticipants, setShowPartecipants] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [chat, setChat] = useState(false)
    const [unread, setUnread] = useState(0)
    const [excalidrawAPI, setExcalidrawAPI] = useState(null)
    const [accessState, setAccessState] = useState('unknow')

    const fullScreenHandle = useFullScreenHandle()

    useEffect(() => {

        const addUserParticipantId = async () => {
            try {
                await api.put(`/lessons/${lesson_id}/participant`, { participant_id: localSessionId })
            }
            catch (e) {
                console.error(e)
            }
        }

        if (localSessionId) {
            addUserParticipantId()
            const randomColor = darkPalette[Math.floor(Math.random() * darkPalette.length)];
            call.setUserName(`${context.user?.name} ${context.user.surname}`)
            call.setUserData({ ...context.user, color: randomColor })
        }

    }, [localSessionId])

    useEffect(() => {
        if (sessionData?.spotlighted) {
            setSpotlightedId(sessionData.spotlighted)
        }
        else {
            if (owner) {
                if(screens.find(s => s.session_id === localSessionId)){
                    setSpotlightedId(localSessionId)
                }
                else{
                    setSpotlightedId(owner[0])
                }
            }
            else {
                onWhiteboardChange({show: false, data: []})
                setSpotlightedId(localSessionId)
            }
        }
    }, [screens, localSessionId, owner, sessionData])

    useEffect(() => {
        if (devices.cameras.length > 0) {
            if (!devices.currentCam) {
                devices.setCamera(devices.cameras[0].device.deviceId)
            }
        }
        if (devices.speakers.length > 0) {
            if (!devices.currentSpeaker) {
                devices.setSpeaker(devices.speakers[0].device.deviceId)
            }
        }
        if (devices.microphones.length > 0) {
            if (!devices.currentMic) {
                devices.setMicrophone(devices.microphones[0].device.deviceId)
            }
        }
    }, [devices])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })
    }, [])

    useEffect(() => {

        const asyncAccess = async() => {
            let resp = await call.requestAccess({ level: 'full', name: `${context.user.name} ${context.user.surname}` })
            if(resp){
                setAccessState(resp.access.level)
            }
        }

        if (call) {
            const accessState = call.accessState()
            setAccessState(accessState.awaitingAccess ? accessState.awaitingAccess.level : accessState.access.level)
            if (accessState.access.level === 'lobby' && ['joined-meeting', 'new'].includes(meetingState)) {
                asyncAccess()
            }
        }
        if (meetingState === 'error') {
            setAccessState('error')
        }
    }, [call, meetingState])

    useEffect(() => {
        if (excalidrawAPI) {
            excalidrawAPI.updateScene({ elements: whiteboard.data })
        }
        if(accessState === 'lobby'){
            setSpotlightedId(localSessionId)
        }
    }, [whiteboard, excalidrawAPI, localSessionId])

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
            <div className={styles.container}>
                <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                    <div className={styles.spotlighted}>
                        {spotlightedId && !whiteboard.show &&
                            <DailySpotlighted
                                session_id={spotlightedId}
                                isLocal={spotlightedId === localSessionId}
                                isScreenShare={!!screens.find(s => s.session_id === spotlightedId)} />
                        }

                        {whiteboard.show && call.accessState().access.level === 'full' &&
                            <div style={{ display: 'flex', minHeight: '500px', width: '100%' }}>
                                <Excalidraw viewModeEnabled={true} excalidrawAPI={(api) => setExcalidrawAPI(api)} />
                            </div>
                        }

                        {sessionData?.spotlighted === spotlightedId &&
                            <div className={styles.pinBadge}>
                                <PinIcon />
                                Fissato
                            </div>
                        }

                        {call.accessState().access.level !== 'full' &&
                            <div className={styles.overlay}>
                                {accessState === 'lobby' &&
                                    <div>
                                        In attesa che il docente ti ammetta alla lezione...
                                    </div>
                                }
                                {accessState === 'error' &&
                                    <div>
                                        Non sei più all'interno della lezione.
                                        <Button onClick={() => { call.join() }}>RIENTRA</Button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <DailyChat open={chat} unread={{ state: unread, setState: setUnread }} />
                </div>
                <div className={`${styles.participants} ${!showParticipants ? styles.close : ''}`}>
                    <div className={styles.participantsTrack}>
                        <DailyParticipant
                            isLocal={true}
                            session_id={localSessionId}
                            onClick={() => {
                                setSpotlightedId(localSessionId)
                            }} />
                        <Each of={participants} render={(p, index) => {
                            return (
                                <>
                                    <DailyParticipant
                                        session_id={p}
                                        onClick={(session_id) => {
                                            setSpotlightedId(session_id)
                                        }} />
                                </>
                            )
                        }} />
                    </div>
                </div>


                <DailyControls
                    localSessionId={localSessionId}
                    showParticipants={showParticipants}
                    onShowParticipantsChange={() => { setShowPartecipants(!showParticipants) }}
                    fullScreen={fullScreen}
                    onFullScreenChange={() => {
                        fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter()
                        setFullScreen(!fullScreen)
                    }}
                    chat={chat}
                    onChatChange={() => {
                        if (!chat) {
                            setUnread(0)
                        }
                        setChat(!chat)
                    }}
                    unread={unread}
                />
            </div>
        </FullScreen>
    )
}

export default DailyRoom
