import { motion } from "framer-motion"

const Wave = ({style, duration, delay, ...props}) => {

    const wave = {
        hidden: {
            pathLength: 0,
            strokeWidth: 0,
            stroke: "rgba(255, 255, 255, 0)"
        },
        visible: {
            pathLength: 1,
            strokeWidth: 10.9375,
            stroke: "#6D4AFF"
        }
    }

    return (
        <svg width="358" height="26" viewBox="0 0 358 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} {...props}>
            <motion.path
                d="M4 13.4688C11.2917 6.17708 18.5833 6.17708 25.875 13.4688C33.1667 20.7604 40.4583 20.7604 47.75 13.4688C55.0417 6.17708 62.3333 6.17708 69.625 13.4688C76.9167 20.7604 84.2083 20.7604 91.5 13.4688C98.7917 6.17708 106.083 6.17708 113.375 13.4688C120.667 20.7604 127.958 20.7604 135.25 13.4688C142.542 6.17708 149.833 6.17708 157.125 13.4688C164.417 20.7604 171.708 20.7604 179 13.4688C186.292 6.17708 193.583 6.17708 200.875 13.4688C208.167 20.7604 215.458 20.7604 222.75 13.4688C230.042 6.17708 237.333 6.17708 244.625 13.4688C251.917 20.7604 259.208 20.7604 266.5 13.4688C273.792 6.17708 281.083 6.17708 288.375 13.4688C295.667 20.7604 302.958 20.7604 310.25 13.4688C317.542 6.17708 324.833 6.17708 332.125 13.4688C339.417 20.7604 346.708 20.7604 354 13.4688"
                variants={wave}
                initial="hidden"
                animate="visible" 
                transition={{duration: duration ?? 3, delay: delay ?? 0}}
            />
        </svg>
    )

}

export default Wave