import styles from "./CareerCard.module.css"
import Card from "./Card"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import { hexAlpha } from "../../utils"

const CareerCard = ({ career }) => {

    return (
        <a href={`/careers/${career.slug}`}>
        <Card style={{ borderRadius: '12px', padding: 0, flexDirection: 'column' }}>
            <div className={styles.gradient} style={{background: `linear-gradient(180deg, ${hexAlpha(career.color,20)} 0%, ${hexAlpha(career.color,0)} 50%)`}}/>
            <img className={styles.thumbnail} src={career.thumbnail} alt="career-thumbnail" />
            <div className={styles.title}>{career.name.toUpperCase()}</div>
            {career.tags?.length > 0 &&
                <>
                    <div className={styles.divider} />
                    <div className={styles.tags}>
                        <Each of={career.tags} render={(tag) => {
                            return <Tag tag={tag} />
                        }} />
                    </div>
                </>
            }
        </Card>
        </a>
    )
}

export default CareerCard