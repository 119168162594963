import { useContext, useEffect } from "react"
import styles from "./NotificationBanner.module.css"
import MainContext from "../common/MainContext"
import { AnimatePresence, motion } from "framer-motion"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { useNavigate } from "react-router-dom"

const NotificationBanner = ({ notification }) => {

    const context = useContext(MainContext)
    const { width } = useWindowDimensions()
    const navigate = useNavigate()

    useEffect(() => {
        if (context.notificationBanner) {
            setTimeout(() => {
                context.setNotificationBanner(null)
            }, 10000)
        }
    }, [context.notificationBanner])

    const handleClose = (e) => {
        e.stopPropagation();
        context.setNotificationBanner(null)
    }

    const handleNotificationClick = () => {
        if (notification.url) {
            context.setDropdown(null)
            if (notification.url.includes('.')) {
                window.location.href = notification.url
            }
            else {
                navigate(notification.url)
            }
        }
        context.setNotificationBanner(null)
    }

    return (
        <AnimatePresence>
            {context.notificationBanner &&
                <motion.div
                    onClick={handleNotificationClick}
                    style={{cursor: notification.url ? 'pointer' : 'default'}}
                    initial={{ x: width > 540 ? '100%' : 0, y: width > 540 ? 0 : '-200%' }}
                    animate={{ x: 0, y: 0 }}
                    exit={{ x: width > 540 ? '100%' : 0, y: width > 540 ? 0 : '-200%' }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    className={styles.container}>
                    {notification.image &&
                        <img src={notification.image} className={styles.thumbnail} alt="" />
                    }
                    <div className={styles.info}>
                        <div className={styles.title}>
                            {notification.title}
                        </div>
                        <div className={styles.body}>
                            {notification.body}
                        </div>
                    </div>
                    <button className={styles.close} onClick={handleClose}>
                        <CloseIcon className={styles.closeIcon} />
                    </button>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default NotificationBanner