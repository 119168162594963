import axios from "axios";
import { auth } from "./firebase"
import { signInAnonymously } from "firebase/auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: "30000"
})

api.interceptors.request.use(async (config) => {
  // Ottieni il token

  if (!auth.currentUser) {
    console.error(`Current User is null during ${config.url}`)
  }

  const tokenResult = await auth.currentUser.getIdTokenResult()
  const token = tokenResult.token

  // Aggiungi il token all'header Authorization
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, (error) => {
  // Gestisci errori nell'interceptor
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  if (![200, 201, 204].includes(response.status)) {
    return Promise.reject(response.data)
  }
  return response.data
}, async (error) => {

  if (error.response.status === 401) {
    await auth.signOut()
    await signInAnonymously(auth)
    if (window.location.pathname !== "/login") {
      window.location.href = '/login'
    }
  }

  if (error.response.data) {
    document.dispatchEvent(new CustomEvent('api-error', { detail: error.response.data }));
    return Promise.reject(error.response.data)
  }

  return Promise.reject(error)

})

export default api;
