import { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import ReactMarkdown from "react-markdown"
import ReactPlayer from "react-player"
import { useParams } from "react-router-dom"
import remarkGfm from "remark-gfm"
import api from "../api"
import { ReactComponent as ShareIcon } from "../assets/images/icons/ic-share.svg"
import { ReactComponent as ThumbDownIconFill } from "../assets/images/icons/ic-thumb-down-fill.svg"
import { ReactComponent as ThumbDownIcon } from "../assets/images/icons/ic-thumb-down.svg"
import { ReactComponent as ThumbUpIconFill } from "../assets/images/icons/ic-thumb-up-fill.svg"
import { ReactComponent as ThumbUpIcon } from "../assets/images/icons/ic-thumb-up.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import Tag from "../components/Tag"
import VideoCard from "../components/cards/VideoCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Video.module.css"
import useWindowDimensions from "../common/hooks/useWindowDimensions"

const Video = () => {

    const { slug } = useParams()
    const [video, setVideo] = useState(null)
    const [fullDescription, setFullDescription] = useState(false)
    const [related, setRelated] = useState(null)
    const { width } = useWindowDimensions()

    useEffect(() => {

        const getVideoBySlug = async () => {
            try {
                let video = await api.get(`/videos/slug/${slug}`)
                setVideo(video)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!video) {
            getVideoBySlug()
        }

    }, [slug])

    useEffect(() => {

        const getRelated = async () => {
            try {
                let related = await api.get(`/videos/${video.id}/related`)
                setRelated(related)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (video && !related) {
            getRelated()
        }
    }, [video])

    const rateVideo = async (liked) => {
        try {
            let rating = await api.post(`/videos/${video.id}/ratings`, { liked: liked })
            setVideo({ ...video, liked: rating.liked })
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>{video ? video.title : 'Video'}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {video &&
                    <>
                        <div className={styles.section}>
                            <div className={styles.sectionInner}>
                                <div className={styles.playerWrapper}>
                                    <ReactPlayer
                                        url={video.url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                        playIcon={<PlayIcon />}
                                        light={video.thumbnail}
                                        config={
                                            {
                                                youtube: {
                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className={styles.header}>
                                    <div className={typo.subtitle}>{video.title}</div>
                                    <div className={styles.headerActions}>
                                        <div className={styles.rating}>
                                            <div className={styles.like} onClick={() => {
                                                setVideo((prev) => {
                                                    prev.liked = true
                                                    return { ...prev }
                                                })
                                                rateVideo(true)
                                            }}>
                                                {video.liked === true &&
                                                    <ThumbUpIconFill />
                                                }
                                                {!video.liked &&
                                                    <ThumbUpIcon />
                                                }
                                            </div>
                                            <div className={styles.dislike} onClick={() => {
                                                setVideo((prev) => {
                                                    prev.liked = false
                                                    return { ...prev }
                                                })
                                                rateVideo(false)
                                            }}>
                                                {video.liked === false &&
                                                    <ThumbDownIconFill />
                                                }
                                                {(video.liked == null || video.liked === true) &&
                                                    <ThumbDownIcon />
                                                }
                                            </div>
                                        </div>
                                        <Button onClick={async () => {
                                            try {
                                                await navigator.share({ title: video.title, url: `https://beta.velv.academy/videos/${video.slug}` });
                                            } catch (e) {
                                                console.error(e)
                                            }
                                        }} style={{ padding: '0.7rem 1rem' }}>CONDIVIDI <ShareIcon /></Button>
                                    </div>
                                </div>
                                <div className={`${styles.description} ${fullDescription ? styles.full : ''}`}>
                                    <div className={styles.descriptionInner}>
                                        <ReactMarkdown children={video.description} remarkPlugins={[remarkGfm]} />
                                    </div>
                                    {!fullDescription &&
                                        <div className={styles.gradient} />
                                    }
                                </div>
                                <Button appearance="text" style={{ padding: 0, width: 'fit-content' }} onClick={() => {
                                    setFullDescription(!fullDescription)
                                }}> {fullDescription && 'Mostra meno'} {!fullDescription && 'Mostra altro'}</Button>
                                <div className={styles.tags}>
                                    <Each of={video.tags} render={(tag) => {
                                        return (
                                            <Tag tag={tag} />
                                        )
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>Correlati</div>
                                <div className={styles.videos}>
                                        <Each of={related} render={(v) => {
                                            return (
                                                <VideoCard video={v} style={{maxWidth: width <= 540 ? 'unset' : '350px'}}/>
                                            )
                                        }} />
                                    </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </HeaderFooterLayout>
    )
}

export default Video