import { auth } from './firebase'

const RECONNECT_TIMEOUT = 5000;

class WebSocketClient {
    constructor(path, onMessageReceived) {
        this.path = path;
        this.onMessageReceived = onMessageReceived;
        this.socket = null;
        this.reconnectInterval = null;
    }

    async init() {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_WEBSOCKET_URL}/ws/${this.path}?token=${token}&type=user`

        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            console.debug('WebSocket connection opened.');
            clearTimeout(this.reconnectInterval);
            document.dispatchEvent(new CustomEvent(`${this.path}-websocket-connected`));
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.onMessageReceived(data); // Richiama la funzione fornita per gestire il messaggio ricevuto
        };

        this.socket.onclose = () => {
            console.debug('WebSocket connection closed.');
            document.dispatchEvent(new CustomEvent(`${this.path}-websocket-reconnecting`));
            this.reconnectInterval = setTimeout(() => {
                document.dispatchEvent(new CustomEvent(`${this.path}-websocket-reconnecting`));
                this.init(); // Tentativo di riconnessione
            }, RECONNECT_TIMEOUT);
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    sendMessage(message) {
        if (this.socket) {
            try {
                this.socket.send(JSON.stringify(message));
            } catch (e) {
                console.error(e);
            }
        } else {
            console.debug("Socket is null");
        }
    }

    close() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
            clearTimeout(this.reconnectInterval);
        }
    }
}

export default WebSocketClient;