import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import { Each } from "../../common/Each"
import { CorrectionType, TestType } from "../../enums"
import styles from "./TestTextCompletion.module.css"

const TestTextCompletion = ({
    id = null,
    words = [],
    testType = null,
    onSelect = () => { },
    responses = null,
    correctionType = null // se presente si mostrano i risultati
}) => {

    const { t } = useTranslation()
    const [userAnswers, setUserAnswers] = useState(words.map((w, i) => { return { value: "", index: w.hidden ? i : null } }).filter(w => w.index !== null))
    const [wordSolutions, setWordSolutions] = useState([])
    const type = useMemo(() => {
        if (!testType)
            return null

        switch (testType) {
            case TestType.SingleChoice: return "singleChoice"
            case TestType.MultipleChoice: return "multipleChoice"
            case TestType.TrueFalse: return "trueFalse"
            case TestType.Matching: return "matching"
            case TestType.TextCompletion: return "textCompletion"
            default: console.error("invalid test type"); break
        }
    }, [testType])

    useEffect(() => {
        if (responses && !correctionType) {
            setUserAnswers([...responses])
        }
    }, [words])

    useEffect(() => {
        if (correctionType) {
            for (let i = 0; i < words.length; i++) {
                if (words[i].hidden) {
                    if (responses.responses) {
                        const ur = responses.responses.find(r => r.index === i)
                        if (ur) {
                            words[i].userResponse = {
                                ...ur,
                                isCorrect: words[i].solutions.map(s => s.toLowerCase()).includes(ur.value.toLowerCase())
                            }
                        }
                    }
                }
            }
            setWordSolutions([...words])
        }

    }, [correctionType])


    useEffect(() => {
        // const isValid = isTestTextCompletionValid(userAnswers)
        onSelect(id, testType, true, userAnswers)
    }, [userAnswers])

    const onAnswerChange = useCallback((e, index) => {
        setUserAnswers((p) => {
            const idx = userAnswers.findIndex(i => i.index === index)
            if (idx >= 0) {
                p[idx].value = e.target.value
            }
            return [...p]
        })
    }, [userAnswers])

    return (
        <>
            {
                !correctionType &&
                <div className={styles.container}>
                    <div className={styles.text}>{t(`tests.testType.${type}`)}</div>
                    <div className={styles.words}>
                        <Each
                            of={words}
                            render={(word, index) => (
                                <>
                                    {word.hidden &&
                                        <input
                                            type="text"
                                            className={styles.inputWord}
                                            value={userAnswers.find(i => i.index === index)?.value ?? ""}
                                            onChange={(e) => onAnswerChange(e, index)}
                                            placeholder={t("tests.typeHere")}
                                        />
                                    }

                                    {!word.hidden &&
                                        <ReactMarkdown children={word.markdown} className={styles.word} />
                                    }
                                </>
                            )}
                        />

                    </div>

                </div >
            }
            {
                correctionType &&
                <div className={styles.textCompletion}>
                    <div className={styles.words}>
                        <Each
                            of={wordSolutions}
                            render={(word, index) => (
                                <>
                                    {
                                        !word.hidden && <div className={styles.word}>{word.text}</div>
                                    }
                                    {
                                        word.hidden && word.userResponse.isCorrect && <div className={styles.hiddenWord}>{word.userResponse.value}</div>
                                    }
                                    {
                                        word.hidden && !word.userResponse.isCorrect && (correctionType === CorrectionType.Solutions) &&
                                        <div className={`${styles.hiddenWord} ${styles.wrong}`}>
                                            <span>{word.userResponse.value}</span> <span>{word.text}</span>

                                        </div>
                                    }
                                    {
                                        word.hidden && !word.userResponse.isCorrect && (correctionType !== CorrectionType.Solutions) &&
                                        <div className={`${styles.hiddenWord} ${styles.wrong}`}>
                                            {word.userResponse.value || "Nessuna risposta"}
                                        </div>
                                    }
                                </>

                            )}
                        />
                    </div>
                    {
                        correctionType === CorrectionType.Solutions &&
                        <div className={styles.solutions}>
                            <Each
                                of={wordSolutions.filter(w => w.hidden)}
                                render={(hiddenWord, index) => (
                                    <div className={styles.solutionContainer}>
                                        <div>{`${index + 1}. `}{hiddenWord.userResponse.value}</div>
                                        {hiddenWord.userResponse.value && "->"}
                                        <div className={styles.solution}>{hiddenWord.solutions.join(", ")}</div>
                                    </div>
                                )}
                            />
                        </div>
                    }
                </div>
            }
        </>

    )
}


export default TestTextCompletion
