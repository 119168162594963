import { useEffect, useState } from "react"
import styles from "./StepHighlight.module.css"

const StepHighlight = ({ nSteps, currentStep, }) => {

  const [width, setWidth] = useState(null)

  useEffect(() => {
    setWidth(Math.min(Math.round(100 / nSteps) * currentStep, 100))
  }, [nSteps, currentStep])

  return (
    <div className={styles.container}>
      <div
        className={styles.bar}
        style={
          {
            width: `${width}%`,
            left: `0`,
          }
        }
      />

    </div>
  )
}

export default StepHighlight
