import useWindowDimensions from "../common/hooks/useWindowDimensions"
import styles from "./BottomBar.module.css"
import Button from "./Button"
import { ReactComponent as Arrow } from "../assets/images/icons/ic-arrow.svg"

const BottomBar = ({ backHidden = false, backDisabled = true, onBack = () => { }, continueDisabled = true, onContinue = () => { }, continueLabel = "CONTINUA" }) => {

    const { width } = useWindowDimensions()

    return (
        <div className={`${styles.container} ${backHidden ? styles.centered : ''}`}>
            {!backHidden &&
                <Button 
                style={{padding: width > 540 ? '1rem 3rem' : '.75rem 2rem', fontSize: width > 540 ? '1.125rem' : '.8rem'}}
                inverse 
                disabled={backDisabled} 
                onClick={onBack}>
                    {width > 540 &&
                        <> <Arrow></Arrow> INDIETRO </>
                    }
                    {width <= 540 &&
                        'INDIETRO'
                    }
                </Button>
            }
            <Button 
                style={{padding: width > 540 ? '1rem 3rem' : '.75rem 2rem', fontSize: width > 540 ? '1.125rem' : '.8rem'}}
                disabled={continueDisabled} 
            onClick={onContinue}>
                {/* {width > 540 ? continueLabel : <Arrow style={{ transform: 'scaleX(-1)' }}></Arrow>}  */}
                {continueLabel}
            </Button>
        </div >
    )

}

export default BottomBar