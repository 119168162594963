import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import typo from "../typography.module.css";
import styles from "./Career.module.css";
import CareerProgress from "../components/CareerProgress"
import { ReactComponent as SalaryIcon } from "../assets/images/icons/ic-salary.svg"
import { ReactComponent as InfoIcon } from "../assets/images/icons/ic-info.svg"
import { ReactComponent as BookIcon } from "../assets/images/icons/ic-book-inactive.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/ic-cart.svg";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import { Each } from "../common/Each";
import Tag from "../components/Tag"
import CourseBadge from "../components/CourseBadge";
import CourseCard from "../components/cards/CourseCard"
import { ArticleCardType, TagType } from "../common/constants";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../utils";
import Button from "../components/Button";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import ArticleCard from "../components/cards/ArticleCard";
import { AnimatePresence, motion } from "framer-motion";
import {ReactComponent as PlayIcon} from "../assets/images/illustrations/il-play-button.svg"
import { HelmetProvider, Helmet } from "react-helmet-async";

const Career = () => {

    const { t } = useTranslation()
    const { slug } = useParams();
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const [career, setCareer] = useState(null)
    const [discount, setDiscount] = useState(null)
    const [course, setCourse] = useState(null)
    const [relatedCourses, setRelatedCourses] = useState([])
    const [relatedArticles, setRelatedArticles] = useState([])
    const [partners, setPartners] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        const getBySlug = async () => {
            try {
                let career = await api.get(`/careers/slug/${slug}`)
                setCareer(career)
            }
            catch {
                navigate("/404")
            }
        }
        getBySlug()
    }, [slug])

    useEffect(() => {
        const getRelatedCourses = async () => {
            try {
                let relatedCourses = await api.get(`/careers/${career.id}/courses`)
                setRelatedCourses(relatedCourses)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (career) {
            getRelatedCourses()
        }
    }, [career])

    useEffect(() => {
        const getCourse = async () => {
            try {
                let course = await api.get(`/careers/${career.id}/course`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (career) {
            getCourse()
        }
    }, [career])

    useEffect(() => {
        const getPartners = async () => {
            try {
                let partners = await api.get(`/careers/${career.id}/partners`)
                setPartners(partners)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (career) {
            getPartners()
        }
    }, [career])

    useEffect(() => {
        if (course) {
            if (course.product) {
                let discount = (course.product.price - course.product.discounted_price) / course.product.price * 100
                setDiscount(Math.round(discount))
            }
        }
    }, [course])


    useEffect(() => {
        const getRelatedArticles = async () => {
            try {
                let relatedCourses = await api.get(`/careers/${career.id}/articles`)
                setRelatedArticles(relatedCourses)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (career) {
            getRelatedArticles()
        }
    }, [career])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>{career ? career.name : 'Carriera'}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {career &&
                    <>
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)' }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.careerLeft}>
                                    <div className={styles.playerWrapper}>
                                        <ReactPlayer
                                            url={career.video_url}
                                            controls
                                            width="100%"
                                            height="100%"
                                            className={styles.player}
                                            playIcon={<PlayIcon />}
                                            light={"https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/thumbnails/cover-analista-finanziario.png"}
                                            config={
                                                {
                                                    youtube: {
                                                        playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={styles.careerRight}>
                                    <div className={typo.subtitle}>{career.name}</div>
                                    <div className={styles.mobilePlayerWrapper}>
                                        <ReactPlayer
                                            url={career.video_url}
                                            controls
                                            width="100%"
                                            height="100%"
                                            className={styles.player}
                                            playIcon={<PlayIcon />}
                                            light={true}
                                            config={
                                                {
                                                    youtube: {
                                                        playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    {/* <CareerProgress name={t('career.yourProgress')}
                                        style={{ maxWidth: width > 540 ? '360px' : 'none' }}
                                    /> */}
                                    <div className={styles.salary}>
                                        <SalaryIcon></SalaryIcon>
                                        <div className={styles.salaryLabel}>{t('career.salaryAvg').toUpperCase()}</div>
                                        <div className={styles.salaryValue}>{career.salary_avg}</div>
                                        <div className={styles.spacer} />
                                        <InfoIcon></InfoIcon>
                                    </div>
                                    <div className={typo.body}>
                                        {career.description}
                                    </div>
                                    <div className={styles.tags}>
                                        <Each of={career.tags} render={(tag) => {
                                            return <Tag tag={tag} />
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AnimatePresence>
                            {course &&
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    className={`${styles.section} ${styles.bordered}`}>
                                    <div className={styles.sectionInner}>
                                        <div className={styles.courseLeft}>
                                            <CourseBadge type={course.type}></CourseBadge>
                                            <div className={typo.subtitle}>{course.name}</div>
                                            <img className={styles.thumbnailMobile} src={course.thumbnail} alt="course-thumbnail-mobile" />

                                            <div className={typo.body}>{course.description}</div>
                                            <div className={styles.featureRow}>
                                                <div className={styles.feature}>
                                                    <BookIcon></BookIcon> {course.program.length} moduli
                                                    <ClockIcon></ClockIcon> {course.duration} ore
                                                </div>
                                                <Each of={course.tags} render={(tag) => {
                                                    if (tag.type === TagType.Feature) {
                                                        return (
                                                            <div className={styles.feature}>
                                                                <img src={tag.icon} alt={tag.name} /> {tag.name}
                                                            </div>
                                                        )
                                                    }
                                                }} />
                                            </div>
                                            <div className={styles.priceRow}>
                                                {discount &&
                                                    <>
                                                        <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.discounted_price)}€</div>
                                                        <div className={styles.discount}><div>{formatPrice(course.product.price)}€</div> {t('professional.discountOf')} {discount}%</div>
                                                    </>
                                                }
                                                {!discount &&
                                                    <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.price)}€</div>
                                                }
                                            </div>
                                            <div className={styles.actionRow}>
                                                <div>
                                                    <Button style={{ fontSize: '1.15rem', width: '100%', padding: '.5rem' }}
                                                        onClick={() => {
                                                            navigate(`/courses/${course.slug}`)
                                                        }}>
                                                        <CartIcon></CartIcon>{t('courses.purchase').toUpperCase()}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.courseRight}>
                                            <img className={styles.thumbnail} src={course.thumbnail} alt="course-thumbnail" />
                                        </div>
                                    </div>
                                </motion.div>
                            }
                        </AnimatePresence>

                        {partners.length > 0 &&
                            <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)', paddingBottom: 0 }}>
                                <div id={styles['sectionInner']} className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                    <div className={`${typo.title} ${styles.sectionTitle}`} style={{ fontSize: '1.15rem' }}>
                                        {t('career.partners')}
                                    </div>
                                    <div className={styles.partners}>
                                        <div className={styles.partnersTrack}>
                                            <Each of={career.partners} render={(partner) => {
                                                return (
                                                    <div className={styles.partnerCard}>
                                                        <img className={styles.partnerLogo} alt={`${partner}-logo`} src={partner.logo} />
                                                    </div>
                                                )
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {relatedCourses.length > 0 &&
                            <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)', paddingBottom: 0 }}>
                                <div id={styles['sectionInner']} className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                    <div className={`${typo.title} ${styles.sectionTitle}`} style={{ fontSize: '1.15rem' }}>
                                        {t('career.relatedCourses')}
                                    </div>
                                    <div className={styles.courses}>
                                        <div className={styles.coursesTrack}>
                                            <Each of={relatedCourses} render={(c) => {

                                                return (
                                                    <div className={styles.courseCard}>
                                                        <CourseCard course={c} />
                                                    </div>
                                                )
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {relatedArticles.length > 0 &&
                            <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)' }}>
                                <div className={styles.sectionInner}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem' }}>
                                        {t('career.relatedArticles')}
                                    </div>
                                    <div className={styles.articles}>
                                        <div className={styles.bigArticle}>
                                            <ArticleCard article={relatedArticles[0]} type={width > 1180 ? ArticleCardType.Large : ArticleCardType.Small} />
                                        </div>
                                        <div className={styles.smallArticles}>
                                            <Each of={relatedArticles.slice(1, 4)} render={(article) => {
                                                return (
                                                    <div className={styles.articleCard}>
                                                        <ArticleCard article={article} type={ArticleCardType.Small} />
                                                    </div>
                                                )
                                            }} />
                                        </div>
                                    </div>
                                    <div className={styles.articles} style={{ paddingTop: 0 }}>
                                        <Each of={relatedArticles.slice(4, relatedArticles.length + 1)} render={(article) => {
                                            return <ArticleCard article={article} type={width > 1180 ? ArticleCardType.Medium : ArticleCardType.Small} />
                                        }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </HeaderFooterLayout>
    )
}

export default Career