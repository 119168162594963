import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../../api"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { ReactComponent as SendIcon } from "../../assets/images/icons/ic-send.svg"
import { ButtonStatus } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import Succeeded from "../../components/animated/Succeeded"
import { CorrectionType, TestType } from "../../enums"
import { canTestBeSubmitted, computeChoiceResult, computeTextCompletionResult, computeTrueFalseResult, formatCountdown, UTCtoLocal } from "../../utils"
import Button from "../Button"
import Skeleton from "../Skeleton"
import TestChoice from "./TestChoice"
import styles from "./TestForm.module.css"
import TestTextCompletion from "./TestTextCompletion"
import TestTrueFalse from "./TestTrueFalse"

const TestForm = ({ test = null, loading = false, onSubmit = () => { }, onExpire = () => { } }) => {

  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const [innerTest, setInnerTest] = useState(null)
  const [userTest, setUserTest] = useState({})
  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoNext, setCanGoNext] = useState(true)
  const [canBeSubmitted, setCanBeSubmitted] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(null)
  const [seconds, setSeconds] = useState(null)
  const [countdown, setCountdown] = useState(null)
  const [resultCounters, setResultCounters] = useState(null)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      if (countdown) {
        clearInterval(countdown)
      }
    }
  }, [])

  useEffect(() => {
    if (!test) return
    const l = test.content.length
    if (index > l) {
      setIndex(l)
      setInnerTest({ ...test.content[l] })
    } else {
      setInnerTest({ ...test.content[index] })
    }

    setCanGoBack(index > 0)
    const storage = localStorage.getItem("userTestIndexes")
    let uti = null
    if (storage) {
      uti = JSON.parse(storage)
      uti[test.id] = index
    } else {
      uti = {}
      uti[test.id] = index
    }
    localStorage.setItem('userTestIndexes', JSON.stringify(uti))

  }, [index])

  useEffect(() => {
    if (test.user_test && innerTest && test.correction_type === CorrectionType.NumberOfErrors) {
      const { user_test } = test
      const { testType } = innerTest
      let counter = {
        correct: 0,
        wrong: 0,
        notAnswered: 0
      }
      switch (testType) {
        case TestType.SingleChoice:
        case TestType.MultipleChoice:
          counter = computeChoiceResult(innerTest, user_test)
          break
        case TestType.TrueFalse:
          counter = computeTrueFalseResult(innerTest, user_test)
          break
        case TestType.TextCompletion:
          counter = computeTextCompletionResult(innerTest, user_test)
          break
        default: break
      }
      setResultCounters(counter)
    }

  }, [innerTest])

  useEffect(() => {
    if (!test) return
    const storageUserTestIndex = localStorage.getItem("userTestIndexes")
    const storageUserTest = localStorage.getItem("userTests")

    let i = null
    let ut = null
    if (storageUserTestIndex) {
      const indexes = JSON.parse(storageUserTestIndex)
      i = indexes[test.id]
    }

    if (storageUserTest) {
      const userTests = JSON.parse(storageUserTest)
      ut = userTests[test.id]
    }

    const index = (i !== null && i !== undefined) ? parseInt(i) : 0
    setIndex(index)
    setInnerTest(test.content[index])
    setUserTest(ut ?? {})

    if (test.expires_at) {
      const getTime = async () => {
        try {
          const time = await api.get("/time")
          return time
        } catch (e) {
          console.error(e)
        }
        return null
      }

      getTime().then((time) => {
        const expiresAt = UTCtoLocal(test.expires_at)
        const localtime = UTCtoLocal(time)
        const remainingTime = expiresAt.getTime() - localtime.getTime()
        if (remainingTime > 0 && remainingTime < 24 * 60 * 60 * 1000) { //  1 day in millis
          setSeconds(Math.floor(remainingTime / 1000))
          setCountdown(
            setInterval(() => {
              setSeconds(s => {
                s = s > 0 ? (s - 1) : 0
                return s
              })
            }, 1000)
          )
        }
      })
    }

  }, [test])

  useEffect(() => {
    if (seconds === null) {
      return
    }

    if (seconds === 0) {
      save()
      onExpire()
    }
  }, [seconds])

  useEffect(() => {
    if (!test) return
    const isValid = canTestBeSubmitted(userTest, test.content)
    setCanBeSubmitted(isValid)
  }, [userTest])

  const onSelect = useCallback((id, testType, isValid, responses) => {
    // setCanGoNext(isValid)
    userTest[id] = {
      testType,
      isValid,
      responses
    }
    setUserTest({ ...userTest })

    const storage = localStorage.getItem("userTests")
    let ut = null
    if (storage) {
      ut = JSON.parse(storage)
      if (!ut[test.id]) {
        ut[test.id] = {}
      }
      ut[test.id][id] = userTest[id]
    } else {
      ut = {}
      ut[test.id] = userTest
    }

    localStorage.setItem('userTests', JSON.stringify(ut));
  }, [userTest])

  const goBack = useCallback(() => {
    setIndex((i) => {
      if (i === 0) {
        return 0
      }
      return i - 1
    })
  }, [innerTest])

  const goNext = useCallback(() => {
    const { content } = test
    setIndex((i) => {
      if (i >= content.length) {
        return content.length
      }
      return i + 1
    })
  }, [test])

  const fillResponses = useCallback((innerTest) => {
    // Get default values for each test type 
    const { testType } = innerTest
    const responses = []
    switch (testType) {
      case TestType.SingleChoice:
      case TestType.MultipleChoice:
        for (const answer of innerTest.answers) {
          responses.push({ id: answer.id, value: false })
        }
        break
      case TestType.TrueFalse:
        for (const answer of innerTest.answers) {
          responses.push({ id: answer.id, value: null })
        }
        break
      case TestType.TextCompletion:
        for (const [index, word] of innerTest.words.entries()) {
          responses.push({ value: "", index: word.hidden ? index : null })
        }
        break
      default: break
    }
    return responses
  }, [])

  const save = useCallback(async () => {
    const ut = []

    for (const innerTest of test.content) {
      const id = innerTest.id
      if (userTest[id]) {
        const { responses } = userTest[id]
        ut.push({ id, responses })
      } else {
        const r = fillResponses(innerTest)
        ut.push({ id, responses: r })
      }

    }
    const res = await api.post(`/tests/${test.id}`, {
      content: { content: ut }
    })
    setCompleted(true)

    const storage = localStorage.getItem("userTests")
    const storageIndex = localStorage.getItem("userTestIndexes")
    if (storage) {
      const ut = JSON.parse(storage)
      if (ut[test.id]) {
        delete ut[test.id]
        localStorage.setItem('userTests', JSON.stringify(ut));
      }
    }

    if (storageIndex) {
      const ut = JSON.parse(storageIndex)
      if (ut[test.id]) {
        delete ut[test.id]
        localStorage.setItem('userTestIndexes', JSON.stringify(ut));
      }
    }
  }, [test, userTest])

  const submit = useCallback(async () => {
    try {
      setButtonStatus(ButtonStatus.Loading)
      await save()
      setButtonStatus(ButtonStatus.Success)
      onSubmit()
    } catch (e) {
      setButtonStatus(ButtonStatus.Error)
      console.error(e)
    }
  }, [onSubmit, save])



  return (
    <div className={styles.container}>
      {
        loading === true &&
        <>
          <Skeleton width={"256px"} height={"26px"} borderRadius={"12px"} />
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: ".5rem", alignItems: "center" }}>
            <Skeleton width={"100%"} height={"1.25rem"} borderRadius={"12px"} />
            <Skeleton width={"85%"} height={"1.25rem"} borderRadius={"12px"} />
          </div>
          <TestChoice loading={true} />
          <div className={styles.paging}>
            <Skeleton width={"144px"} height={"48px"} borderRadius={"10rem"} />
            <Skeleton width={"48px"} height={"1.125rem"} borderRadius={"12px"} />
            <Skeleton width={"144px"} height={"48px"} borderRadius={"10rem"} />
          </div>
        </>
      }
      {
        loading === false &&
        <>
          <div className={styles.header}>
            <div className={styles.name}>{test?.name}</div>
            {
              seconds !== null &&
              <div
                className={styles.countdown}
                style={{
                  color: seconds < (5 * 60) ? "var(--sf-red)" : "#545454"
                }}
              >
                {formatCountdown(seconds)}
              </div>
            }
          </div>
          {/* <div className={styles.description}>{test?.description}</div> */}
          {
            !completed && innerTest?.testType === TestType.SingleChoice && <TestChoice index={index + 1} singleChoice {...innerTest} responses={userTest[innerTest.id]?.responses ?? null} onSelect={onSelect} nErrors={resultCounters?.wrong} />
          }

          {
            !completed && innerTest?.testType === TestType.MultipleChoice && <TestChoice index={index + 1} {...innerTest} responses={userTest[innerTest.id]?.responses ?? null} onSelect={onSelect} nErrors={resultCounters?.wrong} />
          }

          {
            !completed && innerTest?.testType === TestType.TrueFalse && <TestTrueFalse index={index + 1} {...innerTest} responses={userTest[innerTest.id]?.responses ?? null} onSelect={onSelect} nErrors={resultCounters?.wrong} />
          }

          {
            !completed && innerTest?.testType === TestType.TextCompletion && <TestTextCompletion index={index + 1} {...innerTest} responses={userTest[innerTest.id]?.responses ?? null} onSelect={onSelect} nErrors={resultCounters?.wrong} />
          }

          {
            completed === true && <div className={styles.succeededContainer}>
              <Succeeded width="200px" height="200px" />
            </div>
          }

          <div className={styles.paging}>
            <Button
              onClick={goBack}
              disabled={!canGoBack || completed}
              inverse
              style={{
                padding: ".8rem 2rem"
              }}>
              <ArrowIcon /> {width > 540 && t("tests.back").toUpperCase()}
            </Button>
            {
              completed === false &&
              <div className={styles.testNumber}>
                {width > 540 && t("tests.testNumberLabel")} {index + 1}/
                <div className={styles.totalTestsNumber}>{test?.content.length ?? "-"}</div>
              </div>
            }
            {
              (index < (test?.content.length - 1)) &&
              <Button
                onClick={goNext}
                disabled={!canGoNext || completed}
                style={{
                  padding: ".8rem 2rem"
                }}>
                {width > 540 && t("tests.next").toUpperCase()}
                {width <= 540 && <ArrowIcon style={{ transform: "rotate(180deg)" }} />}
              </Button>
            }
            {
              (index === (test?.content.length - 1)) && width > 540 &&
              <Button
                onClick={submit}
                disabled={!canBeSubmitted || completed}
                accentColor={"var(--primary)"}
                status={buttonStatus}
                style={{
                  padding: ".8rem 2rem",
                }}>
                {t("tests.submit").toUpperCase()}
                {
                  !buttonStatus &&
                  <SendIcon />
                }
              </Button>
            }

            {
              (index === (test?.content.length - 1)) && width <= 540 &&
              <Button
                appearance="text"
                onClick={submit}
                disabled={!canBeSubmitted || completed}
                accentColor={"var(--primary)"}
              >
                {t("tests.submit").toUpperCase()}
              </Button>
            }
          </div>
        </>
      }
    </div>
  )
}

export default TestForm
