import styles from "./CourseCard.module.css"
import typo from "../../typography.module.css"
import Button from "../Button"
import { useTranslation } from "react-i18next"
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-active.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { useEffect, useState } from "react"
import { CourseStatus, CourseType } from "../../common/constants"
import { useNavigate } from "react-router-dom"
import CourseBadge from "../CourseBadge"
import { formatPrice } from "../../utils"

const CourseCard = ({ course, cardType = 'shop' }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [discount, setDiscount] = useState(null)

    useEffect(() => {
        if (course.product) {
            let discount = (course.product.price - course.product.discounted_price) / course.product.price * 100
            setDiscount(Math.round(discount))
        }
    }, [course])

    const openCourse = () => {
        navigate(`/courses/${course.slug}`)
    }

    return (
        <>
            {cardType === "shop" &&
                <div className={styles.container} style={{cursor: 'pointer'}} onClick={openCourse}>
                    <div className={styles.thumbnail}>
                        <img src={course.thumbnail} alt="course-thumbnail"></img>
                        <div className={styles.thumbnailOverlay}></div>
                        <CourseBadge type={course.type} style={{ position: "absolute", top: ".5rem", left: ".5rem" }} />
                        <div className={styles.metas}>
                            {course.type === CourseType.Professional &&
                                <div className={styles.meta}>
                                    <BookIcon></BookIcon>
                                    <div>{course.program.length} moduli</div>
                                </div>
                            }
                            <div className={styles.meta}>
                                <ClockIcon></ClockIcon>
                                <div>{course.duration} ore</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={typo.subheadline}>{course.name}</div>
                        <div className={`${typo.body} ${styles.description}`} style={{ opacity: 0.4 }}>{course.description}</div>
                    </div>
                    <div className={styles.spacer}></div>
                    <div className={styles.divider}></div>
                    <div className={styles.spacer}></div>
                    <div className={styles.purchase}>

                        {discount &&
                            <div className={styles.discount}>Sconto del {discount}% <div>{formatPrice(course.product.price)}€</div></div>
                        }
                        <Button
                            fullWidth
                            onClick={openCourse}>
                            <CartIcon style={{ minWidth: '20px', minHeight: '20px' }}></CartIcon>
                            {t('courses.purchase').toUpperCase()} {formatPrice(course.product.discounted_price ?? course.product.price)}€
                        </Button>

                    </div>
                </div>
            }
            {cardType === "myPath" &&
                <div className={styles.container}>
                    <div className={styles.thumbnail}>
                        <img src={course.edition?.thumbnail ?? course.thumbnail} alt="course-thumbnail"></img>
                        <CourseBadge type={course.type} style={{ position: "absolute", top: ".5rem", left: ".5rem" }} />
                        <div className={styles.metas}>
                            {course.type === CourseType.Professional &&
                                <div className={styles.meta}>
                                    <BookIcon></BookIcon>
                                    <div>{course.program.length} moduli</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={typo.subheadline}>{course.name}</div>
                        <div className={`${typo.body} ${styles.description}`} style={{ opacity: 0.4 }}>{course.description}</div>
                    </div>
                    <div className={styles.spacer}></div>
                    <div className={styles.divider}></div>
                    <div className={styles.spacer}></div>
                    <div className={styles.purchase}>
                        <Button inverse={course.status !== CourseStatus.ToStart} style={{ width: '100%' }} onClick={() => { navigate(`/my-path/courses/${course.slug}`) }}>
                            {t(course.status === CourseStatus.ToStart ? 'courses.subscribe' : 'courses.enter').toUpperCase()}
                        </Button>

                    </div>
                </div>
            }
        </>

    )
}

export default CourseCard
