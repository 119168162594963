import styles from "./Dialog.module.css"
import typo from "../../typography.module.css"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"

const Dialog = ({ open, title, children, onClose, style, contentStyle }) => {

    const handleClose = () => {
        if (onClose && typeof onClose === 'function') {
            onClose()
        }
    }

    return (
        <>
            {open &&
                <div className={styles.container}>
                    <div className={styles.card} style={style}>
                        <div className={styles.header}>
                            <div className={[typo.subtitle, styles.title].join(' ')}>{title}</div>
                            <button className={styles.closeButton} onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className={styles.content} style={contentStyle}>
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Dialog