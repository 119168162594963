import { Helmet, HelmetProvider } from "react-helmet-async"
import { useParams } from "react-router-dom"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Test.module.css"
import TestCarousel from "../components/TestCarousel"

const Test = () => {
  const { testId } = useParams()

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>{"Test"}</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <TestCarousel testId={testId} />
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}


export default Test
