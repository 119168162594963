import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { TestType } from "../../enums"
import Button from "../Button"
import TestChoice from "./TestChoice"
import styles from "./TestResult.module.css"
import TestTextCompletion from "./TestTextCompletion"
import TestTrueFalse from "./TestTrueFalse"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"

const TestResult = ({ test = null, onBack = () => { } }) => {

    const { t } = useTranslation()
    const [index, setIndex] = useState(0)
    const [innerTest, setInnerTest] = useState(null)
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (!test) return
        const l = test.content.length
        if (index > l) {
            setIndex(l)
            setInnerTest({ ...test.content[l] })
        } else {
            setInnerTest({ ...test.content[index] })
        }
    }, [index])

    useEffect(() => {
        if (test) {
            setInnerTest(test.content[index])
        }
    }, [test])

    const goBack = useCallback(() => {
        setIndex((i) => {
            if (i === 0) {
                onBack()
                return 0
            }
            return i - 1
        })
    }, [innerTest])

    const goNext = useCallback(() => {
        const { content } = test
        setIndex((i) => {
            if (i >= content.length) {
                return content.length
            }
            return i + 1
        })
    }, [test])

    return (
        <div className={styles.container}>


            <div className={styles.name}>{test?.name}</div>
            <div className={styles.description}>{test?.description}</div>
            {
                innerTest?.testType === TestType.SingleChoice &&
                <TestChoice index={index + 1} singleChoice {...innerTest} responses={test.user_test.content.find(c => c.id === innerTest.id)} correctionType={test.correction_type} />
            }

            {
                innerTest?.testType === TestType.MultipleChoice &&
                <TestChoice index={index + 1} {...innerTest} responses={test.user_test.content.find(c => c.id === innerTest.id)} correctionType={test.correction_type} />
            }

            {
                innerTest?.testType === TestType.TrueFalse &&
                <TestTrueFalse index={index + 1} {...innerTest} responses={test.user_test.content.find(c => c.id === innerTest.id)} correctionType={test.correction_type} />
            }

            {
                innerTest?.testType === TestType.TextCompletion &&
                <TestTextCompletion index={index + 1} {...innerTest} responses={test.user_test.content.find(c => c.id === innerTest.id)} correctionType={test.correction_type} />
            }

            <div className={styles.paging}>
                <Button
                    onClick={goBack}
                    // disabled={index === 0}
                    inverse
                    style={{
                        padding: ".8rem 2rem"
                    }}>
                    <ArrowIcon />
                    {width > 540 && t("tests.back").toUpperCase()}
                </Button>
                {
                    <div className={styles.testNumber}>
                        {width > 540 && t("tests.testNumberLabel")} {index + 1}/
                        <div className={styles.totalTestsNumber}>{test?.content.length ?? "-"}</div>
                    </div>
                }
                {
                    index < test.content.length - 1 &&
                    <Button
                        onClick={goNext}
                        disabled={index === test.content.length - 1}
                        style={{
                            padding: ".8rem 2rem"
                        }}>
                        {
                            width > 540 &&
                            t("tests.nextResult").toUpperCase()
                        }
                        {
                            width < 540 &&
                            <ArrowIcon style={{ transform: "rotate(180deg)" }} />
                        }
                    </Button>
                }
                {
                    index === test.content.length - 1 && width > 540 &&
                    <Button
                        onClick={onBack}
                        style={{
                            padding: ".8rem 2rem"
                        }}>
                        {t("tests.backToRecap").toUpperCase()}
                    </Button>
                }
                {
                    index === test.content.length - 1 && width <= 540 &&
                    <Button
                        appearance="text"
                        onClick={onBack}
                        style={{
                            padding: 0
                        }}>
                        {t("tests.backToRecap").toUpperCase()}
                    </Button>
                }
            </div>
        </div>
    )
}

export default TestResult
