import { Splide, SplideSlide } from "@splidejs/react-splide"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import api from "../api"
import { ReactComponent as RetryIcon } from "../assets/images/icons/ic-arrow-circular.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icons/ic-error.svg"
import { ReactComponent as QuestionsIcon } from "../assets/images/icons/ic-questions.svg"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { Each } from "../common/Each"
import { TestStatus } from "../common/constants"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import CircularProgess from "../components/CircularProgress"
import Skeleton from "../components/Skeleton"
import Tag from "../components/Tag"
import TestForm from "../components/tests/TestForm"
import TestResult from "../components/tests/TestResult"
import { CorrectionType, DialogStatus, TestType } from "../enums"
import { calcLastUpdate, computeChoiceResult, computeTextCompletionResult, computeTrueFalseResult, formatDateV2, UTCtoLocal } from "../utils"
import styles from "./TestCarousel.module.css"
import AlertDialog from "./dialogs/AlertDialog"

let submitTimeout = null

const TestCarousel = ({ testId, onSubmitted = () => { } }) => {

  const { t } = useTranslation()
  const [test, setTest] = useState(null)
  const [loading, setLoading] = useState(false)
  const [startTest, setStartTest] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)
  const [error, setError] = useState(false)
  const [alreadyStarted, setAlreadyStarted] = useState(false)
  const [circularContent, setCircularContent] = useState(null)
  const [page, setPage] = useState(0)
  const [showExpiredDialog, setShowExpiredDialog] = useState(false)
  const { width } = useWindowDimensions()

  const refCover = useRef(null)
  const splideRef = useRef(null)

  const expirationDateData = useMemo(() => {
    if (test?.expires_at) {
      const date = UTCtoLocal(test?.expires_at)
      return {
        date,
        dateString: date.toLocaleString(),
        expired: date.getTime() < Date.now()
      }
    }
    return null
  }, [test])

  const getTest = useCallback(async (background = false) => {
    if (!background) {
      setLoading(true)
    }
    try {
      const test = await api.get(`/tests/${testId}`)
      setTest({ ...test })
    } catch (e) {
      setError(e)
      console.error(e)
    }
    if (!background) {
      setLoading(false)
    }
    return () => {
      if (submitTimeout) {
        clearTimeout(submitTimeout)
        submitTimeout = null
      }
    }
  }, [])

  useEffect(() => {

    const storage = localStorage.getItem("userTests")
    if (storage) {
      const data = JSON.parse(storage)
      if (data[testId]) {
        setAlreadyStarted(true)
      }
    }
    getTest()
  }, [])

  useEffect(() => {
    if (!test) {
      return
    }

    if (!startTest && !test?.user_test && test?.status === TestStatus.Public) {
      setPage(0)
    } else if (startTest) {
      setPage(1)
    } else if (!startTest && test?.user_test && !showSolutions) {
      setPage(2)
    } else if (!startTest && test?.user_test && showSolutions) {
      setPage(3)
    } else if (!startTest && !test?.user_test && test?.status === TestStatus.Completed) {
      cleanLocalStorage()
      setPage(4)
    }
  }, [startTest, test, showSolutions])

  useEffect(() => {
    if (splideRef && splideRef.current) {
      splideRef.current.splide.go(page)
    }
  }, [page, splideRef])

  const numberOfQuestions = useMemo(() => {
    if (!test) {
      return;
    }
    const { content } = test
    return content?.length ?? 0
  }, [test])

  const tags = useMemo(() => {
    if (!test) {
      return [];
    }
    const { content } = test
    const uniqueTags = {}
    for (const innerTest of content) {
      const { tags } = innerTest
      for (const tag of tags)
        if (!uniqueTags[tag.id]) {
          uniqueTags[tag.id] = tag
        }
    }

    return Object.values(uniqueTags)
  }, [test])

  const computeTestResult = () => {
    let correct = 0
    let wrong = 0
    let notAnswered = 0
    if (test.user_test) {
      const { content: innerTests, user_test } = test
      for (const innerTest of innerTests) {
        const { testType } = innerTest
        switch (testType) {
          case TestType.SingleChoice:
          case TestType.MultipleChoice:
            const choiceResult = computeChoiceResult(innerTest, user_test)
            correct += choiceResult.correct
            wrong += choiceResult.wrong
            notAnswered += choiceResult.notAnswered
            break
          case TestType.TrueFalse:
            const trueFalseResult = computeTrueFalseResult(innerTest, user_test)
            correct += trueFalseResult.correct
            wrong += trueFalseResult.wrong
            notAnswered += trueFalseResult.notAnswered
            break
          case TestType.TextCompletion:
            const textCompletionResult = computeTextCompletionResult(innerTest, user_test)
            correct += textCompletionResult.correct
            wrong += textCompletionResult.wrong
            notAnswered += textCompletionResult.notAnswered
            break
          default: console.error("Unsupported test type"); break
        }
      }
    }
    return { correct, wrong, notAnswered }
  }

  const circularData = useMemo(() => {
    if (!test) return [
      {
        id: "correct",
        value: 0,
        rawValue: 0,
        color: 'var(--sf-light-green)',
        label: t("tests.correctAnswers")
      },
      {
        id: "wrong",
        value: 0,
        rawValue: 0,
        color: 'var(--sf-red)',
        label: t("tests.wrongAnswers")
      },
      {
        id: "notAnswered",
        value: 0,
        rawValue: 0,
        color: '#B5B8BE',
        label: t("tests.notAnswered")
      },
    ]

    const successThreshold = test.success_threshold ?? 0
    const { correct, wrong, notAnswered } = computeTestResult()
    const sum = correct + wrong + notAnswered

    const pc = Math.round(((correct) / sum * 100) * 100) / 100
    const pw = Math.round(((wrong) / sum * 100) * 100) / 100
    const pna = Math.round(((notAnswered) / sum * 100) * 100) / 100

    if ((correct + wrong) === 0) {
      setCircularContent({
        label: t("tests.notCompleted"), style: {
          fontSize: "1.125rem",
          fontWeight: 800,
          fill: "#B5B8BE"
        }
      })
    } else if (correct >= successThreshold) {
      setCircularContent({
        label: t("tests.passed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--sf-light-green)"
        }
      })
    } else {
      setCircularContent({
        label: t("tests.failed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--sf-red)"
        }
      })
    }

    return [
      {
        id: "correct",
        value: pc,
        rawValue: correct,
        color: 'var(--sf-light-green)',
        label: t("tests.correctAnswers")
      },
      {
        id: "wrong",
        value: pw,
        rawValue: wrong,
        color: 'var(--sf-red)',
        label: t("tests.wrongAnswers")
      },
      {
        id: "notAnswered",
        value: pna,
        rawValue: notAnswered,
        color: '#B5B8BE',
        label: t("tests.notAnswered")
      },
    ]
  }, [test])

  const cleanLocalStorage = () => {
    const storage = localStorage.getItem("userTests")
    const storageIndex = localStorage.getItem("userTestIndexes")
    if (storage) {
      const ut = JSON.parse(storage)
      if (ut[testId]) {
        delete ut[testId]
        localStorage.setItem('userTests', JSON.stringify(ut));
      }
    }

    if (storageIndex) {
      const ut = JSON.parse(storageIndex)
      if (ut[testId]) {
        delete ut[testId]
        localStorage.setItem('userTestIndexes', JSON.stringify(ut));
      }
    }
  }


  const onRetry = useCallback(() => {
    cleanLocalStorage()
    setStartTest(true)
  }, [testId])

  const onSubmit = useCallback(() => {
    submitTimeout = setTimeout(() => {
      getTest(true).then(() => {
        setStartTest(false)
        setShowSolutions(false)
        setError(false)
        setAlreadyStarted(false)
        onSubmitted(circularData.find(data => data.id === 'correct').rawValue >= test.success_threshold)
      })
      submitTimeout = null
    }, 3000)
  }, [getTest, test])


  const onExpire = useCallback(() => {
    setShowExpiredDialog(true)
  }, [])

  const onExpireDialogClose = useCallback(() => {
    setShowExpiredDialog(false)
    submitTimeout = setTimeout(() => {
      getTest(true).then(() => {
        setStartTest(false)
        setShowSolutions(false)
        setError(false)
        setAlreadyStarted(false)
        onSubmitted(circularData.find(data => data.id === 'correct').rawValue >= test.success_threshold)
      })
      submitTimeout = null
    }, 3000)
  }, [test])

  return (
    <div className={styles.testContainer}>
      {
        loading === false && error &&
        <div
          ref={refCover}
          className={styles.initialCover} style={{ gap: "1rem", minHeight: "256px" }}>
          <ErrorIcon className={styles.icon} style={{ color: "var(--sf-red)" }} />
          <div className={styles.name}>{t("errors.somethingWentWrong")}</div>
          <div className={styles.description}>{t("errors.invalidTest")}</div>
        </div>
      }

      {
        loading &&
        <div ref={refCover} className={styles.initialCover}>
          <div className={styles.top} style={{ alignItems: "center" }}>
            <Skeleton width={width > 540 ? "256px" : "128px"} height="1.625rem" borderRadius="12px" />
            <Skeleton width={width > 540 ? "512px" : "256px"} height="1.25rem" borderRadius="12px" />
          </div>
          <div className={styles.middle}>
            <div className={styles.card} style={{ gap: ".5rem" }}>
              <Skeleton width="72px" height=".875rem" borderRadius="12px" />
              <Skeleton width="112px" height="1rem" borderRadius="12px" />
            </div>
            <div className={styles.card} style={{ gap: ".5rem" }}>
              <Skeleton width="72px" height=".875rem" borderRadius="12px" />
              <div className={styles.tags}>
                <Each
                  of={[130, 140, 86]}
                  render={(tagWidth) => (
                    <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.button}>
            <Skeleton type="rect" width={`256px`} height="2rem" borderRadius="10rem" />
          </div>
        </div>
      }
      {
        !loading && !error &&
        <Splide className={styles.splide} aria-label="..." ref={splideRef} options={{
          rewind: false,
          drag: false,
          autoplay: false,
          pagination: false,
        }}>
          <SplideSlide className={styles.slide}>
            <div
              className={styles.initialCover}>
              <div className={styles.top}>
                <div className={styles.name}>{test?.name}</div>
                <div className={styles.description}>{test?.description}</div>
              </div>
              <div className={styles.middle}>
                <div className={styles.card}>
                  <div className={styles.label}>{t("tests.requests")}</div>
                  <div className={styles.requests}><QuestionsIcon style={{ marginRight: "4px" }} /> {numberOfQuestions} {(numberOfQuestions === 0 || numberOfQuestions > 1) ? t("tests.requests") : t("tests.request")}</div>
                </div>
                {
                  expirationDateData &&
                  <div className={styles.card}>
                    <div className={styles.label}>{t("tests.expiresAt")}</div>
                    <div className={styles.requests}><CalendarIcon style={{ marginRight: "4px" }} /> {expirationDateData.dateString}</div>
                  </div>
                }
                {
                  tags.length > 0 &&
                  <div className={styles.card}>
                    <div className={styles.label}>{t("tests.tags")}</div>
                    <div className={styles.tags}>
                      {
                        tags && tags.length > 0 &&
                        <Each
                          of={tags}
                          render={(tag) => (
                            <Tag tag={tag} />
                          )}
                        />
                      }
                    </div>
                  </div>
                }
              </div>
              <div className={styles.button}>
                <Button
                  fullWidth
                  accentColor={"var(--tertiary)"}
                  style={{ width: "256px" }}
                  onClick={() => { setStartTest(true) }}
                >
                  {
                    alreadyStarted &&
                    t("tests.continue").toUpperCase()
                  }
                  {
                    !alreadyStarted &&
                    t("tests.start").toUpperCase()
                  }
                </Button>
              </div>
            </div>
          </SplideSlide>

          <SplideSlide className={styles.slide}>
            {
              page === 1 &&
              <TestForm
                test={test}
                loading={loading}
                onSubmit={onSubmit}
                onExpire={onExpire}
              />
            }
          </SplideSlide>
          <SplideSlide className={styles.slide}>
            {
              page === 2 &&
              <div className={styles.completedTest}>
                <div className={styles.top}>
                  <div className={styles.name}>{test?.name}</div>
                  <div className={styles.description}>{test?.description}</div>
                </div>
                <div className={styles.middle}>
                  <div className={styles.circularChartColumn}>
                    <CircularProgess content={circularContent ?? []} data={circularData.filter(d => d.id !== "notAnswered")}></CircularProgess>
                    <div className={styles.chartLabels}>
                      {circularData.map(data => {
                        return (
                          <div className={styles.chartCell} key={data.label}>
                            <div className={styles.chartRow}>
                              <div className={styles.chartColumn}>
                                <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                              </div>
                              <div className={styles.chartColumn}>
                                <div className={styles.chartDataTitle}>{data.rawValue}</div>
                                <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  {
                    test?.can_be_retried && test?.status === TestStatus.Public &&
                    <div className={styles.button}>
                      <Button
                        fullWidth
                        appearance="default"
                        accentColor={"var(--sf-orange)"}
                        onClick={onRetry}
                      >
                        {t("tests.retry").toUpperCase()}
                        <RetryIcon className={styles.icon} />
                      </Button>

                    </div>
                  }
                  {
                    (test?.correction_type === CorrectionType.Errors || test?.correction_type === CorrectionType.Solutions) &&
                    <div className={styles.button}>
                      <Button
                        fullWidth
                        accentColor={"var(--tertiary)"}
                        onClick={() => setShowSolutions(true)}
                      >
                        {t("tests.showSolutions").toUpperCase()}
                      </Button>
                    </div>
                  }
                </div>
              </div>
            }
          </SplideSlide>
          <SplideSlide className={styles.slide}>
            {
              page === 3 &&
              <TestResult
                test={test}
                onBack={() => {
                  setShowSolutions(false)
                }}
              />
            }
          </SplideSlide>
          <SplideSlide className={styles.slide}>
            {
              page === 4 &&
              <div className={styles.initialCover}>
                <div className={styles.top}>
                  <div className={styles.name}>{test?.name}</div>
                  <div className={styles.description}>{test?.description}</div>
                </div>
                <div className={styles.middle}>
                  <div className={styles.card}>
                    <div className={styles.label}>{t("tests.requests")}</div>
                    <div className={styles.requests}><QuestionsIcon style={{ marginRight: "4px" }} /> {numberOfQuestions} {(numberOfQuestions === 0 || numberOfQuestions > 1) ? t("tests.requests") : t("tests.request")}</div>
                  </div>
                  {
                    tags.length > 0 &&
                    <div className={styles.card}>
                      <div className={styles.label}>{t("tests.tags")}</div>
                      <div className={styles.tags}>
                        {
                          tags && tags.length > 0 &&
                          <Each
                            of={tags}
                            render={(tag) => (
                              <Tag tag={tag} />
                            )}
                          />
                        }
                      </div>
                    </div>
                  }
                  {
                    expirationDateData &&
                    <div className={styles.card}>
                      <div className={styles.label}>{t("tests.expiresAt")}</div>
                      <div className={styles.requests}><CalendarIcon style={{ marginRight: "4px" }} /> {expirationDateData.dateString}</div>
                    </div>
                  }
                </div>
                <div className={styles.expiredContainer}>
                  {
                    expirationDateData?.expired && // completato e scaduto
                    <div className={styles.expired}>
                      {t("tests.expired", { when: calcLastUpdate(expirationDateData.date, false) })}
                    </div>
                  }
                  {
                    test && (!expirationDateData || expirationDateData.expired === false) && // completato prima della scadenza o senza scadenza
                    <div className={styles.expired}>
                      {t("tests.completed")}
                    </div>
                  }
                  <div className={styles.button}>
                    <Button
                      disabled
                      fullWidth
                      accentColor={"var(--tertiary)"}
                      style={{ width: "256px" }}
                      onClick={() => { setStartTest(true) }}
                    >
                      {t("tests.start").toUpperCase()}
                    </Button>
                  </div>
                </div>
              </div>
            }
          </SplideSlide>
        </Splide>
      }
      <AlertDialog
        open={showExpiredDialog}
        title={t("tests.expiredTitle")}
        text={t("tests.expiredText")}
        status={DialogStatus.Default}
        IconComponent={EmptyIllustration}
        iconStyle={{ width: "164px" }}
        onClose={onExpireDialogClose}
        actions={[
          {
            label: t("see").toUpperCase(),
            onClick: onExpireDialogClose,
          }
        ]}
      />
    </div>
  )

}

export default TestCarousel
