import { GoogleAuthProvider, OAuthProvider, deleteUser, linkWithPopup, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import api from "../api";
import { ReactComponent as AppleLogo } from '../assets/images/apple.svg';
import { ReactComponent as GoogleLogo } from '../assets/images/google.svg';
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import FooterLayout from "../components/layouts/FooterLayout";
import { auth } from "../firebase";
import typo from "../typography.module.css";
import style from "./Login.module.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Splide, SplideSlide } from "@splidejs/react-splide"
import Screenshot1 from "../assets/images/login1.png"
import Screenshot2 from "../assets/images/login2.png"
import Screenshot3 from "../assets/images/login3.png"


const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const context = useContext(MainContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null)
    const [resetPassword, setResetPassword] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [timer, setTimer] = useState(60)
    const [loading, setLoading] = useState(false)


    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })

    }, [])

    useEffect(() => {
        window.addEventListener("keydown", handleEnter)
        return () => {
            window.removeEventListener("keydown", handleEnter)
        }
    }, [email, password])


    useEffect(() => {
        if (auth.currentUser) {
            if (!auth.currentUser.isAnonymous) {
                navigate('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser])

    useEffect(() => {
        async function unlockSend() {
            for (let i = 60; i > 0; i--) {
                setTimer(i)
                await new Promise((r) => {
                    setTimeout(r, 1000)
                })
            }
            setEmailSent(false)
        }

        if (emailSent) {
            unlockSend()
        }
    }, [emailSent])

    const handleEnter = (e) => {
        if (e.code === "Enter") {
            if(resetPassword){
                handleResetPassword()
            }
            else{
                signIn()
            }
        }
    }

    const googleSignIn = async () => {
        setLoading(true)
        try {
            await signInWithPopup(auth, googleProvider)
            let user = await api.get("/user")
            if (user) {
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Google Sign In: ", error)
        }
        setLoading(false)
    }

    const appleSignIn = async () => {
        setLoading(true)
        try {
            await signInWithPopup(auth, appleProvider)
            let user = await api.get("/user")
            if (user) {
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Apple Sign In: ", error)
        }
        setLoading(false)
    }

    const signIn = async () => {
        setLoading(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
            let user = await api.get("/user")
            if (user) {
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            if (error.message.includes('invalid-credential')) {
                setError({ code: -1, message: t('errors.invalidCredentials') })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
        setLoading(false)
    }

    const onEmailChange = (value) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setError({ code: -1, message: t('errors.invalidEmail') })
        }
        else {
            setError(null)
        }
    }

    const onPasswordChange = (value) => {
        setPassword(value)
    }

    const validateEmail = (email) => {
        if (email.length > 0) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        }
        else {
            return true
        }
    }

    const goToSignup = () => {
        navigate('/signup')
    }

    const handleChangeResetPassword = () => {
        setResetPassword(!resetPassword)
    }

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email)
            setEmailSent(true)
        }
        catch (error) {
            setError({ code: -1, message: error.message })
        }
    }

    return (
        <FooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Login</title>
                </Helmet>
            </HelmetProvider>
            <div className={style.container}>
                {auth.currentUser.isAnonymous &&
                    <>
                        <div className={style.columnLeft}>
                            <div className={style.logoContainer}>
                                <LogoBlack onClick={() => { navigate("/") }} className={style.logo} />
                            </div>
                            <div className={style.innerLeft}>

                                <div className={style.spacer}></div>
                                {!resetPassword &&
                                    <>
                                        <div className={[typo.title, style.title].join(' ')}>{t('login.title')}</div>
                                        <div className={[typo.body, style.body].join(' ')}>{t('login.subtitle')}</div>

                                        <div className={style.formContainer}>
                                            <TextInput type="text" placeholder={t('login.email')} onKeyUp={onEmailChange}></TextInput>
                                            <TextInput type="password" placeholder={t('login.password')} onKeyUp={onPasswordChange}></TextInput>
                                            <div className={style.resetPassword}>

                                                <div className={style.error}>
                                                    {error &&
                                                        <span className={typo.caption}>
                                                            {error.message}
                                                        </span>
                                                    }
                                                </div>

                                                <Button
                                                    fullWidth
                                                    appearance="text"
                                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                                    onClick={handleChangeResetPassword}>
                                                    {t('login.forgotPassword')}
                                                </Button>
                                            </div>
                                        </div>

                                        <Button
                                            onClick={signIn}
                                            style={{ padding: '1rem 1.5rem' }}
                                            disabled={loading || !email || !password}
                                            fullWidth>
                                            LOGIN
                                        </Button>

                                        <div className={style.textDivider}>
                                            <div className={style.divider}></div>
                                            <div className={style.caption}>{t('login.or')}</div>
                                            <div className={style.divider}></div>
                                        </div>

                                        <div className={style.socialLogin}>
                                            <button className={style.googleSignIn} onClick={googleSignIn}>
                                                <GoogleLogo className={style.googleLogo}></GoogleLogo>
                                                {t('login.signInWithGoogle')}
                                            </button>
                                            <button className={style.appleSignIn} onClick={appleSignIn}>
                                                <AppleLogo className={style.appleLogo}></AppleLogo>
                                                {t('login.signInWithApple')}
                                            </button>
                                        </div>
                                        <div className={style.spacer}></div>
                                        <div className={style.registerContainer}>
                                            {t('login.noAccount')}
                                            <Button appearance="text" onClick={goToSignup}>{t('login.register')}</Button>
                                        </div>
                                    </>
                                }
                                {resetPassword &&
                                    <>
                                        <div className={[typo.title, style.title].join(' ')}>{t('login.resetPasswordTitle')}</div>
                                        {!emailSent &&
                                            <div className={[typo.body, style.body].join(' ')}>{t('login.resetPasswordSubtitle')}</div>
                                        }
                                        {emailSent &&
                                            <div className={[typo.body, style.body].join(' ')}>{t('login.waitToResend', { timer: timer })}</div>
                                        }
                                        <div className={style.formContainer}>
                                            <TextInput value={email} type="text" placeholder={t('login.email')} onKeyUp={onEmailChange}></TextInput>
                                            <div className={style.resetPassword}>
                                                <div className={style.error}>
                                                    {error &&
                                                        <span className={typo.caption}>
                                                            {error.message}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Button onClick={handleResetPassword} style={{ padding: '1rem 1.5rem' }} disabled={emailSent} fullWidth>{t(emailSent ? 'login.sent' : 'login.send').toUpperCase()}</Button>
                                        <div className={style.spacer}></div>
                                        <Button appearance="text" onClick={handleChangeResetPassword}>{t('login.goBack')}</Button>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={style.columnRight}>
                            <div className={style.innerRight}>
                                <Splide 
                                    options={{
                                        autoplay: true,
                                        rewind: true,
                                        speed: 500
                                    }}
                                >
                                    <SplideSlide className={style.slide}>
                                        <div className={[style.rightTitle, typo.title].join(' ')}>{t('login.contentTitle')}</div>
                                        <div className={[typo.body, style.rightBody].join(' ')}>{t('login.contentSubtitle')}</div>
                                        <img src={Screenshot1} className={style.screenshot} alt="" />
                                    </SplideSlide>
                                    <SplideSlide className={style.slide}>
                                        <div className={[style.rightTitle, typo.title].join(' ')}>Il tuo percorso di carriera inizia qui</div>
                                        <div className={[typo.body, style.rightBody].join(' ')}>Partecipa alle masterclass e ai corsi professionalizzanti tenuti da esperti del settore per costruire la carriera dei tuoi sogni.</div>
                                        <img src={Screenshot2} className={style.screenshot} alt="" />
                                    </SplideSlide>
                                    <SplideSlide className={style.slide}>
                                        <div className={[style.rightTitle, typo.title].join(' ')}>Tutto l'aiuto di cui hai bisogno</div>
                                        <div className={[typo.body, style.rightBody].join(' ')}>Resta in contatto diretto con i docenti e i tutor per qualsiasi necessità tramite la live chat.</div>
                                        <img src={Screenshot3} className={style.screenshot} alt="" />
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </div>
                    </>
                }
            </div>
        </FooterLayout>
    )

}

export default Login
