import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import api from "../api"
import { CourseStatus, CourseType } from "../common/constants"
import CourseSubscribe from "../components/CourseSubscribe"
import Loader from "../components/Loader"
import ModuleFeed from "../components/ModuleFeed"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Course.module.css"
import ProfessionalCourse from "./ProfessionalCourse"

const Course = () => {

    const { slug } = useParams()
    const [status, setStatus] = useState(null)
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(true)
    const [course, setCourse] = useState(null)

    useEffect(() => {
        const getUserCourse = async () => {
            setLoading(true)
            try {
                const course = await api.get(`/user/courses/${slug}`)
                setCourse(course)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        const getCourseStatus = async (slug) => {
            try {
                setLoading(true)
                let data = await api.get(`/courses/${slug}/status`)
                setType(data.course_type)
                setStatus(data.status)
                if (data.course_type) {
                    getUserCourse()
                } else {
                    setLoading(false)
                }
            }
            catch (e) {
                console.error(e)
                setLoading(false)
            }
        }



        if (slug) {
            getCourseStatus(slug)
        }
    }, [slug])

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    return (
        <HeaderFooterLayout>
            {!loading &&
                <>
                    {status === CourseStatus.ToStart &&
                        <CourseSubscribe />
                    }
                    {status !== CourseStatus.ToStart && type === CourseType.Masterclass &&
                        <ModuleFeed></ModuleFeed>
                    }
                    {status !== CourseStatus.ToStart && type === CourseType.Professional &&
                        <ProfessionalCourse course={course}></ProfessionalCourse>
                    }
                </>
            }
            {loading &&
                <div className={styles.container}>
                    <Loader />
                </div>
            }
        </HeaderFooterLayout>
    )

}

export default Course
