import { DailyAudio, DailyVideo, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as ScreenShareIcon } from "../../assets/images/icons/ic-screenshare.svg";
import styles from "./DailyParticipant.module.css";

const DailyParticipant = ({ session_id, isLocal = false, style, onClick = () => { } }) => {

    const [participant, setParticipant] = useState(null)
    const participantProprerty = useParticipantProperty(session_id, ['audio', 'video', 'userData', 'tracks', 'user_name', 'owner'])
    const { screens } = useScreenShare()
    const [screenshare, setScreenshare] = useState(false)

    useEffect(() => {
        if (participantProprerty) {
            let participant = {
                id: session_id,
                name: participantProprerty[4],
                owner: participantProprerty[5],
                audio: participantProprerty[0],
                video: participantProprerty[1],
                data: participantProprerty[2],
                isLocal: isLocal
            }
            setParticipant(participant)
        }
    }, [participantProprerty])

    useEffect(() => { 
        if(screens){
            setScreenshare(!!screens.find(s => s.session_id === session_id))
        }
    }, [screens])

    return (
        <>
            {participant &&
                <div
                    className={styles.container}
                    style={{ ...style, backgroundColor: participant.data?.color ? participant.data.color : 'black' }}
                    onClick={() => {
                        onClick(session_id)
                    }}>
                    {(participant.video || screenshare)&&
                        <DailyVideo
                            type={screenshare ? 'screenVideo' : 'video'}
                            automirror={participant.isLocal}
                            sessionId={session_id}
                            style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                    }
                    {!participant.isLocal &&
                        <DailyAudio sessionId={session_id} />
                    }
                    {participant.data &&
                        <div className={styles.name}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.id}`}`}</div>
                    }
                    {participant && screens.find(s => s.session_id === session_id) &&
                        <div className={styles.screenShareBadge}>
                            <ScreenShareIcon />
                        </div>
                    }
                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            }
            {/* {participant && screens.find(s => s.session_id === session_id) &&
                <div className={styles.container} style={style} onClick={() => {
                    onClick(session_id, true)
                }}>
                    <DailyVideo automirror={participant.isLocal} sessionId={session_id} type="screenVideo" style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                    <DailyAudio sessionId={session_id} />

                    <div className={styles.name}>{participant.isLocal ? "Tu - Condivisione" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.id}`} - Condivisione`}</div>

                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            } */}
        </>
    )

}

export default DailyParticipant