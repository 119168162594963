import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as BookIconActive } from "../../assets/images/icons/ic-book-active.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg"
import { ReactComponent as CareersIconActive } from "../../assets/images/icons/ic-careers-active.svg"
import { ReactComponent as CareersIcon } from "../../assets/images/icons/ic-careers.svg"
import { ReactComponent as HomeIcon } from "../../assets/images/icons/ic-home.svg"
import { ReactComponent as HomeIconActive } from "../../assets/images/icons/ic-home-active.svg"
import { ReactComponent as Logo } from "../../assets/images/velv-black.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import Button from "../Button"
import Hamburger from "./Hamburger"
import styles from "./TopNav.module.css"

const TopNav = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { width } = useWindowDimensions()

    const [path, setPath] = useState("/")
    const [isOpen, setIsOpen] = useState(false)

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    useEffect(() => {
        if (width > 768) {
            setIsOpen(false)
        }
    }, [width])

    useEffect(() => {
        if (isOpen && width <= 768) {
            document.body.style.overflow = 'hidden';
        }
        else {
            // When the modal is hidden, we want to remain at the top of the scroll position
            document.body.style.overflow = '';
        }
    }, [isOpen, width])

    useEffect(() => {
        setPath(location.pathname)
    }, [location.pathname])


    function getIcon(page) {
        if (location.pathname === `/${page}`) {
            switch (page) {
                case 'courses':
                    return <BookIconActive className={styles.icon}></BookIconActive>
                case 'careers':
                    return <CareersIconActive className={styles.icon}></CareersIconActive>
                case '':
                    return <HomeIconActive className={styles.icon}></HomeIconActive>
                default: return
            }
        }
        else {
            switch (page) {
                case 'courses':
                    return <BookIcon className={styles.icon}></BookIcon>
                case 'careers':
                    return <CareersIcon className={styles.icon}></CareersIcon>
                case '':
                    return <HomeIcon className={styles.icon}></HomeIcon>
                default: return
            }
        }
    }

    return (
        <>
            <div className={`${styles.container} ${isOpen ? styles.open : ''} ${((scrollPosition > 70 || isOpen) || path !== '/') ? styles.filled : ''}`}>
                <div className={styles.header}>
                    {width > 768 &&
                        <>
                            <Logo className={styles.logo} onClick={() => { navigate("/") }} />
                            <div className={styles.menu}>
                                <button
                                    className={`${styles.menuButton} ${path === '/' ? styles.active : ''}`}
                                    onClick={() => { navigate("/") }}>
                                    HOME
                                </button>
                                <button
                                    className={`${styles.menuButton} ${path === '/courses' ? styles.active : ''}`}
                                    onClick={() => { navigate("/courses") }}>
                                    CORSI
                                </button>
                                <button
                                    className={`${styles.menuButton} ${path === '/careers' ? styles.active : ''}`}
                                    onClick={() => { navigate("/careers") }}>
                                    CARRIERE
                                </button>
                            </div>
                            <div className={styles.spacer}></div>
                            <Button inverse style={{ fontSize: '1.125rem', padding: '.7rem 2rem' }} onClick={() => { navigate("/login") }}>ACCEDI</Button>
                        </>
                    }
                    {width <= 768 &&
                        <>
                            <Logo className={styles.logo} onClick={() => { navigate("/") }} />
                            <Hamburger isOpen={isOpen} onChange={(event) => { setIsOpen(event.target.checked) }}></Hamburger>
                        </>
                    }
                </div>
                <div className={`${styles.content} ${isOpen ? styles.open : ''}`}>
                    {isOpen &&
                        <>
                            <button
                                className={`${styles.menuButton} ${path === '/' ? styles.active : ''}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate("/")
                                }}>
                                {getIcon('')}
                                Home
                            </button>
                            <button
                                className={`${styles.menuButton} ${path === '/courses' ? styles.active : ''}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate("/courses")
                                }}>
                                {getIcon('courses')}
                                Corsi
                            </button>
                            <button
                                className={`${styles.menuButton} ${path === '/careers' ? styles.active : ''}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate("/careers")
                                }}>
                                {getIcon('careers')}
                                Carriere
                            </button>

                            <div className={styles.spacer}></div>

                            <Button style={{ width: '100%', padding: '.7rem 0' }}
                                onClick={() => { navigate("/login") }}>
                                {/* <LoginIcon className={styles.loginIcon}></LoginIcon> */}
                                ACCEDI
                            </Button>

                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default TopNav