import { useState } from "react"
import styles from "./MultiStateSwitch.module.css"
import { Each } from "../common/Each"
import Button from "./Button"

const MultiStateSwitch = ({ states, selected, onStateChange }) => {
    const [selectedState, setSelectedState] = useState(selected ?? 0)

    const handleStateChange = async (index) => {
        setSelectedState(index)
        if (onStateChange && typeof onStateChange === 'function') {
            onStateChange(index)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
            <Each of={states} render={(state, index) => {
                return (
                    <Button
                        style={{ fontSize: '1rem', padding: 0, display: '-webkit-box',
                            'WebkitLineClamp': 1,
                            'WebkitBoxOrient': 'vertical',
                            maxHeight: '1.5rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all'  }}
                        appearance="text"
                        additionalClass={selectedState !== index ? styles.inactive : ''}
                        onClick={() => { handleStateChange(index) }}>
                        {state}
                    </Button>
                )
            }} />
        </div>
    )

}

export default MultiStateSwitch