import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as QuestionsIcon } from "../../assets/images/icons/ic-questions.svg"
import { ReactComponent as RepeatIcon } from "../../assets/images/icons/ic-repeat.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-test-active.svg"
import { ReactComponent as TestVariantIcon } from "../../assets/images/icons/ic-test-variant.svg"
import { ReactComponent as TrophyIcon } from "../../assets/images/icons/ic-trophy.svg"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { Each } from "../../common/Each"
import { formatDateV2, formatTimeV2 } from "../../utils"
import Button from "../Button"
import Skeleton from "../Skeleton"
import styles from "./TestCard.module.css"
import { TestStatus } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"

const TestCard = ({
  test = null,
  loading = false,
  useVariant = false,
  style
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const numberOfQuestions = useMemo(() => {
    if (!test) {
      return;
    }
    const { content } = test
    const { innerTests } = content
    return innerTests?.length ?? 0
  }, [test])

  return (
    <div className={test?.deleted_at && !loading ? `${styles.container} ${styles.deleted}` : styles.container} style={style}>
      {
        loading === true &&
        <>
          <div className={styles.header}>
            <Skeleton type="rect" width="144px" height="16px" borderRadius="4px" />
          </div>
          <div className={styles.content}>
            <div style={{ display: "flex", flexDirection: "column", gap: ".2rem" }}>
              <Skeleton type="rect" width="196px" height="16px" borderRadius="4px" />
              <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="72px" height="14px" borderRadius="4px" />
              <Skeleton type="rect" width="256px" height="16px" borderRadius="4px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="64px" height="14px" borderRadius="4px" />
              <Skeleton type="rect" width="180px" height="16px" borderRadius="4px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="72px" height="14px" borderRadius="4px" />
              <div className={styles.tags}>
                <Each
                  of={[130, 140, 86, 44, 112]}
                  render={(tagWidth) => (
                    <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                  )}
                />
              </div>
            </div>
          </div>
        </>
      }

      {
        !loading &&
        <>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <div className={styles.name} onClick={() => navigate(`/tests/${test.id}`)}>
                {useVariant && <TestVariantIcon style={{ marginRight: "1rem" }} />}
                {!useVariant && <BookIcon style={{ color: "var(--primary)", marginRight: ".2rem" }} />}
                {test.name ?? ""}
              </div>
            </div>
            {
              test.status === TestStatus.Public && !test.submitted && // test attivo e non ancora fatto dallo studente
              <Button style={{ padding: '.5rem 1rem' }}
                onClick={() => {
                  navigate(`/tests/${test.id}`)
                }}
              >
                <>{width > 540 ? t("start").toUpperCase() + ' ' : ''}<ArrowIcon className={styles.arrow} /></>
              </Button>
            }

            {
              test.status === TestStatus.Public && test.submitted && test.can_be_retried && // test attivo e può essere rifatto
              <Button style={{ padding: '.5rem rem' }}
                onClick={() => {
                  navigate(`/tests/${test.id}`)
                }}
              >
                {width > 540 ? t("retry").toUpperCase() : <ArrowIcon className={styles.arrow} />}
              </Button>
            }

            {
              ((test.status === TestStatus.Public && test.submitted && !test.can_be_retried) || // può essere ancora rifatto
                (test.status === TestStatus.Completed && test.submitted)) && // test terminato e fatto dallo studente
              <Button style={{ padding: '.5rem 1.5rem' }}
                onClick={() => {
                  navigate(`/tests/${test.id}`)
                }}
              >
                {width > 540 ? t("see").toUpperCase() : <ArrowIcon className={styles.arrow} />}
              </Button>
            }
            {
              test.status === TestStatus.Completed && !test.submitted && // test terminato ma non fatto dallo studente
              <div className={styles.statusBadge} style={{ "--color": "var(--secondary)" }}>{t("expired").toUpperCase()}</div>
            }
          </div>
          <div className={styles.content}>
            <div className={styles.description}>{test.description ?? ""}</div>
            {
              test.expires_at &&
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.expiresAt")}</div>
                <div className={styles.questions}><CalendarIcon /> {formatDateV2(test.expires_at)} {formatTimeV2(test.expires_at)} </div>
              </div>
            }
            <div className={styles.row}>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.questions")}</div>
                <div className={styles.questions}><QuestionsIcon /> {numberOfQuestions} {(numberOfQuestions === 0 || numberOfQuestions > 1) ? t("tests.questions") : t("tests.question")}</div>
              </div>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.successThreshold")}</div>
                <div className={styles.questions}><TrophyIcon /> {test.success_threshold ?? 0} / {numberOfQuestions}</div>
              </div>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.repeat")}</div>
                <div className={styles.questions}><RepeatIcon /> {test.canBeRetried ? t("yes") : t("no")}</div>
              </div>
            </div>
          </div>
        </>
      }

    </div >
  )
}

export default TestCard
