import styles from "./Search.module.css"
import TextInput from "../components/TextInput"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import { useEffect, useState } from "react"
import api from "../api"
import CourseCard from "../components/cards/CourseCard"
import { Each } from "../common/Each"
import Tag from "../components/Tag"
import CareerCard from "../components/cards/CareerCard"
import ArticleCard from "../components/cards/ArticleCard"
import Loader from "../components/Loader"
import VideoCard from "../components/cards/VideoCard"
import { HelmetProvider, Helmet } from "react-helmet-async"

const Search = () => {

    const [query, setQuery] = useState('')
    const [nRes, setNRes] = useState(0)
    const [tags, setTags] = useState([])
    const [careers, setCareers] = useState([])
    const [courses, setCourses] = useState([])
    const [articles, setArticles] = useState([])
    const [loading, setLoading] = useState(false)
    const [videos, setVideos] = useState([])

    useEffect(() => {
        // Ottieni la query string dai parametri dell'URL
        const queryParams = new URLSearchParams(window.location.search);

        setQuery(queryParams.get('q'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const search = async () => {
            setLoading(true)
            try {
                const { careers, tags, courses, articles, videos } = await api.get(`/search?q=${query}`)
                setCareers(careers)
                setTags(tags)
                setCourses(courses)
                setArticles(articles)
                setVideos(videos)

                setNRes(careers.length + tags.length + courses.length + articles.length + videos.length)
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        if (query) {
            search()
        }
    }, [query])

    const search = async (value) => {
        const url = new URL(window.location.href);
        url.searchParams.set('q', value);
        window.history.pushState(null, '', url.toString());
        setQuery(value)
    }


    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Cerca{query ? query : ''}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section} style={{ paddingBottom: 0 }}>
                    <div className={styles.sectionInner}>
                        <TextInput value={query} type="search" placeholder="Cerca..." onSubmit={search} />
                        <div className={styles.resultNumber}>
                            {nRes > 0 &&
                                <>
                                    {nRes} risultati per "{query}"
                                </>
                            }
                            {nRes === 0 && !loading &&
                                "Spiacenti, nessun risultato."
                            }
                        </div>
                    </div>
                </div>
                {!loading &&
                    <div className={styles.section} style={{ paddingTop: 0 }}>
                        {tags.length > 0 &&
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    Tag
                                </div>
                                <div className={styles.tags}>
                                    <Each of={tags} render={(tag) => {
                                        return (
                                            <Tag tag={tag} />
                                        )
                                    }} />
                                </div>
                            </div>
                        }
                        {courses.length > 0 &&
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    Corsi
                                </div>
                                <div className={styles.courses}>
                                    <Each of={courses} render={(course) => {
                                        return (
                                            <div className={styles.course}>
                                                <CourseCard course={course} />
                                            </div>
                                        )
                                    }} />
                                </div>
                            </div>
                        }
                        {careers.length > 0 &&
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    Carriere
                                </div>
                                <div className={styles.careers}>
                                    <Each of={careers} render={(career) => {
                                        return (
                                            <div className={styles.career}>
                                                <CareerCard career={career} />
                                            </div>
                                        )
                                    }} />
                                </div>
                            </div>
                        }
                        {articles.length > 0 &&
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    Articoli
                                </div>
                                <div className={styles.articles}>
                                    <Each of={articles} render={(article) => {
                                        return (
                                            <ArticleCard article={article} type="medium" />
                                        )
                                    }} />
                                </div>
                            </div>
                        }
                        {videos.length > 0 &&
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    Video
                                </div>
                                <div className={styles.videos}>
                                    <Each of={videos} render={(video) => {
                                        return (
                                            <VideoCard video={video} />
                                        )
                                    }} />
                                </div>
                            </div>
                        }
                    </div>
                }
                {loading &&
                    <div className={styles.loaderContainer}>
                        <Loader />
                        <div className={typo.headline}>Cerco...</div>
                    </div>
                }
            </div>
        </HeaderFooterLayout>
    )
}

export default Search