import { Each } from "../common/Each"
import styles from "./Stepper.module.css"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { useEffect, useState } from "react"

const Stepper = ({nSteps, nCompleted}) => {

    const [perc, setPerc] = useState(0)

    useEffect(() => {
        if(nSteps && nCompleted){
            const p = (nCompleted/nSteps)*100
            setPerc(p)
        }
    }, [nSteps, nCompleted])

    function interpolateColor(color1, color2, percentage) {
        // Assicurati che la percentuale sia compresa tra 0 e 100
        percentage = Math.max(0, Math.min(100, percentage)) / 100;
    
        // Converte i colori esadecimali in valori RGB
        const r1 = parseInt(color1.slice(1, 3), 16);
        const g1 = parseInt(color1.slice(3, 5), 16);
        const b1 = parseInt(color1.slice(5, 7), 16);
        
        const r2 = parseInt(color2.slice(1, 3), 16);
        const g2 = parseInt(color2.slice(3, 5), 16);
        const b2 = parseInt(color2.slice(5, 7), 16);
        
        // Calcola i nuovi valori RGB
        const r = Math.round(r1 + (r2 - r1) * percentage);
        const g = Math.round(g1 + (g2 - g1) * percentage);
        const b = Math.round(b1 + (b2 - b1) * percentage);
        
        // Converte i valori RGB in formato esadecimale
        const toHex = (x) => ('0' + x.toString(16)).slice(-2);
        
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    function colorWithOpacityEffect(color, opacity) {
        // Assicurati che l'opacità sia compresa tra 0 e 1
        opacity = Math.max(0, Math.min(1, opacity));
    
        // Converte il colore esadecimale in valori RGB
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
    
        // Colore di sfondo (bianco)
        const bgColor = { r: 255, g: 255, b: 255 };
    
        // Calcola i nuovi valori RGB usando l'opacità
        const newR = Math.round(r * opacity + bgColor.r * (1 - opacity));
        const newG = Math.round(g * opacity + bgColor.g * (1 - opacity));
        const newB = Math.round(b * opacity + bgColor.b * (1 - opacity));
    
        // Converte i nuovi valori RGB in formato esadecimale
        const toHex = (x) => ('0' + x.toString(16)).slice(-2);
    
        return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
    }

    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <div className={styles.progress} style={{width: `${perc}%`}}></div>
                <Each of={Array(nSteps).fill(null)} render={(s, i) => {
                    let stepColor = interpolateColor('#16C0FB','#6d4aff', (i/nSteps)*100 + 15)
                    let borderColor = colorWithOpacityEffect(stepColor, 0.5)
                    return (
                        <div className={`${styles.step} ${nCompleted >= i + 1 ? styles.completed : ''}`}
                        style={{borderRadius: i === 0 ? '20px 0 0 20px' : 0}}
                        >
                            <div className={`${styles.check} ${nCompleted >= i + 1 ? styles.completed : ''}`} 
                            style={{'--completed-color': stepColor, '--completed-color-border': borderColor}}>
                                <CheckIcon />    
                            </div> 
                        </div>
                    )
                }}/>
            </div>
        </div>
    )

}

export default Stepper