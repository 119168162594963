import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as CartIcon } from "../assets/images/icons/ic-cart.svg"
import { PaymentStatus } from "../common/constants"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import Button from "../components/Button"
import CourseBadge from "../components/CourseBadge"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import { capitalize, formatDateV2, formatPrice, formatTimeV2 } from "../utils"
import styles from "./Order.module.css"
import { HelmetProvider, Helmet } from "react-helmet-async"

const Order = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { orderNo } = useParams()
    const [order, setOrder] = useState(null)
    const context = useContext(MainContext)

    useEffect(() => {

        const getOrder = async () => {
            try {
                let order = await api.get(`/orders/${orderNo}`)
                setOrder(order)
            }
            catch (e) {
                console.error(e)
                if(e.code === 3){
                    navigate('/404')
                }
            }
        }

        if (orderNo) {
            getOrder()
        }

    }, [orderNo])

    useEffect(() => {

        const clearCart = async() => {
            var newCart = structuredClone(context.cart)
            newCart.products = []
            context.setCart(newCart)
            
            try{
                let cart = await api.put("/cart/clear")
                context.setCart(cart)
            }
            catch(e){
                console.error(e)
            }
        }    

        if(order && order.status === 'succeeded'){
            clearCart()
        }
    }, [order])

    const getStatusColor = (status) => {
        switch (status) {
            case PaymentStatus.Succeeded:
                return '47, 198, 160'
            case PaymentStatus.Failed:
                return '250, 102, 117'
            case PaymentStatus.Pending:
            case PaymentStatus.Processing:
                return '190, 190, 190'
            case PaymentStatus.Refunded:
                return '250, 102, 117'
            default:
                return '255, 255, 255'
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Ordine {order ? (order.order_no ?? '') : ''}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.header}>
                            <div className={typo.title} style={{ textAlign: 'left' }}>Ordine #{orderNo}</div>
                            {order &&
                                <div className={typo.headline} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                    {capitalize(formatDateV2(order.completed_at ? order.completed_at : order.created_at, {weekday: 'long', day: 'numeric', month: 'long'}))}, 
                                    {formatTimeV2(order.completed_at ? order.completed_at : order.created_at)}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        {order &&
                            <>
                                <div className={styles.summary}>
                                    <div className={styles.summaryTitle}>
                                        <div className={styles.title} style={{ fontSize: '1.5rem' }}>Riepilogo</div>
                                    </div>
                                    <Each of={order.products} render={(product) => {
                                        return (
                                            <div className={styles.productContainer}>
                                                <div className={styles.product}>
                                                    <img src={product.course.thumbnail} className={styles.productThumbnail} alt="" />
                                                    <div className={styles.productInfo}>
                                                        <CourseBadge type={product.course.type} style={{ padding: '.1rem .3rem', fontSize: '0.625rem' }} />
                                                        <div className={styles.productName}>
                                                            {product.name}
                                                        </div>
                                                    </div>
                                                    <div className={styles.productPrices}>
                                                        {product.discounted_price &&
                                                            <div className={styles.productPrice}>€ {formatPrice(product.price)}</div>
                                                        }
                                                        <div className={styles.productDiscountedPrice}>
                                                            {product.discounted_price ? `€ ${formatPrice(product.discounted_price)}` : `€ ${formatPrice(product.price)}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }} />
                                    <div className={styles.spacer}></div>
                                    <div className={styles.status} style={{ backgroundColor: `rgba(${getStatusColor(order.status)}, 4%)` }}>
                                        <div className={styles.statusLabel}>Stato</div>
                                        <div className={styles.statusValue} style={{ color: `rgb(${getStatusColor(order.status)})` }}>
                                            {t(`paymentStatus.${order.status}`)}
                                        </div>
                                    </div>
                                    <div className={styles.total}>
                                        <div className={styles.totalLabels}>
                                            Totale
                                            <div className={styles.vatLabel}>iva inclusa</div>
                                        </div>
                                        <div className={styles.totalPrice}>
                                            {`€ ${formatPrice(order.amount / 100)}`}
                                        </div>
                                    </div>
                                </div>
                                {[PaymentStatus.Failed, PaymentStatus.Pending].includes(order.status) &&
                                    <div className={styles.actions}>
                                        <Button appearance="text" style={{ display: 'flex', flexDirectio: 'row', alignItems: 'center', gap: '.2rem' }}
                                            onClick={() => { navigate(-1) }}>
                                            <ArrowIcon className={styles.arrowIcon}></ArrowIcon>Torna indietro
                                        </Button>
                                        <div className={styles.spacer}></div>
                                        <Button
                                            style={{ fontSize: '1rem', padding: '.5rem 2rem' }}
                                            onClick={() => { context.setCheckout( {open: true, order: orderNo} ) }}>
                                            <CartIcon />
                                            CONFERMA
                                        </Button>
                                    </div>
                                }
                                {order.status === PaymentStatus.Succeeded &&
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', width:'100%' }}>
                                        <div className={typo.subtitle} style={{ paddingTop: '2rem' }}>Pagamento Completato</div>
                                        <div className={typo.body} style={{ opacity: 0.80, textAlign: 'center', paddingBottom: '2rem' }}>
                                            Il pagamento è andato a buon fine, troverai i corsi e le masterclass acquistate nella sezione "Il mio percorso".
                                        </div>
                                        <Button
                                            onClick={() => {
                                                navigate("/my-path")
                                            }}
                                            style={{ padding: '1rem 2rem' }}
                                        >
                                            VAI AL MIO PERCORSO
                                        </Button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Order