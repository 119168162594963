import { useContext, useEffect, useRef, useState } from "react"
import typo from "../../typography.module.css"
import styles from "./BookingDialog.module.css"
import Dialog from "./Dialog"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import { easepick, LockPlugin } from "@easepick/bundle"
import { addDays } from "date-fns"
import Button from "../Button"
import { Each } from "../../common/Each"
import { ReactComponent as EmailIcon } from "../../assets/images/icons/ic-email.svg"
import { ReactComponent as PhoneIcon } from "../../assets/images/icons/ic-phone.svg"
import { ReactComponent as WhatsappIcon } from "../../assets/images/icons/ic-whatsapp.svg"
import TextInput from "../TextInput"
import MainContext from "../../common/MainContext"
import { formatDateV2 } from "../../utils"
import api from "../../api"
import Succeeded from "../animated/Succeeded"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"

const BookingDialog = ({ open, onClose, onConfirm = () => {} }) => {

    const { width } = useWindowDimensions()
    const context = useContext(MainContext)
    const splideRef = useRef(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedRange, setSelectedRange] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    const hourRanges = [
        {
            id: 0,
            name: "mattina",
            description: "9:00 - 13:00"
        },
        {
            id: 1,
            name: "pomeriggio",
            description: "14:00 - 18:00"
        },
        {
            id: 2,
            name: "sera",
            description: "18:00 - 21:00"
        }
    ]
    const contactOptions = [
        {
            id: 0,
            name: "Email",
            value: 'email',
            icon: <EmailIcon className={styles.contactOptionIcon} />
        },
        {
            id: 1,
            name: "Telefono",
            value: 'phone',
            icon: <PhoneIcon className={styles.contactOptionIcon} />
        },
        {
            id: 2,
            name: "Whatsapp",
            value: 'whatsapp',
            icon: <WhatsappIcon className={styles.contactOptionIcon} />
        }
    ]

    const [email, setEmail] = useState(context.user?.email)
    const [phone, setPhone] = useState('')
    const [selectedContactOption, setSelectedContactOption] = useState(contactOptions[0])
    const phoneRef = useRef(null)

    const isContinueDisabled = () => {
        switch (slideIndex) {
            case 0:
                return !selectedDate || !selectedRange
            case 1:
                if (selectedContactOption.id === 0) {
                    return email.trim().length === 0
                }
                else {
                    return phone.trim().length === 0
                }
            default: return false
        }
    }

    const initializePicker = () => {

        const wrapper = document.querySelector('.easepick-wrapper')
        if (!wrapper) {
            const tomorrow = addDays(new Date(), 1)

            const picker = new easepick.create({
                element: "#datepicker",
                css: [
                    "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
                    'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css',
                    "/styles/easepick.css"
                ],
                inline: true,
                lang: 'it-IT',
                grid: 1,
                calendars: 1,
                date: selectedDate,
                plugins: [LockPlugin],
                LockPlugin: {
                    minDate: tomorrow,
                    filter: (date) => {
                        const day = date.getDay()
                        return [6, 0].includes(day)
                    }
                }
            })

            picker.on('select', (e) => {
                const date = e.detail.date
                setSelectedDate(date)
            })


        }

    }

    useEffect(() => {
        if (open) {
            initializePicker()
        }
    }, [open])

    const formatPreference = (date, hourRange) => {
        date.setHours(12, 0, 0)
        const formattedDate = `${date.toISOString()} - ${hourRange.name} (${hourRange.description})`;
        return formattedDate
    }

    const confirmBooking = async () => {
        setLoading(true)
        try {
            await api.post("/bookings", { preference: `${formatPreference(selectedDate, selectedRange)}`, method: selectedContactOption.value, contact: selectedContactOption.id === 0 ? email : phone })
            setCompleted(true)

            var onboarding = { ...context.onboarding }
            for (let step of onboarding.steps) {
                if (step.type === 'call') {
                    step.actions[0].completed = true
                    step.completed = true
                }
            }

            context.setOnboarding({ ...onboarding })

            onConfirm()

        }
        catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={"Prenota una chiamata"}
            style={{ width: 'min-content', maxWidth: width > 540 ? '545px' : '96vw' }}
        >
            <Splide
                ref={splideRef}
                style={{
                    width: '100%'
                }}
                options={{
                    rewind: false,
                    autoplay: false,
                    speed: 300,
                    pagination: false,
                    drag: false
                }}>
                <SplideSlide>
                    <div className={styles.step}>
                        <div className={typo.body}>
                            Seleziona una preferenza per un giorno e un momento in cui fare il colloquio, ti contatteremo per fissare l'orario preciso.
                        </div>
                        <div className={styles.content}>
                            <div id="datepicker" style={{ display: 'none' }}></div>
                            {(!slideIndex || slideIndex === 0) &&
                                <div className={styles.hourRanges}>
                                    <Each of={hourRanges} render={(range) => {
                                        return (
                                            <div className={`${styles.hourRange} ${selectedRange?.id === range.id ? styles.selected : ''}`}
                                                onClick={() => {
                                                    setSelectedRange(range)
                                                }}>
                                                <div className={styles.hourRangeTitle}>
                                                    {range.name}
                                                </div>
                                                <div className={styles.hourRangeDescription}>
                                                    {range.description}
                                                </div>
                                            </div>)
                                    }} />
                                </div>
                            }
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className={styles.step}>
                        <div className={typo.body}>
                            Inserisci le informazioni necessarie per permetterci di contattarti e confermare la tua prenotazione.
                        </div>
                        {selectedContactOption.id === 0 &&
                            <TextInput value={email} type="text" placeholder={"Email"} onKeyUp={(value) => {
                                setEmail(value)
                            }} />
                        }
                        {selectedContactOption.id !== 0 &&
                            <TextInput ref={phoneRef} value={phone} type="number" placeholder={"Telefono"} onKeyUp={(value) => {
                                setPhone(value)
                            }} />
                        }
                        <div className={typo.subtitle} style={{ fontSize: '0.875rem' }}>
                            Dove preferisci essere contattato?
                        </div>
                        <div className={styles.contactOptions}>
                            <Each of={contactOptions} render={(option) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setSelectedContactOption(option)
                                        }}
                                        className={`${styles.contactOption} ${option.id === selectedContactOption?.id ? styles.selected : ''}`}>
                                        {option.icon}
                                        <div className={styles.contactOptionTitle}>{option.name}</div>
                                    </div>
                                )
                            }} />
                        </div>
                    </div>

                </SplideSlide>
                <SplideSlide>
                    <div className={styles.step}>
                        {!completed &&
                            <>
                                <div className={typo.body}>
                                    Conferma la data, l'orario e il metodo di contatto che hai fornito.
                                </div>
                                <div className={styles.recap}>
                                    <div className={styles.recapRow}>
                                        <div className={typo.headline}>
                                            DATA
                                        </div>
                                        {selectedDate && selectedRange &&
                                            <div className={typo.body}>
                                                <strong>{formatDateV2(selectedDate, { weekday: 'long', day: 'numeric', month: 'long' })}</strong>, {selectedRange.name} ({selectedRange.description})
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.recapRow}>
                                        <div className={typo.headline}>
                                            METODO DI CONTATTO
                                        </div>
                                        {selectedContactOption &&
                                            <div className={typo.body} style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                                                {selectedContactOption.icon} <strong>{selectedContactOption.name}</strong>
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.recapRow}>
                                        <div className={typo.headline}>
                                            {selectedContactOption.name.toUpperCase()}
                                        </div>
                                        {selectedContactOption &&
                                            <div className={typo.body} style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                                                <strong>{selectedContactOption.id === 0 ? email : phone}</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {completed &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', widht: '100%' }}>
                                    <Succeeded />
                                </div>
                                <div className={typo.subtitle} style={{ display: 'flex', justifyContent: 'center' }}>
                                    Richiesta inviata!
                                </div>
                                <div className={typo.body} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                    La tua richiesta di contatto è stata inviata con successo, sarai ricontattato entro 48h per confermare la data e l'ora esatta del colloquio.
                                </div>
                            </>
                        }
                    </div>

                </SplideSlide>
            </Splide>
            <div className={styles.actions}>
                {!completed &&
                    <>
                        {slideIndex > 0 && !loading &&
                            <Button
                                style={{ padding: '0.5rem 1rem' }}
                                accentColor={slideIndex === 2 ? 'var(--tertiary)' : 'var(--primary)'}
                                disabled={!selectedDate || !selectedRange}
                                onClick={() => {
                                    const index = splideRef.current.splide.index
                                    splideRef.current.splide.go(index - 1)
                                    setSlideIndex((prev) => {
                                        return prev - 1
                                    })
                                }}>INDIETRO
                            </Button>
                        }
                        <div></div>

                        <Button
                            style={{ padding: '0.5rem 1rem' }}
                            disabled={isContinueDisabled() || loading}
                            accentColor={slideIndex === 2 ? 'var(--tertiary)' : 'var(--primary)'}
                            onClick={() => {
                                if (slideIndex === 2) {
                                    confirmBooking()
                                }
                                else {
                                    const index = splideRef.current.splide.index
                                    splideRef.current.splide.go(index + 1)
                                    setSlideIndex(index + 1)
                                }
                            }}>{slideIndex === 2 ? 'CONFERMA' : 'CONTINUA'}
                        </Button>
                    </>
                }
                {completed &&
                    <>
                        <div></div>
                        <Button
                            style={{ padding: '0.5rem 1rem' }}
                            accentColor={'var(--tertiary)'}
                            onClick={() => {
                                onClose()
                            }}>{"CHIUDI"}
                        </Button>
                        <div></div>
                    </>
                }
            </div>
        </Dialog>
    )
}

export default BookingDialog