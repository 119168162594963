import { useEffect, useState } from "react"
import styles from "./TutorsBadge.module.css"
import { Each } from "../common/Each"
import Button from "./Button"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { useNavigate } from "react-router-dom"
const { Buffer } = require("buffer")

const TutorsBadge = ({ tutors }) => {

    const navigate = useNavigate()
    const [_tutors, setTutors] = useState([])

    useEffect(() => {
        setTutors(tutors)
    }, [tutors])

    return (
        <div className={styles.container}>
            <Each of={_tutors} render={(tutor, index) => {
                return (
                    <>
                        <div className={styles.tutor}>
                            <img className={styles.picture} src={tutor.picture ?? UserPlaceholder} alt="tutor avatar" />
                            <div className={styles.info}>
                                <div className={styles.name}>{tutor.name} {tutor.surname}</div>
                                <div className={styles.details}>TUTOR DISPONIBILE</div>
                            </div>
                            <Button 
                            onClick={() => { 
                                const base64 = Buffer.from(`${tutor.id}_${tutor.name}_${tutor.surname}`, "utf-8").toString("base64")
                                navigate(`/messages?chat=${base64}`)
                            }}
                            additionalClass={styles.tutorButton}>
                                CHAT
                            </Button>
                        </div>
                        {_tutors.length > 1 && index < _tutors.length - 1 &&
                            <div className={styles.divider} />
                        }
                    </>
                )
            }} />
        </div>
    )

}

export default TutorsBadge