import { ReactComponent as SocialBadge } from "../assets/images/illustrations/3D/social.svg"
import { ReactComponent as LinkedinBadge } from "../assets/images/illustrations/3D/startup.svg"
import { ReactComponent as Contact } from "../assets/images/illustrations/il-contact.svg"
import { ReactComponent as Marketing } from "../assets/images/illustrations/il-marketing.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
// import { ReactComponent as Wave } from "../assets/images/illustrations/il-wave.svg"
import { useEffect, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import api from "../api"
import CoursesMobile from "../assets/images/courses-mobile.png"
import CoursesTablet from "../assets/images/courses-tablet.png"
import Book3D from "../assets/images/illustrations/3D/book.png"
import Computer3D from "../assets/images/illustrations/3D/computer.png"
import Contents3D from "../assets/images/illustrations/3D/contents.webp"
import Explorer3D from "../assets/images/illustrations/3D/explorer.png"
import Flag3D from "../assets/images/illustrations/3D/flag.png"
import IA3D from "../assets/images/illustrations/3D/ia.png"
import Picture3D from "../assets/images/illustrations/3D/picture.png"
import Psyco3D from "../assets/images/illustrations/3D/psyco.webp"
import { ReactComponent as Star3D } from "../assets/images/illustrations/3D/star.svg"
import Survey3D from "../assets/images/illustrations/3D/survey.webp"
import { ReactComponent as SFLogo } from "../assets/images/velv.svg"
// import MacroMobile from "../assets/images/macro-mobile.png"
import { motion, useInView } from "framer-motion"
import PlatformMobile from "../assets/images/platform-mobile.png"
import PlatformTablet from "../assets/images/platform-tablet.png"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import FAQAccordions from "../components/FAQAccordions"
import ReviewsCarousel from "../components/ReviewsCarousel"
import Wave from "../components/animated/Wave"
import Card from "../components/cards/Card"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Landing.module.css"
import { ReactComponent as LogoMini } from "../assets/images/velv-minimized.svg"

const Landing = () => {

    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const [reviews, setReviews] = useState(null)
    const testRef = useRef(null)
    const testInView = useInView(testRef)

    const card1Ref = useRef(null)
    const card1InView = useInView(card1Ref, { once: true })

    const card2Ref = useRef(null)
    const card2InView = useInView(card2Ref, { once: true })


    const card3Ref = useRef(null)
    const card3InView = useInView(card3Ref, { once: true })

    const cardRefs = [card1Ref, card2Ref, card3Ref]

    // eslint-disable-next-line no-multi-str
    const backgroundImage = 'radial-gradient(circle at 45% 450px, rgba(76, 180, 255, 0.14) 1%, transparent 6%),\
                             radial-gradient(circle at 55% 450px, rgba(76, 180, 255, 0.14) 1%, transparent 6%),\
                             radial-gradient(circle at 100% 250px, rgba(47, 189, 199, .25) 1%, transparent 3%),\
                             radial-gradient(circle at 110% 750px, rgba(207, 85, 255, .25) 1%, transparent 5%)'
    // eslint-disable-next-line no-multi-str
    const backgroundImage2 = 'radial-gradient(circle at 30% 0px, rgba(76, 180, 255, 0.14) 1%, transparent 6%),\
                             radial-gradient(circle at 80% 500px, rgba(76, 180, 255, 0.14) 1%, transparent 8%),\
                             radial-gradient(circle at 60% 100px, rgba(47, 189, 199, .25) 1%, transparent 15%),\
                             radial-gradient(circle at 50% 750px, rgba(207, 85, 255, .25) 1%, transparent 10%)'

    const problems = [
        {
            number: '01',
            title: 'Scarsa educazione al percorso di carriera',
            content: 'Non si educano i giovani a conoscere sé stessi per allineare la loro personalità col percorso di carriera in cui potranno avere maggior successo ed essere più soddisfatti.',
            solutionTitle: 'Psicologi del lavoro e Test Psicometrico',
            solutionContent: 'Velv ti permette di seguire un percorso innovativo con l’ausilio di psicologi del lavoro e un test psicometrico per identificare le carriere in cui potresti avere più successo in base alla tua personalità.',
            solutionImage: <img src={Psyco3D} alt="" className={styles.solutionImage} />,
            color: "76, 180, 255",
            backColor: "91, 214, 255"
        },
        {
            number: '02',
            title: 'Poco orientamento su economia e finanza',
            content: 'Rispetto all\'estero, ci sono poche valide opportunità di orientamento alle carriere del mondo economico e finanziario.',
            solutionTitle: 'Contenuti di orientamento',
            solutionContent: 'Accesso a contenuti di orientamento e approfondimento sulle carriere nel settore economico-finanziarie, curati dalla più grande community italiana di economia e finanza.',
            solutionImage: <img src={Contents3D} alt="" className={styles.solutionImage} />,
            color: "252, 255, 76",
            backColor: "47, 199, 160"
        },
        {
            number: '03',
            title: 'Molta teoria e poca pratica ',
            content: 'Il sistema educativo dà troppo peso alla teoria e poco alla pratica, creando un divario tra ciò che gli studenti imparano e le competenze richieste nel mondo lavorativo.',
            solutionTitle: 'Percorsi formativi completi',
            solutionContent: 'Percorsi formativi creati con il supporto di aziende e professionisti, per darti le competenze necessarie a inserirti con successo nel mercato del lavoro.',
            solutionImage: <img src={Explorer3D} alt="" className={styles.solutionImage} />,
            color: "249, 76, 255",
            backColor: "94, 155, 255"
        }

    ]

    const faqs = [
        {
            "name": "Che cos'è Velv?",
            "description": ""
        },
        {
            "name": "Cos'è una Masterclass?",
            "description": ""
        },
        {
            "name": "Come funziona una Masterclass?",
            "description": ""
        },
        {
            "name": "Dove posso seguire le lezioni?",
            "description": ""
        }
    ]

    useEffect(() => {
        const getReviews = async () => {
            try {
                let reviews = await api.get(`/reviews`)
                setReviews(reviews)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!reviews) {
            getReviews()
        }
    }, [reviews])


    const getAnimate = (index) => {
        if (index === 0) {
            return card1InView ? 'translateX(0)' : 'translateX(200%)'
        }
        if (index === 1) {
            return card2InView ? 'translateX(0)' : 'translateX(200%)'
        }
        if (index === 2) {
            return card3InView ? 'translateX(0)' : 'translateX(200%)'
        }
    }


    return (
        <HeaderFooterLayout
            hideFooter
            // style={{ backgroundImage: backgroundImage }} 
            initial={{ backgroundImage: backgroundImage }}
            animate={{ backgroundImage: backgroundImage2 }}
            transition={{ duration: 10, ease: 'easeInOut', repeat: Infinity, repeatType: 'reverse' }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>VELV</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section} id={styles.main}>
                    <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                        <div className={styles.banner}>
                            <img src={Book3D} className={styles.bookIllustration} alt="" />
                            <img src={Picture3D} className={styles.eduIllustration} alt="" />
                            <div className={styles.title}>
                                <div className={styles.titleRow}>
                                    <div>Conosci</div>
                                    <div className={styles.underlined}>
                                        te stesso
                                        <Wave className={styles.wave}
                                            duration={2}
                                            delay={0}
                                            style={{
                                                clipPath: 'polygon(0 0, 85% 0%, 94% 100%, 0 100%)',
                                                'WebkitClipPath': 'polygon(0 0, 85% 0%, 94% 100%, 0 100%)'
                                            }} />
                                    </div>
                                    <div>e costruisci</div>
                                </div>
                                <div className={styles.titleRow}>
                                    <div>la</div>
                                    <div className={styles.underlined}>
                                        carriera
                                        <Wave
                                            duration={2}
                                            delay={.5}
                                            className={styles.wave}
                                            style={{
                                                clipPath: 'polygon(0 0, 75% 0%, 84% 100%, 0 100%)',
                                                'WebkitClipPath': 'polygon(0 0, 75% 0%, 79% 100%, 0 100%)'
                                            }} />
                                    </div>
                                    <div>migliore</div>
                                    <div className={styles.underlined}>
                                        per te
                                        <Wave
                                            duration={2}
                                            delay={1}
                                            className={styles.wave} style={{
                                                clipPath: 'polygon(0 0, 61% 0%, 69% 100%, 0 100%)',
                                                'WebkitClipPath': 'polygon(0 0, 61% 0%, 69% 100%, 0 100%)'
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className={[styles.subtitle, typo.body].join(' ')}>
                                Velv è la prima piattaforma in Italia che sulla base della <b>tua personalità</b> individua le carriere economiche finanziarie <b>più adatte a te</b> e ti dà le <b>competenze</b> per ottenerle.                            </div>
                            <Button
                                appearance="gradient"
                                onClick={() => { navigate('/login') }}
                                additionalClass={styles.actionButton}>INIZIA ORA</Button>
                        </div>
                        <div className={styles.socialProof}>
                            <div className={styles.socialProofBox}>
                                <SocialBadge className={styles.socialBadge} />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    +1,9 milioni
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    I membri della nostra community
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <LinkedinBadge className={styles.linkedinBadge} />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    Miglior Startup
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    Top 10 LinkedIn Startups - Italia
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <Star3D className={styles.trustpilotBadge} />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    4.8 su 5.0
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    La media delle nostre recensioni su Trustpilot
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.section} style={{ background: 'linear-gradient(to top, var(--background-color) 0%, var(--background-color) 70%, transparent 100%)' }}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={styles.playerWrapper}>
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dTor1Gd4WSw"
                                width="100%"
                                height="100%"
                                className={styles.player}
                                playIcon={<PlayIcon className={styles.playerIcon} />}
                                light={"https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/thumbnails/cover-landing-page.png"}
                                config={
                                    {
                                        youtube: {
                                            playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section} style={{background: '#FFFFFF'}}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={`${typo.title} ${styles.sectionTitle}`}>
                            Perchè tutti dovrebbero usare VELV?
                        </div>
                        <div className={typo.body} style={{ textAlign: 'center' }}>
                            In Italia abbiamo <span style={{ color: 'var(--secondary)', fontWeight: 700 }}>tre grossi problemi</span>
                        </div>
                        <div className={styles.problems}>
                            <Each of={problems} render={(problem, index) => {
                                return (
                                    <div className={styles.problem} ref={cardRefs[index]}>
                                        <div className={styles.problemContent}>
                                            <div className={styles.problemNumber}>
                                                {problem.number}
                                                <div className={styles.problemLabel}>PROBLEMA</div>
                                            </div>
                                            <div className={styles.problemTitle}>
                                                {problem.title}
                                            </div>
                                            <div className={[styles.problemDescription, typo.body].join(' ')}>
                                                {problem.content}
                                            </div>
                                        </div>
                                        <motion.div
                                            animate={{ transform: getAnimate(index) }}
                                            transition={{ duration: 1, ease: 'easeInOut' }}
                                            className={styles.solutionContent}>
                                            <Card style={{
                                                width: width > 768 ? '480px' : 'unset',
                                                flexDirection: 'column',
                                                padding: '2rem',
                                                gap: '1rem',
                                                zIndex: 1
                                            }}>
                                                <div className={styles.solutionTitle}>
                                                    {problem.solutionTitle}
                                                    {problem.solutionImage}
                                                </div>
                                                <div className={[styles.solutionDescription, typo.body].join(' ')}>
                                                    {problem.solutionContent}
                                                </div>
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '-70%',
                                                    left: '-50%',
                                                    background: `radial-gradient(rgba(${problem.color}, 20%) 0, transparent 50%)`,
                                                    height: '400px',
                                                    width: '400px'
                                                }} />
                                            </Card>
                                            <div className={styles.radialGradient} style={{ background: `radial-gradient(rgba(${problem.backColor}, 40%) 0%, transparent 50%)` }}></div>
                                        </motion.div>
                                    </div>
                                )
                            }} />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.platform}>
                            <div className={styles.inner}>
                                <div className={styles.innerTitle}>
                                    Come funziona la piattaforma?
                                </div>
                                <div className={[styles.innerDescription, typo.body].join(' ')}>
                                    All'ingresso ci sarà un primo questionario per conoscere i tuoi interessi e le tue aspirazioni di carriera. Una volta dentro, avrai accesso a contenuti dedicati alle principali carriere nel settore economico-finanziario, oltre a corsi che ti prepareranno a entrarvi con successo.<br></br><br></br>Vuoi scoprire in quali carriere potrai avere più successo in base alla tua personalità?<br></br>Scorri per scoprire il funzionamento del nostro test psicometrico.
                                </div>
                                <Button
                                    additionalClass={styles.actionButton}
                                    onClick={() => { navigate('/login') }}
                                >
                                    INIZIA ORA
                                </Button>
                            </div>
                            {width > 768 &&
                                <motion.div
                                    animate={{ transform: (testInView && width > 1150) ? 'translateY(-50%)' : 'translateY(0%)' }}
                                    transition={{ duration: 0.7, ease: 'easeInOut' }}
                                    className={styles.screenshot}>
                                    <img src="./images/platform.png" alt="platform" className={styles.platformScreenshot} />
                                    <img src="./images/courses.png" alt="platform" className={styles.platformScreenshot2} />
                                </motion.div>
                            }
                            {width > 540 && width <= 768 &&
                                <motion.div
                                    animate={{ transform: testInView ? 'translateX(-90%)' : 'translateX(0%)' }}
                                    transition={{ duration: 0.7, ease: 'easeInOut' }}
                                    className={styles.screenshotMobile}>
                                    <img src={PlatformTablet} alt="platform" className={styles.platformScreenshotMobile} />
                                    <img src={CoursesTablet} alt="platform" className={styles.platformScreenshotMobile2} />
                                </motion.div>
                            }
                            {width <= 540 &&
                                <motion.div className={styles.screenshotMobile}
                                    animate={{ transform: testInView ? 'translateX(-90%)' : 'translateX(0%)' }}
                                    transition={{ duration: 0.7, ease: 'easeInOut' }}
                                >
                                    <img src={PlatformMobile} alt="platform" className={styles.platformScreenshotMobile} />
                                    <img src={CoursesMobile} alt="platform" className={styles.platformScreenshotMobile2} />
                                    {/* <img src={MacroMobile} alt="platform" className={styles.platformScreenshotMobile3} /> */}
                                </motion.div>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.platform2}>
                            <div className={styles.cards}>
                                <div className={styles.cardWrapperLeft}>
                                    <div className={[styles.card, styles.surveyCard].join(' ')}>
                                        <img src={Survey3D} alt="" />
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '-70%',
                                            left: '-50%',
                                            background: `radial-gradient(rgba(var(--primary-rgb), 20%) 0, transparent 50%)`,
                                            height: '400px',
                                            width: '400px'
                                        }} />
                                        <div style={{
                                            position: 'absolute',
                                            top: '-70%',
                                            right: '-50%',
                                            background: `radial-gradient(rgba(207, 85, 255, .25) 0, transparent 50%)`,
                                            height: '400px',
                                            width: '400px'
                                        }} />
                                        <div className={typo.subtitle}>Un Test<br></br>Psicometrico</div>
                                    </div>
                                </div>
                                <div className={styles.cardWrapperRight}>
                                    <div className={[styles.card, styles.iaCard].join(' ')}>
                                        <img src={IA3D} alt="" />
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '-70%',
                                            left: '-0%',
                                            background: `radial-gradient(rgba(var(--primary-rgb), 20%) 0, transparent 50%)`,
                                            height: '400px',
                                            width: '400px'
                                        }} />
                                        <div style={{
                                            position: 'absolute',
                                            top: '-70%',
                                            right: '-0%',
                                            background: `radial-gradient(rgba(var(--sf-yellow-rgb), 20%) 0, transparent 50%)`,
                                            height: '400px',
                                            width: '400px'
                                        }} />
                                        <div className={typo.subtitle}>Validato dall' IA</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inner} ref={testRef}>
                                <div className={styles.innerTitle}>
                                    Come funziona il test psicometrico?
                                </div>
                                <div className={[styles.innerDescription, typo.body].join(' ')}>
                                    Grazie al nostro test psicometrico scientificamente validato e sviluppato con il supporto dell'intelligenza artificiale, potrai ottenere un quadro chiaro dei tuoi punti di forza e delle carriere più adatte alla tua personalità. Potrai inoltre avvalerti dell'aiuto di psicologi del lavoro specializzati per analizzare i risultati del test e
                                    trasformarli in un percorso concreto per il tuo successo professionale.
                                </div>
                                <Button additionalClass={styles.actionButton} onClick={() => { navigate("/personalities") }}>VAI AL TEST</Button>
                            </div>

                        </div>
                    </div>
                </div>

                {reviews && reviews.length > 0 &&
                    <div className={`${styles.section} ${styles.reviews}`}>
                        <div className={[styles.sectionInner, styles.reviewsInner].join(' ')} style={{ flexDirection: 'column' }}>
                            <Marketing className={styles.marketingIllustration} />
                            <div className={`${typo.title} ${styles.sectionTitle}`}>
                                Cosa dice chi ha provato VELV
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center' }}>
                                Le recensioni dei nostri utenti.
                            </div>
                            <div className={styles.reviewsCards}>
                                <ReviewsCarousel reviews={reviews} style={{ maxWidth: 'none' }} />
                            </div>
                        </div>
                    </div>
                }

                <div className={[styles.section, styles.who].join(' ')}>
                    <div className={styles.sectionInner}>
                        <img src={Computer3D} className={styles.newsIllustration} alt="" />
                        <div className={typo.title}>
                            Chi Siamo?
                        </div>
                        <div className={[styles.whoContent, typo.body].join(' ')}>
                            VELV nasce per portare in Italia quello che non hai mai imparato a scuola: come scegliere il lavoro migliore per te in base alla tua personalità. E lo fa al fianco di Starting Finance, la più grande community Italiana per giovani del mondo economico e finanziario.
                        </div>
                        <img src={Flag3D} className={styles.mediaIllustration} alt="" />
                    </div>
                </div>

                <div className={[styles.section, styles.faq].join(' ')} style={{ backgroundColor: "var(--background-color)", textAlign: 'center' }}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            FAQ
                        </div>
                        <FAQAccordions faq={faqs} />
                    </div>
                </div>

                <div className={styles.section} style={{ backgroundColor: "var(--background-color)" }}>
                    <div className={styles.sectionInner} style={{ alignItems: 'center' }}>
                        <div className={styles.contact}>
                            <div className={[typo.title, styles.sectionTitle].join(' ')}>
                                Hai delle domande?
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center', paddingBottom: '2rem' }}>
                                Contattaci al nostro indirizzo email o tramite whatsapp!
                            </div>
                            <Button
                                style={{ zIndex: 1 }}
                                additionalClass={styles.actionButton}
                                accentColor="var(--tertiary)">
                                CONTATTACI
                            </Button>
                            <Contact className={styles.contactIllustration} />
                        </div>
                    </div>
                </div>

                <div className={styles.section} style={{ backgroundColor: 'var(--text-color)' }}>
                    <div className={styles.sectionInner}>
                        <div className={styles.footer}>
                            <div className={styles.footerWho}>
                                <SFLogo className={styles.logo} />
                                <div className={styles.footerWhoContent}>
                                    VELV nasce per portare in Italia quello che non hai mai imparato a scuola: come scegliere il lavoro migliore per te in base alla tua personalità. E lo fa al fianco di Starting Finance, la più grande community Italiana per giovani del mondo economico e finanziario.
                                </div>
                            </div>
                            <div className={styles.footerLinks}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    LINK RAPIDI
                                </div>
                                <div className={styles.links}>
                                    <a>Masterclass</a>
                                    <a>Approfondimenti</a>
                                    <a>Merchandising</a>
                                    <a>Recensioni</a>
                                    <a>Rimborsi</a>
                                </div>
                            </div>
                            <div className={styles.footerContact}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    CONTATTI
                                </div>
                                <div className={styles.links}>
                                    <a>Invia un'email</a>
                                    <a>Contatta il supporto</a>
                                </div>
                            </div>
                            <div className={styles.footerSocial}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>
                                    SEGUICI
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[styles.section, styles.subFooter].join(' ')}>
                    <div className={styles.sectionInner}>
                        <div className={styles.subFooterContent}>
                            <div className={styles.subFooterInfos}>
                                <LogoMini className={styles.logoMini} />
                                <b>©2024 Velv Srl</b> • P.IVA 14944641001
                            </div>
                            <div className={styles.subFooterButtons}>
                                <Button appearance="text">Contatti</Button>
                                <Button appearance="text">Privacy & Cookie Policy</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </HeaderFooterLayout >
    )

}

export default Landing