import { useContext } from "react"
import MainContext from "../common/MainContext"
import Landing from "./Landing"
import Dashboard from "./Dashboard"

const Home = () => {

    const context = useContext(MainContext)

    return(
        <>
        {context.user.isAnonymous && 
            <Landing />
        }
        {!context.user.isAnonymous &&
            <Dashboard />
        }
        </>
    )

}

export default Home