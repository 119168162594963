import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./PageNotFound.module.css"
import TicTacToe from "../components/TicTacToe"
import { auth } from "../firebase"
import Footer from "../components/layouts/Footer"
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import typo from "../typography.module.css"
import { ReactComponent as NotFoundIllustration } from "../assets/images/illustrations/404.svg"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { useEffect } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

const PageNotFound = () => {

    const { width } = useWindowDimensions()

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    return (
        <>
            {auth.currentUser &&
                <HeaderFooterLayout>
                    <HelmetProvider>
                        <Helmet>
                            <title>404</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className={styles.container}>
                        <div className={typo.title} style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '2rem', textAlign: 'center' }}>
                            Ops! Questa pagina non esiste...
                        </div>
                        <div className={typo.headline} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            Pssst, approfitta e fai una partita, dai...
                        </div>
                        <div className={styles.content}>
                            <NotFoundIllustration className={styles.notFound} />
                            <TicTacToe width={width > 768 ? 400 : 300} height={width > 768 ? 400 : 300}></TicTacToe>
                        </div>
                    </div>
                </HeaderFooterLayout>
            }
            {!auth.currentUser &&
                <div className={styles.container} style={{ padding: 0 }}>
                    <a href="/">
                        <div className={styles.header}>
                            <LogoBlack className={styles.logo} />
                        </div>
                    </a>
                    <div className={styles.inner} style={{ minHeight: '85vh' }}>
                        <div className={typo.title} style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '2rem' }}>
                            Ops! Questa pagina non esiste...
                        </div>
                        <div className={typo.headline} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            Pssst, approfitta e fai una partita, dai...
                        </div>
                        <div className={styles.content}>
                            <TicTacToe></TicTacToe>
                        </div>
                    </div>

                    <Footer></Footer>
                </div>
            }
        </>
    )
}

export default PageNotFound