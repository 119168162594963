import { useTranslation } from "react-i18next"
import CartDropdown from "../CartDropdown"
import NotificationsDropdown from "../NotificationsDropdown"
import TextInput from "../TextInput"
import style from "./Header.module.css"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"

const Header = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [query, setQuery] = useState('')

    return (
        <div className={style.container}>
            <div className={style.search}>
                {!location.pathname.includes('search') &&
                    <TextInput value={query} onKeyUp={(value) => { setQuery(value) }} placeholder={t('header.search')} type="search" onSubmit={() => {
                        navigate(`/search?q=${query}`)
                    }}></TextInput>
                }
            </div>

            <div className={style.dropdowns}>
                <NotificationsDropdown />
                <CartDropdown />
            </div>
        </div >
    )

}

export default Header