import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as CartIcon } from "../assets/images/icons/ic-cart.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import MainContext from "../common/MainContext"
import { Stripe } from "../common/constants"
import Button from "../components/Button"
import Loader from "../components/Loader"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Cart.module.css"
import { Each } from "../common/Each"
import CourseBadge from "../components/CourseBadge"
import { formatPrice } from "../utils"
import Succeeded from "../components/animated/Succeeded"

const Cart = () => {

    const context = useContext(MainContext)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const stripePromise = loadStripe(Stripe.publicKey)
    const [clientSecret, setClientSecret] = useState('');
    const [orderNo, setOrderNo] = useState(null)
    const [amount, setAmount] = useState(0)
    const [succeeded, setSucceeded] = useState(false)
    const [isPaying, setIsPaying] = useState(false)
    const [discountedAmount, setDiscountedAmount] = useState(0)
    const [discount, setDiscount] = useState(null)
    const [loadingCart, setLoadingCart] = useState(true)

    useEffect(() => {
        if (context.cart) {
            let amount = context.cart.products.map(p => { return p.price })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            let discountedAmount = context.cart.products.map(p => { return p.discounted_price ? p.discounted_price : p.price })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

            setAmount(amount)
            setDiscountedAmount(discountedAmount)
            setDiscount(Math.round(amount - discountedAmount))
        }
    }, [context.cart])

    useEffect(() => {

        window.scrollTo({
            top: 0,
        })

        const createOrder = async () => {
            setLoadingCart(true)
            try {
                let order = await api.get("/cart/checkout")
                setClientSecret(order.client_secret)
                setAmount(order.amount)
                setOrderNo(order.order_no)
            }
            catch (e) {
                console.error(e)
                if(!e.detail.includes("empty cart")){
                    console.error(e)
                }
            }
            finally{
                setLoadingCart(false)
            }
        }

        createOrder()
        
    }, [])

    const fonts = [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800'
        }
    ]

    const appearance = {
        theme: 'stripe',

        variables: {
            colorPrimary: '#2fc6a0',
            colorText: '#262626',
            fontFamily: 'Manrope',
            borderRadius: '12px',
            spacingUnit: '6px'
        }
    }

    const removeCartItem = async (product_id) => {
        try {
            let body = { product_id: product_id }
            let cart = await api.put("/cart/remove", body)
            context.setCart(cart)
        }
        catch (e) {
            console.error(e)
        }
    }

    const clearCart = async () => {

        //Clear Local
        var newCart = structuredClone(context.cart)
        newCart.products = []
        context.setCart(newCart)

        //Clear Remote
        try {
            let cart = await api.put("/cart/clear")
            context.setCart(cart)
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Carrello</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.payment}>
                    <div className={styles.inner}>
                        {!succeeded &&
                            <div className={typo.title} style={{ textAlign: 'left', width: '100%' }}>Pagamento</div>
                        }

                        {loadingCart &&
                            <>
                                <div style={{ display: 'flex', minHeight: '300px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', width: '100%', height: '100%' }}>
                                    <Loader />
                                    <div className={typo.headline}>Caricando il carrello...</div>
                                </div>
                            </>
                        }
                        {!loadingCart &&
                            <>
                                {succeeded &&
                                    <>
                                        <Succeeded />
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                                            <div className={typo.subtitle} style={{ paddingTop: '2rem' }}>Pagamento Completato</div>
                                            <div className={typo.body} style={{ opacity: 0.80, textAlign: 'center', paddingBottom: '2rem' }}>
                                                Il pagamento è andato a buon fine, troverai i corsi e le masterclass acquistate nella sezione "Il mio percorso".
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    navigate("/my-path")
                                                }}
                                                style={{ padding: '1rem 2rem' }}
                                            >
                                                VAI AL MIO PERCORSO
                                            </Button>
                                        </div>
                                    </>
                                }
                                {!succeeded &&
                                    <>
                                        {context.cart?.products.length > 0 && clientSecret &&
                                            <>
                                                <div className={styles.paymentForm}>
                                                    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, appearance: appearance, fonts: fonts }}>
                                                        <CartPayment
                                                            orderNo={orderNo}
                                                            pay={isPaying}
                                                            onComplete={() => {
                                                                clearCart()
                                                                setIsPaying(false)
                                                                setSucceeded(true)
                                                            }}
                                                            onError={() => {
                                                                setIsPaying(false)
                                                            }} />
                                                    </Elements>
                                                </div>
                                                <div className={styles.spacer} />
                                                <div className={styles.actions}>
                                                    <Button appearance="text" style={{ display: 'flex', flexDirectio: 'row', alignItems: 'center', gap: '.2rem' }}
                                                        onClick={() => { navigate(-1) }}>
                                                        <ArrowIcon className={styles.arrowIcon}></ArrowIcon>Torna indietro
                                                    </Button>
                                                    <div className={styles.spacer}></div>
                                                    <Button
                                                        disabled={isPaying || context.cart?.products.length === 0}
                                                        style={{ fontSize: '1rem', padding: '.5rem 2rem' }}
                                                        onClick={() => { setIsPaying(true) }}>
                                                        <CartIcon />
                                                        CONFERMA E PAGA
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {(!context.cart || context.cart?.products.length === 0) &&
                                            <>
                                                <div className={styles.empty}>
                                                    <div className={typo.headline}>Il tuo carrello è vuoto, cerca un corso che fa al caso tuo e aggiungilo qui per procedere all'acquisto!</div>
                                                    <Button
                                                        additionalClass={styles.goToCourses}
                                                        onClick={() => { navigate("/courses") }}
                                                        style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem' }} inverse>
                                                        {t('myPath.goToCourses').toUpperCase()}
                                                        <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                                {isPaying &&
                                    <div className={styles.isPayingOverlay}>
                                        <Loader />
                                        <div className={typo.headline}>Processando il pagamento...</div>
                                    </div>
                                }
                            </>
                        }

                    </div>
                </div>

                <div className={styles.summary}>
                    <div className={styles.summaryTitle}>
                        <div className={styles.title} style={{ fontSize: '1.5rem' }}>Riepilogo</div>
                    </div>
                    <Each of={context.cart?.products} render={(product) => {
                        return (
                            <div className={styles.productContainer}>
                                <div className={styles.product}>
                                    <img src={product.course.thumbnail} className={styles.productThumbnail} alt="" />
                                    <div className={styles.productInfo}>
                                        <CourseBadge type={product.course.type} style={{ padding: '.1rem .3rem', fontSize: '0.625rem' }} />
                                        <div className={styles.productName}>
                                            {product.name}
                                        </div>
                                        <button className={styles.deleteButton} onClick={() => { removeCartItem(product.id) }}>
                                            Rimuovi <DeleteIcon />
                                        </button>
                                    </div>
                                    <div className={styles.productPrices}>
                                        {product.discounted_price &&
                                            <div className={styles.productPrice}>€ {formatPrice(product.price)}</div>
                                        }
                                        <div className={styles.productDiscountedPrice}>
                                            {product.discounted_price ? `€ ${formatPrice(product.discounted_price)}` : `€ ${formatPrice(product.price)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }} />
                    <div className={styles.spacer}></div>
                    {discount > 0 &&
                        <div className={styles.discount}>
                            <div className={styles.discountLabel}>Totale sconti</div>
                            <div className={styles.discountValue}>€ -{formatPrice(discount)}</div>
                        </div>
                    }

                    <div className={styles.total}>
                        <div className={styles.totalLabels}>
                            Totale
                            <div className={styles.vatLabel}>iva inclusa</div>
                        </div>
                        <div className={styles.totalPrice}>
                            {`€ ${formatPrice(discountedAmount)}`}
                        </div>
                    </div>
                </div>

            </div>
        </HeaderFooterLayout>
    )

}

const CartPayment = ({orderNo, pay, onComplete, onError }) => {

    const [errorMessage, setErrorMessage] = useState(null)
    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        if (pay) {
            handleSubmitPayment()
        }
    }, [pay])

    const handleSubmitPayment = async () => {
        setErrorMessage(null)
        if (!stripe || !elements) {
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_PAYMENT_REDIRECT}/orders/${orderNo}`,
            },
            redirect: 'if_required',
        });

        if (error) {
            onError()
            console.error(error)
            setErrorMessage(error.message);
        } else {
            if (paymentIntent.status === 'succeeded') {
                onComplete()
            }
        }
    }

    return (
        <div className={styles.cartPayment}>
            <PaymentElement options={{ layout: { type: 'accordion' } }} />
            {errorMessage && <div className={styles.paymentError}>{errorMessage}</div>}
        </div>
    )
}

export default Cart

