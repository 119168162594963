import styles from "./CourseInfoCard.module.css"
import typo from "../../typography.module.css"
import Card from "./Card"

const CourseInfoCard = ({ info }) => {

    return (
        <Card style={{ flexDirection: 'column', position: 'relative', overflow: 'hidden', padding: '2rem 1.25rem', width: '100%' }}>
            <div className={styles.gradient}
                style={{
                    background: `radial-gradient(circle, ${info.color}33 0%, ${info.color}00 75%)`
                }}></div>
            <div className={styles.icon}
                dangerouslySetInnerHTML={{ __html: info.icon.replace(/fill="#[^"]*"/g, 'fill="currentColor"').replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }}
                style={{
                    color: info.color
                }} />
            <div className={typo.title} style={{ fontSize: '1.15rem', fontFamily: 'Avenir Next', fontWeight: 900 }}>
                {info.title.toUpperCase()}
            </div>
            <div className={typo.body}>
                {info.description}
            </div>
        </Card>
    )
}

export default CourseInfoCard