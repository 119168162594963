import Card from "./Card"
import styles from "./OnboardingBannerCard.module.css"
import typo from "../../typography.module.css"
import Button from "../Button"
import { useNavigate } from "react-router-dom"
import BookingDialog from "../dialogs/BookingDialog"
import { useState } from "react"
import api from "../../api"
import { formatDateV2, formatTimeV2, getLocalDate } from "../../utils"
import { useTranslation } from "react-i18next"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"

const OnboardingBannerCard = ({ step }) => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const [bookingOpen, setBookingOpen] = useState(false)
    const [booking, setBooking] = useState(null)
    const [date, setDate] = useState(null)
    const [hour, setHour] = useState(null)

    const getBooking = async () => {
        try {
            let booking = await api.get("/bookings")
            setBooking(booking)

            if (booking.status === 'pending') {
                let parts = booking.preference.split(' - ');

                // Prendere la parte prima del primo ' - '
                let preferenceDate = parts[0];

                // Prendere la stringa intera dopo il primo ' - '
                let preferenceHour = booking.preference.slice(booking.preference.indexOf(' - ') + 3);

                let pDate = new Date(preferenceDate)
                setDate(formatDateV2(pDate, { weekday: 'long', day: 'numeric', month: 'long' }))
                setHour(preferenceHour)
            }
            else {
                let confirmedDate = new Date(booking.date)
                setDate(formatDateV2(confirmedDate, { weekday: 'long', day: 'numeric', month: 'long' }))
                setHour(formatTimeV2(confirmedDate))
            }

        }
        catch (e) {
            console.error(e)
        }
    }

    useState(() => {
        if (!booking) {
            getBooking()
        }
    }, [step, booking])

    return (
        <>
            <Card style={{
                height: '100%', padding: 0,
                backgroundImage: 'radial-gradient(at 0% 0%, hsla(188, 100%, 54%, 0.3) 0px, transparent 50%), radial-gradient(at 100% 50%, hsla(240,98%,74%,0.3) 0px, transparent 50%)' 
            }}>
                {step.banner &&
                    <div className={styles.cardInner}>
                        {!step.completed &&
                            <>
                                {['call', 'page'].includes(step.type) &&
                                    <>
                                        {step.banner.media_type === 'image' &&
                                            <div className={styles.bannerImage}>
                                                <img src={step.banner.media} alt="" />
                                            </div>
                                        }
                                        <div className={styles.bannerInfo}>
                                            <div className={typo.subtitle}>
                                                {step.banner.title}
                                            </div>
                                            <div className={typo.body}>
                                                {step.banner.description}
                                            </div>
                                            <Button style={{ padding: '1rem 2rem' }} onClick={() => {
                                                if (step.type === 'call') {
                                                    setBookingOpen(true)
                                                }
                                                else if (step.banner.url.startsWith('#')) {
                                                    let elem = document.querySelector(step.banner.url)
                                                    if (elem) {
                                                        elem.scrollIntoView({ behavior: 'smooth' })
                                                    }
                                                }
                                                else {
                                                    navigate(step.banner.url)
                                                }
                                            }}>
                                                {step.banner.call_to_action.toUpperCase()}
                                            </Button>
                                        </div>
                                    </>
                                }
                                {['survey', 'personalities'].includes(step.type) &&
                                    <>
                                        {step.banner.media_type === 'image' &&
                                            <div className={styles.bannerImage}>
                                                <img src={step.banner.media} alt="" />
                                            </div>
                                        }
                                        <div className={styles.bannerInfo}>
                                            <div className={typo.subtitle}>
                                                {step.banner.title}
                                            </div>
                                            <div className={typo.body}>
                                                {step.banner.description}
                                            </div>
                                            <Button
                                                // disabled={step.completed}
                                                style={{ padding: '1rem 2rem' }} onClick={() => {
                                                    navigate(step.banner.url)
                                                }}>{step.banner.call_to_action.toUpperCase()}</Button>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {step.completed &&
                            <>
                                {step.type === 'call' && booking &&
                                    <div className={styles.call}>
                                        <div className={typo.subtitle}>
                                            {step.banner.title}
                                        </div>
                                        <div className={styles.spacer} />
                                        {step.banner.media_type === 'image' && booking.status !== 'pending' &&
                                            <div className={styles.bannerImage} style={{width: 'fit-content', height: '160px'}}>
                                                <img src={step.banner.media} style={{paddingTop: '3rem'}} alt="" />
                                            </div>
                                        }
                                        <div className={styles.content}>
                                            <div className={styles.callRows}>
                                                <div className={styles.callRow}>
                                                    <div className={typo.headline}>STATO</div>
                                                    <div className={`${typo.body} ${styles.status} ${styles[`${booking.status}`]}`}>{t(`bookings.status.${booking.status}`)}</div>
                                                </div>
                                                {booking.status === 'pending' &&
                                                    <>
                                                        <div className={styles.callRow}>
                                                            <div className={typo.headline}>CONTATTO</div>
                                                            <div className={typo.body}><strong>{booking.contact}</strong></div>
                                                        </div>
                                                        <div className={styles.callRow}>
                                                            <div className={typo.headline}>PREFERENZA</div>
                                                            <div className={typo.body}><strong>{date}</strong>, {hour}</div>
                                                        </div>
                                                    </>
                                                }
                                                {booking.status === 'confirmed' &&
                                                    <>
                                                        <div className={styles.callRow}>
                                                            <div className={typo.headline}>DATA E ORA</div>
                                                            <div className={typo.body}><strong>{date}</strong>, {hour}</div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className={styles.callRows}>
                                                {booking.status === 'confirmed' &&
                                                    <>
                                                        <div className={styles.callRow}>
                                                            <div className={typo.headline}>CONSULENTE</div>
                                                            <div className={styles.admin}>
                                                                <img src={booking.admin.picture ?? UserPlaceholder} className={styles.adminPicture} alt="" />
                                                                <div className={typo.body}><strong>{booking.admin.name} {booking.admin.surname}</strong></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {booking.status === 'pending' &&
                                            <div className={typo.caption} style={{ paddingBottom: '1rem' }}>
                                                La tua richiesta di contatto è stata presa in carico. Sarai contattato entro 48h da un nostro consulente per definire il giorno e l'ora estatti in cui desideri effettuare la chiamata.
                                                Quando la prenotazione sarà confermata, il bottone seguente ti permetterà di accedere direttamente alla chiamata.
                                            </div>
                                        }
                                        {booking.status !== 'completed' &&
                                            <Button
                                                onClick={() => { window.location.href = booking.url }}
                                                fullWidth
                                                disabled={!booking.url || new Date() <= getLocalDate(booking.date)}
                                                accentColor={'var(--tertiary)'}
                                                style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                                                VAI ALLA CALL
                                            </Button>
                                        }
                                    </div>
                                }
                                {step.type === "page" &&
                                    <>
                                        {step.banner.media_type === 'image' &&
                                            <div className={styles.bannerImage}>
                                                <img src={step.banner.media} alt="" />
                                            </div>
                                        }
                                        <div className={styles.bannerInfo}>
                                            <div className={typo.subtitle}>
                                                {step.banner.title}
                                            </div>
                                            <div className={typo.body}>
                                                {step.banner.description}
                                            </div>
                                            <Button style={{ padding: '1rem 2rem' }} onClick={() => {
                                                if (step.banner.url.startsWith('#')) {
                                                    let elem = document.querySelector(step.banner.url)
                                                    if (elem) {
                                                        elem.scrollIntoView({ behavior: 'smooth' })
                                                    }
                                                }
                                                else {
                                                    navigate(step.banner.url)
                                                }
                                            }}>
                                                {step.banner.call_to_action.toUpperCase()}
                                            </Button>
                                        </div>
                                    </>
                                }
                                {step.type !== 'call' && step.type !== 'page' &&
                                    <>
                                        {step.banner.media_type === 'image' &&
                                            <div className={styles.bannerImage}>
                                                <img src={step.banner.media} alt="" />
                                            </div>
                                        }
                                        <div className={styles.bannerInfo}>
                                            <div className={typo.subtitle}>
                                                {step.banner.title}
                                            </div>
                                            <div className={styles.bannerDescription}>
                                                {step.banner.description}
                                            </div>
                                            <Button
                                                style={{ padding: '1rem 2rem' }}
                                                onClick={() => {
                                                    if (step.type === 'survey') {
                                                        navigate('/survey')
                                                    }
                                                    else{
                                                        navigate(step.banner.url)
                                                    }
                                                }}
                                            >
                                                {step.banner.call_to_action.toUpperCase()}
                                            </Button>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                }
            </Card>
            <BookingDialog open={bookingOpen} onClose={() => { setBookingOpen(false) }} onConfirm={() => {
                getBooking()
            }} />

        </>

    )
}

export default OnboardingBannerCard
