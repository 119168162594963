export const TestType = {
  SingleChoice: "single_choice",
  MultipleChoice: "multiple_choice",
  TrueFalse: "true_false",
  Matching: "matching",
  TextCompletion: "text_completion"
}

export const CorrectionType = {
  NumberOfErrors: "number_of_errors",
  Errors: "errors",
  Solutions: "solutions"
}


export const DialogStatus = {
  Default: "default",
  Loading: "loading",
  Success: "Success",
  Error: "Error"
}
