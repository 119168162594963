import styles from "./CartDropdown.module.css"
import typo from "../typography.module.css"
import { ReactComponent as CartIcon } from "../assets/images/icons/ic-cart-stroked.svg"
import { ReactComponent as CartFillIcon } from "../assets/images/icons/ic-cart.svg"
import { useContext, useEffect, useRef, useState } from "react"
import Button from "./Button"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AnimatePresence, motion } from "framer-motion"
import MainContext from "../common/MainContext"
import useClickOutside from "../common/hooks/useClickOutside"
import { Each } from "../common/Each"
import { formatPrice } from "../utils"
import api from "../api"
import CourseBadge from "./CourseBadge"
import Loader from "./Loader"

const CartDropdown = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [position, setPosition] = useState({ bottom: 0 })
    const [amount, setAmount] = useState(0)
    const [discountedAmount, setDiscountedAmount] = useState(0)
    const [discount, setDiscount] = useState(null)
    const [loading, setLoading] = useState(false)

    const dropdownRef = useRef(null)

    const context = useContext(MainContext)

    const handleOpen = async () => {
        if (context.dropdown === null) {
            context.setDropdown('cart')
        }
        else {
            context.setDropdown(null)
        }
    }

    const handleClickOutside = (event) => {
        if (context.dropdown === 'cart' && event.target.id !== 'cart-button') {
            context.setDropdown(null)
        }
    }

    useClickOutside(dropdownRef, handleClickOutside)

    useEffect(() => {
        handleSetPosition()
        if (context.cart) {
            let amount = context.cart.products.map(p => { return p.price })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            let discountedAmount = context.cart.products.map(p => { return p.discounted_price ? p.discounted_price : p.price })
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            setAmount(amount)
            setDiscountedAmount(discountedAmount)
            let discount = (amount - discountedAmount) / amount * 100
            setDiscount(Math.round(discount))
        }

    }, [dropdownRef, context.cart])


    const handleSetPosition = () => {
        if (dropdownRef.current) {
            setPosition({ bottom: dropdownRef.current.clientHeight + 4 })
        }
    }

    useEffect(() => {

        window.addEventListener('resize', handleSetPosition)

        return () => {
            window.removeEventListener('resize', handleSetPosition)
        }

    }, [])

    const removeCartItem = async (product_id) => {
        setLoading(true)
        try {
            let body = { product_id: product_id }
            let cart = await api.put("/cart/remove", body)
            context.setCart(cart)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    return (
        <>
            <div id="cart-button" className={styles.container} onClick={handleOpen}>
                {(context.cart?.products.length === 0 || !context.cart) &&
                    <CartIcon className={styles.cartIcon} />
                }
                {context.cart?.products.length > 0 &&
                    <>
                        <CartFillIcon className={styles.cartFillIcon} />
                        <div className={styles.badge}>
                            {context.cart.products.length}
                        </div>
                    </>
                }
            </div>
            <AnimatePresence>
                <motion.div
                    ref={dropdownRef}
                    style={{ bottom: `-${position.bottom}px` }}
                    className={styles.dropdown}
                    initial={{ opacity: 0, visibility: 'hidden' }}
                    animate={{
                        opacity: context.dropdown === 'cart' ? 1 : 0,
                        visibility: context.dropdown === 'cart' ? 'visible' : 'hidden'
                    }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {loading &&
                        <div className={styles.loadingOverlay}>
                            <Loader />
                        </div>
                    }
                            {(context.cart?.products.length === 0 || !context.cart) &&
                                <div className={styles.empty}>
                                    <div className={typo.headline}>Il tuo carrello è vuoto, cerca un corso che fa al caso tuo e aggiungilo qui per procedere all'acquisto!</div>
                                    <Button
                                        onClick={() => {
                                            context.setDropdown(null)
                                            navigate("/courses")
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', padding: '1rem 2rem' }} inverse>
                                        {t('myPath.goToCourses').toUpperCase()}
                                        <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                    </Button>
                                </div>
                            }
                            {
                                context.cart?.products.length > 0 &&
                                <>
                                    <div className={styles.header}>
                                        <div className={styles.title}>
                                            <div className={typo.subtitle} style={{ fontSize: '1rem' }}>Il mio carrello</div>
                                            <div className={styles.productNumber}>{context.cart.products.length}</div>
                                        </div>
                                        <Button appearance="text" style={{ fontSize: '1rem' }} onClick={() => {
                                            context.setDropdown(null)
                                            navigate("/cart")
                                        }}>vai al carrello</Button>
                                    </div>

                                    <div className={styles.products}>
                                        <Each of={context.cart.products} render={(product) => {
                                            return (
                                                <div className={styles.product}>
                                                    <img className={styles.productThumbnail} src={product.course.thumbnail} alt="" />

                                                    <div className={styles.productInfo}>
                                                        <CourseBadge type={product.course.type} style={{padding: '.1rem .3rem', fontSize: '0.625rem'}}/>
                                                        <div className={styles.productName}>
                                                            {product.name}
                                                        </div>
                                                        <button className={styles.deleteButton} onClick={() => { removeCartItem(product.id) }}>
                                                            Rimuovi <DeleteIcon />
                                                        </button>
                                                    </div>
                                                    <div className={styles.productPrice}>
                                                        € {formatPrice(product.discounted_price ? product.discounted_price : product.price)}
                                                    </div>
                                                </div>
                                            )
                                        }} />

                                        <div className={styles.total}>
                                            <div className={typo.headline} style={{ fontSize: '0.875rem' }}>Totale</div>
                                            <div className={styles.totalPrice}>€ {formatPrice(discountedAmount)}</div>
                                        </div>

                                        <div className={styles.actions}>
                                            <Button
                                                onClick={() => { context.setCheckout({open: true, order: null}) }}
                                                style={{ padding: '1rem', width: '100%' }}>
                                                VAI AL CHECKOUT
                                                <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            
                    }
                </motion.div>
            </AnimatePresence>
        </>
    )

}

export default CartDropdown