import { useEffect, useState } from "react"
import { Each } from "../../common/Each"
import { formatDateV2, formatTimeV2 } from "../../utils"
import styles from "./EditionLessonsCard.module.css"

const EditionLessonsCard = ({ edition, active, style }) => {

    const [lessons, setLessons] = useState([])

    useEffect(() => {
        if (edition) {
            let lessons = edition.modules.flatMap(module => module.lessons)
            setLessons(lessons)
        }
    }, [edition])

    return (
        <div className={`${styles.container} ${lessons.length > 2 ? styles.triple : styles.double} ${active ? styles.active : ''}`} style={style}>
            <Each of={lessons} render={(lesson, index) => {
                return (
                    <div className={`${styles.lessonContainer} ${lessons.length > 2 ? styles.triple : styles.double} ${active ? styles.active : ''}`}>
                        <div className={styles.lesson} >
                            <div className={styles.lessonHour}>
                                {formatTimeV2(lesson.starts_at)} - {formatTimeV2(lesson.ends_at)}
                            </div>
                            <div className={styles.lessonDay}>
                                {formatDateV2(lesson.starts_at, {weekday: 'short', day: 'numeric', month: 'short'})}
                            </div>
                        </div>
                    </div>
                )
            }} />
            {lessons.length === 3 &&
                <div className={styles.lessonContainer} style={{ width: '100%', background: '#F0F9F6' }}>
                    <div className={styles.lesson} style={{ flex: 1 }}></div>
                </div>
            }
        </div>
    )
}

export default EditionLessonsCard