import { useEffect, useState } from "react";
const RadarChart = ({ data, options }) => {

    const [grid, setGrid] = useState([])
    const [shapes, setShapes] = useState([])
    const [dots, setDots] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        var grid = []
        for (let i = 0; i < options.grid.levels; i++) {
            var p = null
            if (i === 0) {
                p = roundedPolygon(
                    data[0]?.values.length,
                    options.grid.sideLength,
                    options.grid.borderRadius,
                    options.grid.paddingVertical,
                    options.grid.paddingHorizontal,
                    options.grid.stroke,
                    options.grid.strokeWidth,
                    options.grid.fill
                )
            }
            else {
                let center = { x: grid[0].width / 2, y: grid[0].height / 2 }
                let path = generateShape(
                    grid[0].points,
                    center,
                    data[0].values.map(d => { return (100 / options.grid.levels * (i)) + (4 / 15 * options.grid.borderRadius) }),
                    options.grid.borderRadius * 0.06
                )

                p = {
                    path: path,
                    stroke: options.grid.stroke,
                    strokeWidth: options.grid.strokeWidth,
                }
            }
            grid.push(p)
        }
        setGrid(grid);

        if (options && data && grid[0]) {
            let center = { x: grid[0].width / 2, y: grid[0].height / 2 }
            var newShapes = []
            for (let d of data) {
                let path = generateShape(grid[0].points, center, d.values, d.tension)
                let shape = {
                    path: path,
                    tension: d.tension,
                    stroke: d.stroke,
                    strokeWidth: d.strokeWidth,
                    fill: d.fill
                }
                newShapes.push(shape)
            }
            setShapes(newShapes)

            if (options.labels) {
                if (options.labels.dots) {
                    let points = getDotPoints(grid[0].points, center, options.labels.dotsDelta ?? 1.15)
                    let newDots = []
                    for (let index in points) {
                        let c = {
                            cx: points[index].x,
                            cy: points[index].y,
                            color: options.labels.dotColors[index ?? '#000000'],
                            r: options.labels.dotsRadius
                        }
                        newDots.push(c)
                    }
                    setDots(newDots)
                }

                let points = getDotPoints(grid[0].points, center, options.labels.dotsDelta ?? 1.15)
                generateLabels(points, center, options.labels.padding, data[0].labels, options.labels.font)
            }
        }

    }, [data, options])



    function fixFloat(value) {
        var fixed = +value.toPrecision(14);
        if (Math.abs(fixed) < 1e-13) {
            fixed = 0;
        }
        return fixed;
    }

    function roundedPolygon(N, L, R, paddingV, paddingH, stroke, strokeWidth, fill) {
        paddingV = paddingV || 0
        paddingH = paddingH || 0

        var half = (N - 2) * Math.PI / N / 2,
            sin = Math.sin(half),
            cos = Math.cos(half),
            gap = L - 2 / Math.tan(half) * R,
            round = 2 * cos * R,
            D = L / cos,
            offsetY = 0;

        if (N % 2) {
            var vertial = D / 2 + D / 2 * sin;
            D = Math.sqrt(Math.pow(L / 2, 2) + Math.pow(vertial, 2));
            offsetY = (D - vertial) / 2;
        }

        D += 2 * paddingH;

        function getQuadrant(x) {
            return Math.floor(((x + 2 * Math.PI) % (2 * Math.PI)) / (Math.PI / 2)) + 1;
        }

        var points = [[
            0,
            R / sin - R * sin + paddingV + offsetY
        ]],
            angles = [half - Math.PI / 2],
            horizontalCut = 0;

        for (var i = 1; i <= N; i += 1) {
            var prev = angles[i - 1],
                next = prev + Math.PI - 2 * half,
                middle = (prev + next) / 2;

            var prevQ = getQuadrant(prev),
                nextQ = getQuadrant(next);

            if (prevQ === 1 && nextQ >= 2 && nextQ <= 3) {
                horizontalCut = Math.cos(Math.abs(middle - Math.PI / 2)) * R / sin - R;
            }

            angles.push(next);
            points.push([
                Math.cos(middle) * round,
                Math.sin(middle) * round
            ]);
            if (i !== N) {
                points.push([
                    Math.cos(next) * gap,
                    Math.sin(next) * gap
                ]);
            }
        }

        var vertialCut = R / sin - R;

        if (N % 2) {
            D -= horizontalCut * 2;
            points[0][1] -= (horizontalCut * 2 + vertialCut) / 2;
        } else {
            D -= vertialCut * 2;
            points[0][1] -= vertialCut;
        }
        points[0][0] = D / 2 - cos * R;

        var width = Math.ceil(D),
            delta = (width - D) / 2;
        points[0][0] += delta;
        points[0][1] += delta;

        var list = [];
        points.forEach(function (p, index) {
            var x = fixFloat(p[0]),
                y = fixFloat(p[1]);
            if (index === 0) {
                list.push('M' + x + ' ' + y);
            } else if (index % 2) {
                list.push('a' + R + ' ' + R + ' 0 0 1 ' + x + ' ' + y);
            } else {
                list.push('l' + x + ' ' + y);
            }
        });

        // Aggiungi il comando di chiusura del percorso
        list.push('Z');

        var path = list.join('');


        //Calcolo punti assoluti 
        var cardinalPts = [{ x: points[0][0], y: points[0][1] }]

        for (let i = 1; i < points.length; i++) {
            let p = { x: points[i][0] + cardinalPts[i - 1].x, y: points[i][1] + cardinalPts[i - 1].y }
            cardinalPts.push(p)
        }

        let maxYPoint = cardinalPts.reduce((maxY, point) => point.y > maxY.y ? point : maxY, cardinalPts[0]);

        // Trova il punto con la minima coordinata y
        let minYPoint = cardinalPts.reduce((minY, point) => point.y < minY.y ? point : minY, cardinalPts[0]);

        // Calcola la distanza tra i punti maxYPoint e minYPoint
        let minHeight = Math.sqrt(Math.pow(maxYPoint.x - minYPoint.x, 2) + Math.pow(maxYPoint.y - minYPoint.y, 2));

        let height = minHeight + (2 * paddingV);

        return {
            width: width,
            height: height,
            path: path,
            stroke: stroke,
            strokeWidth: strokeWidth,
            fill: fill,
            points: points
        };
    }

    function findMidpoint(point1, point2) {
        const midpointX = (point1.x + point2.x) / 2;
        const midpointY = (point1.y + point2.y) / 2;

        return { x: midpointX, y: midpointY };
    }

    function angleDegrees(p1, p2, p3) {
        const vettore12 = { x: p2.x - p1.x, y: p2.y - p1.y };
        const vettore13 = { x: p3.x - p2.x, y: p3.y - p2.y };

        // Calcola il prodotto scalare
        const prodottoScalare = (v1, v2) => v1.x * v2.x + v1.y * v2.y;

        // Calcola le lunghezze dei vettori
        const lunghezza12 = Math.sqrt(prodottoScalare(vettore12, vettore12));
        const lunghezza13 = Math.sqrt(prodottoScalare(vettore13, vettore13));

        // Calcola l'angolo in radianti
        const angoloRadianti = Math.acos(prodottoScalare(vettore12, vettore13) / (lunghezza12 * lunghezza13));

        // Converte l'angolo in gradi
        const degree = angoloRadianti * (180 / Math.PI);

        if (checkConvex(p1, p2, p3)) {
            return 180 + degree
        }
        return 180 - degree

    }

    function checkConvex(p1, p2, p3) {
        const v1 = { x: p1.x - p2.x, y: p1.y - p2.y };
        const v2 = { x: p3.x - p2.x, y: p3.y - p2.y };

        const prodottoVettoriale = v1.x * v2.y - v1.y * v2.x;

        return prodottoVettoriale >= 0;
    }

    function generateRoundedPath(points, radius = 0) {
        if (points.length < 3) {
            throw new Error('Sono necessari almeno tre punti per generare un percorso chiuso.');
        }

        radius = -radius

        var path = ``;

        for (let i = 0; i < points.length; i++) {
            var startPoint = (i === 0) ? points[points.length - 1] : points[i - 1]
            var endPoint = points[i];
            var nextPoint = points[(i + 1) % points.length];

            const angle = Math.atan2(nextPoint.y - endPoint.y, nextPoint.x - endPoint.x) - Math.atan2(startPoint.y - endPoint.y, startPoint.x - endPoint.x);

            const angleDegree = angleDegrees(startPoint, endPoint, nextPoint)
            const angleRadius = radius / 70 * angleDegree - (radius / 7)

            const distance = angleRadius / Math.tan(angle / 2);

            var startControl = {
                x: endPoint.x + distance * Math.cos(Math.atan2(startPoint.y - endPoint.y, startPoint.x - endPoint.x)),
                y: endPoint.y + distance * Math.sin(Math.atan2(startPoint.y - endPoint.y, startPoint.x - endPoint.x))
            };

            var endControl = {
                x: endPoint.x + distance * Math.cos(Math.atan2(nextPoint.y - endPoint.y, nextPoint.x - endPoint.x)),
                y: endPoint.y + distance * Math.sin(Math.atan2(nextPoint.y - endPoint.y, nextPoint.x - endPoint.x))
            };

            const convex = angleDegree > 180

            if (convex) {
                var temp = startControl
                startControl = endControl
                endControl = temp
            }


            if (i === 0) {
                path = `M${startControl.x} ${startControl.y}`
            }
            path += ` L${startControl.x} ${startControl.y} A${angleRadius} ${angleRadius} 0 0 ${convex ? "0" : "1"} ${endControl.x} ${endControl.y}`;

        }

        path += ' Z';

        return path;
    }

    function splitArrayIntoPairs(array, n) {
        if (n <= 0) {
            throw new Error('La dimensione delle coppie deve essere maggiore di zero.');
        }

        const pairs = [];
        for (let i = 0; i < array.length; i += n) {
            pairs.push(array.slice(i, i + n));
        }

        return pairs;
    }

    function interpolatePoint(p1, p2, percentage) {

        const t = percentage / 100;
        const x = (1 - t) * p1.x + t * p2.x;
        const y = (1 - t) * p1.y + t * p2.y;

        return { x: fixFloat(x), y: fixFloat(y) };
    }

    function generateShape(points, center, values, tension) {
        var cardinalPts = [{ x: points[0][0], y: points[0][1] }]


        for (let i = 1; i < points.length; i++) {
            let p = { x: points[i][0] + cardinalPts[i - 1].x, y: points[i][1] + cardinalPts[i - 1].y }
            cardinalPts.push(p)
        }

        let splitted = splitArrayIntoPairs(cardinalPts, 2)

        var shapePoints = []
        for (let cp of splitted) {
            let midPoint = findMidpoint(cp[0], cp[1])
            shapePoints.push(midPoint)
        }

        var finalPoints = []
        for (let i = 0; i < shapePoints.length; i++) {

            let fp = interpolatePoint(center, shapePoints[i], values[i])
            finalPoints.push(fp)
        }

        return generateRoundedPath(finalPoints, tension * 10)
    }

    function getDotPoints(points, center, delta) {
        var cardinalPts = [{ x: points[0][0], y: points[0][1] }]


        for (let i = 1; i < points.length; i++) {
            let p = { x: points[i][0] + cardinalPts[i - 1].x, y: points[i][1] + cardinalPts[i - 1].y }
            cardinalPts.push(p)
        }

        let splitted = splitArrayIntoPairs(cardinalPts, 2)

        var shapePoints = []
        for (let cp of splitted) {
            let midPoint = findMidpoint(cp[0], cp[1])
            shapePoints.push(midPoint)
        }

        var finalPoints = []
        for (let i = 0; i < shapePoints.length; i++) {

            let fp = interpolatePoint(center, shapePoints[i], 100 * delta)
            finalPoints.push(fp)
        }

        return finalPoints
    }

    function generateLabels(points, center, padding, labels, font) {
        var newLabels = []
        for (let i in points) {

            var textAnchor = 'middle'
            var paddingY = 0
            var x = 0
            var y = 0

            if (points[i].x < center.x - 10) {
                textAnchor = 'end'
                x = points[i].x < center.x ? points[i].x - padding : points[i].x + padding
                y = points[i].y + 1.5 * paddingY
            }
            if (points[i].x > center.x + 10) {
                textAnchor = 'start'
                x = points[i].x < center.x ? points[i].x - padding : points[i].x + padding
                y = points[i].y + 1.5 * paddingY
            }

            if (textAnchor === 'middle') {
                if (points[i].y < center.y) {
                    paddingY = -padding
                }
                else {
                    paddingY = padding
                }
                x = center.x
                y = points[i].y + 1.5 * paddingY
            }



            let label = {
                text: labels[i],
                x: x,
                y: y,
                textAnchor: textAnchor,
                fontFamily: font.family,
                fontSize: font.size,
                fontWeight: font.weight
            }
            newLabels.push(label)
        }

        setLabels(newLabels)

    }

    return (
        <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', width: '100%', height: 'fit-content' }}>
            {grid && grid[0] &&
                <svg xmlns="http://www.w3.org/2000/svg" width={grid[0].width} height={grid[0].height} viewBox={`0 0 ${grid[0].width} ${grid[0].height}`}>
                    {grid.map((polygon, index) => {
                        if (index === 0) {
                            return (

                                <path key={`grid-${index}`} stroke={polygon.stroke} strokeWidth={polygon.strokeWidth} fill={polygon.fill} d={polygon.path}>
                                </path>

                            )
                        }
                        else {
                            return (
                                <path key={`grid-${index}`} stroke={polygon.stroke} strokeWidth={polygon.strokeWidth} fill='none' d={polygon.path}></path>
                            )
                        }
                    })}
                    {shapes.map((shape, index) => {
                        return (
                            <path key={`shape-${index}`} fill={shape.fill} stroke={shape.stroke} strokeLinejoin="round" strokeWidth={shape.strokeWidth} d={shape.path}></path>
                        )
                    })}
                    {dots.map((dot, index) => {
                        return (
                            <circle key={`dot-${index}`} cx={dot.cx} cy={dot.cy} r={dot.r} fill={dot.color}></circle>
                        )
                    })}
                    {labels.map((label, index) => {
                        return (
                            <text
                                key={`label-${index}`}
                                x={label.x}
                                y={label.y + options.labels.dotsRadius / 2 + 1}
                                textAnchor={label.textAnchor}
                                fontFamily={label.fontFamily}
                                fontSize={label.fontSize}
                                fontWeight={label.fontWeight}
                            >{label.text}</text>
                        )
                    })}
                </svg>
            }
        </div>
    )
}

export default RadarChart