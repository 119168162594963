import styles from "./DropdownSelection.module.css"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Each } from "../common/Each"
import useClickOutside from "../common/hooks/useClickOutside"

const DropdownSelection = ({
    search = false,
    defaultOption = null,
    disabled = false,
    options = [],
    placeholder = "",
    onSelect = () => { },
    appereance = "default",
    style = {},
    required = false
}) => {
    const [value, setValue] = useState(null)
    const [open, setOpen] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState([])
    const [searchText, setSearchText] = useState("")
    const [error, setError] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    useEffect(() => {
        if (value !== null) {
            onSelect(value?.id)
        } else {
            onSelect(null)
        }
    }, [value])

    useEffect(() => {
        if (options.length > 0 && defaultOption !== null && defaultOption !== undefined) {
            const value = options.find(o => o.id === defaultOption)
            setValue(value)
        }
    }, [defaultOption])

    useClickOutside(ref, () => {
        setOpen(false)

        if (required && !value) {
            setError(true)
        }
    })


    const className = useMemo(() => {
        switch (appereance) {
            case "default": return {
                container: styles.container,
                optionsContainer: styles.options,
                option: styles.option,
            }
            case "transparent": return {
                container: styles.transparent,
                optionsContainer: styles.inverseOptions,
                option: styles.inverseOption,
            }
            case "embedded": return {
                container: styles.embedded,
                optionsContainer: styles.inverseOptions,
                option: styles.inverseOption,
            }
            default: console.error("Invalid appereance"); break;
        }
    }, [])

    const handleOnKeyUp = useCallback((event) => {
        setValue(null)
        setSearchText(event.target.value)
    }, [])

    useEffect(() => {
        if (searchText) {
            const filtered = options
                .filter(o => o.label.toLowerCase().includes(searchText.toLowerCase()))
                .sort((a, b) => {
                    const indexA = a.label.toLowerCase().indexOf(searchText.toLowerCase());
                    const indexB = b.label.toLowerCase().indexOf(searchText.toLowerCase());
                    return indexA - indexB;
                });
            setFilteredOptions(filtered)
            setOpen(true)
        } else {
            setFilteredOptions(options)
        }
    }, [searchText])

    return (
        <div ref={ref} className={className.container}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            onClick={() => {
                if (!disabled) {
                    setOpen((open) => !open)
                }
            }}
            style={{
                opacity: disabled ? "0.5" : "1",
                cursor: disabled ? "default" : "pointer",
                border: error ? "1px solid var(--secondary)" : "1px solid rgba(var(--text-color-rgb), 12%)",
                ...style
            }}
        >
            {
                !search && value && <div>{value.label ?? "missing label"}</div>
            }
            {
                !search && !value && <div className={styles.placeholder}>{placeholder}</div>
            }
            {
                search &&
                <input
                    disabled={disabled}
                    value={searchText || value?.label || ""}
                    type={"text"}
                    className={styles.searchInput}
                    placeholder={placeholder}
                    onFocus={() => {
                        if (!disabled) {
                            setOpen(true)
                        }
                    }}
                    onChange={handleOnKeyUp}
                    onKeyUp={handleOnKeyUp} />
            }
            <ChevronIcon className={`${styles.arrow} ${open ? styles.up : styles.down}`} />
            <div className={`${className.optionsContainer} ${open ? styles.open : styles.hidden}`}>
                {
                    filteredOptions.length > 0 &&
                    <Each
                        of={filteredOptions}
                        render={(option, index) => (
                            <>
                                <div className={className.option} onClick={(e) => {
                                    e.stopPropagation()
                                    setSearchText(null)
                                    setError(false)
                                    setValue({
                                        id: option.id ?? index,
                                        label: option?.label ?? option,
                                    })
                                }}>
                                    {option?.label ?? option}
                                </div>
                                {index !== filteredOptions.length - 1 && <div className={styles.divider} />}
                            </>
                        )}
                    />
                }
                {
                    filteredOptions.length === 0 &&
                    <div className={styles.noResult}>
                        {"Nessun risultato"}
                    </div>
                }
            </div>
        </div>
    )
}

export default DropdownSelection
