import { useTranslation } from "react-i18next"
import styles from "./TrueFalseBadge.module.css"
import { useMemo } from "react"

const TrueFalseBadge = ({ type = false, value = null, highlighted = false, onChange = null }) => {
  const { t } = useTranslation()

  const className = useMemo(() => {
    let r = ""
    if (type === false) {
      r = `${styles.container} ${styles.false}`
      if (value === null) {
        r = highlighted ? `${styles.container} ${styles.false} ${styles.correct}` : `${styles.container} ${styles.false}`
      } if (value === false) {
        r = `${styles.container} ${styles.false} ${styles.checked}`
      }
    } else {
      r = `${styles.container} ${styles.true}`
      if (value === null) {
        r = highlighted ? `${styles.container} ${styles.true} ${styles.correct}` : `${styles.container} ${styles.true}`
      } if (value === true) {
        r = `${styles.container} ${styles.true} ${styles.checked}`
      }
    }

    return r
  }, [highlighted, value, type])

  return (
    <>
      {
        !type &&
        <div
          style={{ cursor: onChange ? "pointer" : "default", pointerEvents: onChange === null ? "none" : "initial" }}
          onClick={() => {
            if (!onChange) {
              return
            }

            if (value === false) {
              onChange(null)
            } else {
              onChange(false)
            }
          }}
          className={className}>
          {t("false").toUpperCase()}
        </div>
      }
      {
        type &&
        <div
          style={{ cursor: onChange ? "pointer" : "default", pointerEvents: onChange === null ? "none" : "initial" }}
          onClick={() => {
            if (!onChange) {
              return
            }

            if (value === true) {
              onChange(null)
            } else {
              onChange(true)
            }
          }}
          className={className}>
          {t("true").toUpperCase()}
        </div>
      }
    </>
  )
}


export default TrueFalseBadge
