import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg";
import { TagType } from "../../common/constants";
import { Each } from "../../common/Each";
import typo from "../../typography.module.css";
import { formatPrice } from "../../utils";
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart.svg";
import Button from "../Button";
import CourseBadge from "../CourseBadge";
import styles from "./LargeCourseCard.module.css";

const LargeCourseCard = ({ course }) => {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [discount, setDiscount] = useState(null)

    useEffect(() => {
        if (course) {
            if (course.product) {
                let discount = (course.product.price - course.product.discounted_price) / course.product.price * 100
                setDiscount(Math.round(discount))
            }
        }
    }, [course])

    return (

        <div className={styles.container}>
            <div className={styles.courseLeft}>
                <CourseBadge type={course.type}></CourseBadge>
                <div className={typo.subtitle}>{course.name}</div>
                <img className={styles.thumbnailMobile} src={course.thumbnail} alt="course-thumbnail-mobile" />

                <div className={[typo.body, styles.description].join(' ')}>{course.description}</div>
                <div className={styles.featureRow}>
                    <div className={styles.feature}>
                        <BookIcon></BookIcon> {course.program.length} moduli
                    </div>
                    <div className={styles.feature}>
                        <ClockIcon></ClockIcon> {course.duration} ore
                    </div>
                    <Each of={course.tags} render={(tag) => {
                        if (tag.type === TagType.Feature) {
                            return (
                                <div className={styles.feature}>
                                    <img src={tag.icon} alt={tag.name} /> {tag.name}
                                </div>
                            )
                        }
                    }} />
                </div>
                <div className={styles.priceRow}>
                    {discount &&
                        <>
                            <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.discounted_price)}€</div>
                            <div className={styles.discount}><div>{formatPrice(course.product.price)}€</div> {t('professional.discountOf')} {discount}%</div>
                        </>
                    }
                    {!discount &&
                        <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.price)}€</div>
                    }
                </div>
                <div className={styles.actionRow}>
                    <div>
                        <Button 
                            additionalClass={styles.action}
                            style={{ fontSize: '1.15rem', width: '100%', padding: '.5rem' }}
                            onClick={() => {
                                navigate(`/courses/${course.slug}`)
                            }}>
                            <CartIcon></CartIcon>{t('courses.purchase').toUpperCase()}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.courseRight}>
                <img className={styles.thumbnail} src={course.thumbnail} alt="course-thumbnail" />
            </div>
        </div>

    )

}

export default LargeCourseCard