import { useEffect, useState } from "react"
import styles from "./Question.module.css"

const Question = ({ index, total, question, onSelect = () => { } }) => {

    const [selected, setSelected] = useState(question.value)
    const [q, setQ] = useState(question)

    useEffect(() => {

        const handleKey = (e) => {
            if (["1", "2", "3", "4", "5"].includes(e.key)) {
                setSelected(Number(e.key))
                onSelect(question.id, Number(e.key))
            }
        }
        window.addEventListener("keydown", handleKey)

        setQ(question)
        setSelected(question.value)

        return () => {
            window.removeEventListener("keydown", handleKey)
        }
    }, [question])

    function getScale(n) {
        switch (n) {
            case 1:
                return 1.7;
            case 2:
                return 1.25;
            case 3:
                return 1;
            case 4:
                return 1.25;
            case 5:
                return 1.7;
            default:
                throw new Error("Il numero deve essere compreso tra 1 e 5");
        }
    }

    function getColor(n) {
        switch (n) {
            case 1:
                return { hex: '#6D4AFF', rgb: '109, 74, 255' };
            case 2:
                return { hex: '#574CFC', rgb: '87, 76, 252' };
            case 3:
                return { hex: '#415DFA', rgb: '65, 93, 250' };
            case 4:
                return { hex: '#2C7EF7', rgb: '44, 126, 247' };
            case 5:
                return { hex: '#16B4FA', rgb: '22, 180, 250' };
            default:
                throw new Error("Il numero deve essere compreso tra 1 e 5");
        }
    }

    const values = [
        {
            value: 1,
            label: "Totalmente in disaccordo"
        },
        {
            value: 2,
            label: "In disaccordo"
        },
        {
            value: 3,
            label: "Neutrale"
        },
        {
            value: 4,
            label: "D'accordo"
        },
        {
            value: 5,
            label: "Totalmente d'accordo"
        }
    ]

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {q.question}
            </div>
            <div className={styles.choices}>
                {values.map((el) => {
                    return (
                        <div
                            style={{
                                '--color': getColor(el.value).hex,
                                '--color-rgb': getColor(el.value).rgb
                            }}
                            key={el.value}
                            className={`${styles.choice} ${selected === el.value ? styles.selected : ''}`}
                            onClick={() => {
                                setSelected(el.value)
                                onSelect(question.id, el.value)
                            }}>
                            <div className={styles.valueWrapper}>
                                <div
                                    style={{
                                        height: `${getScale(el.value) * 50}px`,
                                        width: `${getScale(el.value) * 50}px`,
                                    }}
                                    className={`${styles.value} ${selected === el.value ? styles.selected : ''}`}>
                                    {el.value}
                                </div>
                            </div>
                            <div>{el.label}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Question