import styles from "./Releases.module.css"
import React, { useContext, useEffect } from "react"
import typo from "../typography.module.css"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { useTranslation } from "react-i18next"
import MainContext from "../common/MainContext"
import { Helmet, HelmetProvider } from "react-helmet-async"

const Releases = () => {

    const { t } = useTranslation()
    const context = useContext(MainContext)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Releases</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            Ultimi Aggiornamenti
                        </div>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.67
                            </div>
                            <div className={typo.headline}>
                                16 Maggio 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Redesign Corsi e Masterclass</strong><br></br>
                               Eseguito il redesign dello shop nel dettaglio masterclass e corsi professionalizzanti. Sono 
                               state aggiunte le recensioni, le faq e un nuovo slider docenti.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.66
                            </div>
                            <div className={typo.headline}>
                                4 Aprile 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Messaggi</strong><br></br>
                                Aggiunta la pagina dei messaggi con integrata live chat. <br></br><br></br>
                                Features:<br></br>
                                • Messaggi live con ricevuta di lettura<br></br>
                                • Notifiche in app<br></br>
                                • Possibilità di allegare immagini, video e file.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.65
                            </div>
                            <div className={typo.headline}>
                                15 Marzo 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Aggiunte Notifiche</strong><br></br>
                                Aggiunta la pagina delle notifiche e completata la dropdown delle notifiche.
                            </li>
                            <li>
                                <strong>Aggiunte Notifiche Push</strong><br></br>
                                Aggiunto il supporto alla ricezione delle notifiche push sia con app aperta che chiusa (desktop e mobile.)
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.64
                            </div>
                            <div className={typo.headline}>
                                6 Marzo 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Aggiunta pagina del Carrello</strong><br></br>
                                Aggiunta la pagina del carrello con il riepilogo dei prodotti e la form di pagamento.
                            </li>
                            <li>
                                <strong>Blocco prodotti già acquistati</strong><br></br>
                                Aggiunta il blocco per i prodotti già acquistati, non sarà più possibile aggiungerli al carrello.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.63
                            </div>
                            <div className={typo.headline}>
                                1 Marzo 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Redesign Autenticazione</strong><br></br>
                                Modificata la logica di autenticazione. Adesso gli utenti non registrati possono accedere alle pagine carriera e corsi e dashboard, 
                                non gli viene più mostrata la barra di navigazione laterale che viene sostituita da una navigazione in alto.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.62
                            </div>
                            <div className={typo.headline}>
                                28 Febbraio 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Aggiunto il carrello</strong><br></br>
                                Aggiunta la possibilità di acquistare più prodotti contemporaneamente tramite la logica di carrello.
                                <br></br>
                                <strong>NB: Tutti i precedenti acquisti sono stati eliminati per motivi di test.</strong>
                                <br></br>
                                Known Issues: Al momento il carrello funziona solo in fast checkout. La grafica è una bozza.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.61
                            </div>
                            <div className={typo.headline}>
                                26 Febbraio 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Aggiunto Feed Masterclass</strong><br></br>
                                Aggiunto il feed nelle masterclass a cui si è iscritti. Al momento disponibili solo le card per le lezioni e per gli avvisi del docente.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.60
                            </div>
                            <div className={typo.headline}>
                                25 Febbraio 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>È ora possibile iscriversi alle masterclass acquistate</strong><br></br>
                                Aggiunta la pagina di iscrizione alla masterclass con la selezione delle 'classi' disponibili.
                            </li>
                            <li>
                                <strong>Bacheca Masterclass</strong><br></br>
                                È possibile accedere alla bacheca delle masterclass a cui si è iscritti.
                            </li>
                        </ul>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.1.59
                            </div>
                            <div className={typo.headline}>
                                18 Febbraio 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Aggiunti i pagamenti tramite Stripe</strong><br></br>Adesso è possibile utilizzare le carte, PayPal e klarna in versione sandbox per completare i pagamenti dei corsi.
                                La carta di test per far approvare i pagamenti è:<br></br> <br></br>
                                <strong>Numero:</strong><span style={{ backgroundColor: 'rgba(var(--primary-rgb), 20%)', padding: '.2rem .5rem', borderRadius: '8px' }}>4242 4242 4242 4242</span><br></br>
                                <strong>Scadenza:</strong> Qualsiasi nel futuro.<br></br>
                                <strong>CVV:</strong> Qualsiasi.
                            </li>
                            <li>
                                <strong>Corsi nella sezione 'il mio percorso'.</strong><br></br>
                                I corsi acquistati sono ora disponibili nella sezione del mio percorso.
                            </li>
                            <li>
                                <strong>Placeholders 'il mio percorso'.</strong><br></br>
                                Sono ora presenti i placholders in caso di assenza di corsi o lezioni.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )
}

export default Releases