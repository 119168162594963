import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg";
import MainContext from "../../common/MainContext";
import { PaymentStatus, Stripe } from "../../common/constants";
import typo from "../../typography.module.css";
import Button from "../Button";
import Loader from "../Loader";
import Succeeded from "../animated/Succeeded";
import Dialog from "./Dialog";
import styles from "./PaymentDialog.module.css";
import StripePaymentElement from "./StripePaymentElement";

const PaymentDialog = ({ open, onClose }) => {

    const context = useContext(MainContext)
    const navigate = useNavigate()
    const stripePromise = loadStripe(Stripe.publicKey)
    const [succeeded, setSucceeded] = useState(false)
    const [order, setOrder] = useState(null)
    const [creatingOrder, setCreatingOrder] = useState(false)

    const fonts = [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap'
        }
    ]

    const appearance = {
        theme: 'stripe',

        variables: {
            colorPrimary: '#4CB4FF',
            colorText: '#2A2A2A',
            fontFamily: 'Work Sans',
            borderRadius: '12px',
        }
    };

    useEffect(() => {
        const getOrder = async (orderNo) => {
            try {
                let order = await api.get(`/orders/${orderNo}`)
                setOrder(order)
                if (order.status === PaymentStatus.Succeeded) {
                    setSucceeded(true)
                }
                setOrder(order)
            }
            catch (e) {
                console.error(e)
            }
        }

        const createOrder = async () => {
            setCreatingOrder(true)
            try {
                let order = await api.get("/cart/checkout")
                setOrder(order)
            }
            catch (e) {
                if (e.code === 5) { //Already Exists
                    setSucceeded(true)
                }
            }
            setCreatingOrder(false)
        }
        if (open) {
            if (order) {
                getOrder(order.order_no)
            }
            else {
                createOrder()
            }
        }
    }, [open])

    return (
        <Dialog open={open} title="Completa il pagamento" onClose={onClose}>
            <div className={styles.container}>
                {succeeded &&
                    <>
                        <Succeeded />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                            <div className={typo.subtitle}>Corso già acquistato!</div>
                            <div className={typo.body} style={{ opacity: 0.88, textAlign: 'center' }}>
                                Sembra che tu abbia già acquistato questo corso. Puoi trovarlo nella sezione "il mio percorso".
                            </div>
                            <Button onClick={() => {
                                context.setCheckout({ open: false, order: null })
                                navigate("/my-path")
                            }} inverse>VAI AL MIO PERCORSO <ArrowIcon className={styles.arrow} /></Button>
                        </div>
                    </>
                }
                {order && !succeeded &&
                    <Elements stripe={stripePromise} options={{ clientSecret: order.client_secret, appearance: appearance, fonts: fonts }}>
                        <StripePaymentElement orderNo={order.order_no} amount={order.amount} />
                    </Elements>
                }
                {((!order && !succeeded) || creatingOrder) &&
                    <div style={{ display: 'flex', minHeight: '300px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <Loader />
                        <div className={typo.headline}>Caricando il pagamento...</div>
                    </div>
                }

            </div>
        </Dialog>
    )
}

export default PaymentDialog;
