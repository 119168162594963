import { calcLastUpdate, formatDateV2, formatTimeV2 } from "../../utils"
import Card from "./Card"
import styles from "./TestFeedCard.module.css"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as TestIcon } from "../../assets/images/icons/ic-test.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import TestCard from "./TestCard"

const TestFeedCard = ({ activity }) => {

    const { width } = useWindowDimensions()

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
            <div className={styles.cardHeader}>
                <div className={`${styles.icon} ${styles.test}`}>
                    <TestIcon style={{ width: '20px', height: '20px' }} />
                </div>
                <div className={styles.info}>
                    <div className={styles.title}>
                        Test
                    </div>
                    <div className={styles.subtitle}>
                        {activity.object.expires_at ? `Scadenza ${formatDateV2(activity.object.expires_at)} alle ${formatTimeV2(activity.object.expires_at)}` : ''}
                    </div>
                </div>
                <div className={styles.date}>
                    {activity.profile.name} {activity.profile.surname}, {calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
                    <ClockIcon className={styles.clockIcon} />
                </div>
            </div>
            <div className={styles.content}>
                <div className={`${styles.description} ${styles.communication}`}>
                    {activity.info?.message}
                </div>
                <TestCard test={activity.object} useVariant={true} />
            </div>
        </Card>
    )
}

export default TestFeedCard
