
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import typo from "../../typography.module.css"
import Button from "../Button"
import TeachersBadge from "../TeachersBadge"
import styles from "./ModuleCard.module.css"

const ModuleCard = ({ module, style }) => {

  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.container} style={style}>
      <div className={styles.left}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <div className={typo.subheadline}>
            {module.name}
          </div>
          <div
            className={`${typo.body} ${styles.description}`}
            style={{ opacity: 0.4 }}>
            {module.description}
          </div>
        </div>
        <Button
          onClick={() => {
            navigate(`/my-path/courses/${module.course.slug}/modules/${module.id}`)
          }}
          inverse
        >
          {t('courses.enter').toUpperCase()}
        </Button>
      </div>
      <div className={styles.right}>
        <div className={styles.thumbnail}>
          <img src={module.thumbnail ?? module.edition.thumbnail} alt="course-thumbnail"></img>
        </div>
        {width > 540 &&
          <TeachersBadge
            style={{ padding: 0 }}
            teachers={module.teachers}
            showQualification={false}
            pictureStyle={{ width: "32px", height: "32px" }}
          />
        }
        {width <= 540 &&
          <Button
            inverse
            fullWidth
            onClick={() => {
              navigate(`/my-path/courses/${module.course.slug}/modules/${module.id}`)
            }}>
            {t('courses.enter').toUpperCase()}
          </Button>
        }
      </div>
    </div>
  )

}

export default ModuleCard
