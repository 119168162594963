import { useTranslation } from "react-i18next"
import { CourseType } from "../common/constants"
import styles from "./CourseBadge.module.css"
import Skeleton from "./Skeleton"

const CourseBadge = ({ type, style, loading = false }) => {

    const { t } = useTranslation()

    return (
        <>
            {
                loading &&
                <Skeleton type="rect" width="124px" height="26px" borderRadius={"8px"} />
            }
            {!loading && type === CourseType.Masterclass &&
                <div className={`${styles.courseType} ${styles.masterclass}`} style={style}>{t(`courses.types.${type}`).toUpperCase()}</div>

            }
            {!loading && type === CourseType.Professional &&
                <div className={`${styles.courseType} ${styles.professional}`} style={style}>{t(`courses.types.${type}`).toUpperCase()}</div>

            }
        </>
    )

}

export default CourseBadge
