import { Each } from "../common/Each"
import styles from "./AutoplayVerticalCarousel.module.css"
import { palette } from "../common/constants"

const AutoplayVerticalCarousel = ({ elements, style, reverse, cardStyle, imgStyle }) => {

    return (
        <div className={styles.container} style={style}>
            <div className={styles.gradientLeft}></div>
            <div className={`${styles.track} ${elements.length >= 3 ? (reverse ? styles.animatedReverse : styles.animated) : ''}`}>
                <Each of={elements} render={(e, index) => {
                    const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                    const color = palette[colorIndex];
                    return (
                        <div style={{backgroundColor: `${color}30`, ...cardStyle}} className={`${styles.card} ${elements.length >= 3 ? styles.animated : ''}`}>
                            <img src={e} alt="" style={imgStyle}></img>
                        </div>
                    )
                }} />
                {elements.length > 3 &&
                <>
                    <Each of={elements} render={(e, index) => {
                        const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                        const color = palette[colorIndex];
                        return (
                            <div style={{backgroundColor: `${color}30`, ...cardStyle}} className={`${styles.card} ${elements.length >= 3 ? styles.animated : ''}`}>
                                <img src={e} alt="" style={imgStyle}></img>
                            </div>
                        )
                    }} />
                    <Each of={elements} render={(e, index) => {
                        const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                        const color = palette[colorIndex];
                        return (
                            <div style={{backgroundColor: `${color}30`, ...cardStyle}} className={`${styles.card} ${elements.length >= 3 ? styles.animated : ''}`}>
                                <img src={e} alt="" style={imgStyle}></img>
                            </div>
                        )
                    }} />
                </>
                }
            </div>
            <div className={styles.gradientRight}></div>
        </div>
    )

}

export default AutoplayVerticalCarousel