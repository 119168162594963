import { useEffect, useState } from "react"
import Button from "../Button"
import Dialog from "./Dialog"
import styles from "./NotificationPermissionDialog.module.css"
import Notifications3D from "../../assets/images/illustrations/3D/notifications.png"

const NotificationPermissionDialog = ({ open, onClose = () => { } }) => {

    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const isSupported = () =>
        'Notification' in window &&
        'serviceWorker' in navigator &&
        'PushManager' in window

    const getNotificationsToken = async () => {
        if (isSupported()) {
            await Notification.requestPermission()
        }
        handleClose()
    }

    const handleClose = () => {
        setIsOpen(false)
        localStorage.setItem('notifications', 'denied')
        onClose()
    }

    return (
        <Dialog title="" open={isOpen} onClose={handleClose} style={{ maxWidth: '500px', paddingBottom: '2.5rem' }}>
            <img className={styles.notificationImage} src={Notifications3D} alt="notifications-illustration" />
            <div className={styles.title}>
                Attiva le notifiche push
            </div>
            <div className={styles.message}>
                Sembra che tu non abbia attivato le notifiche push.<br></br> Attivale ora per ricevere aggiornamenti in tempo reale riguardo i corsi, le lezioni e i messaggi.
            </div>
            <div className={styles.caption}>
                Le notifiche <strong>NON</strong> saranno utilizzate per scopi di marketing o pubblicitari.
            </div>
            <Button
                style={{ padding: '.8rem 3rem' }}
                onClick={() => {
                    getNotificationsToken()
                }}>
                ATTIVA
            </Button>
        </Dialog>
    )
}

export default NotificationPermissionDialog