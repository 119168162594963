import styles from "./OnboardingCard.module.css"
import typo from "../../typography.module.css"
import Card from "./Card"
import Stepper from "../Stepper"
import { Each } from "../../common/Each"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"

const OnboardingCard = ({ onboarding, selectedStep }) => {

    return (
        <Card style={{ backgroundColor: "var(--background-color)", height: '100%', maxHeight: '380px' }}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={typo.subtitle} style={{ fontSize: '1.125rem', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Percorso di benvenuto</div>
                        <div>{parseInt(onboarding.steps.filter(s => s.completed).length / onboarding.steps.length * 100)}%</div>
                    </div>
                    <div className={typo.body} style={{ opacity: 0.64 }}>
                        Completa gli step per iniziare il tuo percorso di orientamento nel mondo economico-finanziario.
                    </div>
                </div>
                <Stepper nSteps={onboarding.steps.length} nCompleted={onboarding.steps.filter(s => s.completed).length} />
                <div className={styles.steps}>
                    <Each of={onboarding.steps} render={(step) => {
                        return (
                            <div className={`${styles.step} ${selectedStep.state.id === step.id ? styles.selected : ''}`}
                                onClick={() => {
                                    selectedStep.setState(step)
                                }}>
                                <div className={`${styles.check} ${step.completed ? styles.completed : ''}`}>
                                    <CheckIcon />
                                </div>
                                <div className={styles.stepTitle}>
                                    {step.name}
                                </div>
                                <div className={styles.innerSteps}>
                                    {step.actions.length > 1 &&
                                        `${step.actions.filter(a => a.completed).length }/${ step.actions.length }`
                                    }
                                </div>
                            </div>
                        )
                    }} />
                </div>
            </div>
        </Card>
    )

}

export default OnboardingCard