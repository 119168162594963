import styles from "./Tag.module.css"

const Tag = ({tag}) => {

    return(
        <div 
        className={styles.container} 
        style={{
            backgroundColor: `${tag.color}4D`, 
            color: tag.color, 
            fontSize: '0.625rem', 
            fontWeight: 800
        }}>
            {tag.name}
        </div>
    )
}

export default Tag