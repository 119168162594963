import { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { Each } from "../common/Each"
import { palette } from "../common/constants"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import AutoplayVerticalCarousel from "../components/AutoplayVerticalCarousel"
import Button from "../components/Button"
import Skeleton from "../components/Skeleton"
import CareerCard from "../components/cards/CareerCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import { splitArray } from "../utils"
import styles from "./Careers.module.css"

const Careers = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const [careers, setCareers] = useState([])
    const [myCareer, setMyCareer] = useState(null)
    const [splittedCareers, setSplittedCareers] = useState([])
    const [loading, setLoading] = useState(false)


    const skeletonHeights = generateSkeletonHeights(10)

    function generateSkeletonHeights(n) {
        const randomNumbers = [];

        for (let i = 0; i < n; i++) {
            const randomNumber = Math.floor(Math.random() * (350 - 200 + 1)) + 200;
            randomNumbers.push(randomNumber);
        }

        return randomNumbers;
    }

    const getCareers = async () => {
        setLoading(true)
        try {
            let careers = await api.get("/careers")
            let sorted = careers.sort((c1, c2) => { return c1.id - c2.id })
            setCareers(sorted)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    const getCareer = async () => {
        try {
            let myCareer = await api.get(`/user/career`)
            setMyCareer(myCareer)
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        getCareer()
        getCareers()

        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        var nColumns = 3
        if (width < 1170) {
            nColumns = 2
            if (width < 540) {
                nColumns = 1
            }
        }

        var splitted = []

        if (careers.length > 0) {
            splitted = splitArray(careers, nColumns)
        }

        //Per gli skeleton
        else {
            for (let i = 0; i < nColumns; i++) {
                splitted.push([])
            }
        }
        setSplittedCareers(splitted)

    }, [careers, width])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Carriere</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section} style={{ backgroundColor: 'var(--background-color)', paddingTop: 0, paddingBottom: 0 }}>
                    <div className={styles.sectionInner}>
                        <div className={styles.banner}>
                            <div className={styles.bannerLeft}>
                                <div className={typo.title}>
                                    {t('careers.title')}
                                </div>
                                <div className={typo.body} style={{ paddingBottom: '2rem' }}>
                                    {t('careers.description')}
                                </div>
                                <Button
                                    onClick={() => {
                                        let elem = document.querySelector('#careers')
                                        if (elem) {
                                            elem.scrollIntoView({ behavior: 'smooth' })
                                        }
                                    }}
                                    accentColor={'var(--tertiary)'}
                                    style={{ padding: '.7rem 1rem', fontSize: '1.125rem' }}
                                >
                                    SCOPRI LE CARRIERE
                                </Button>
                            </div>
                            <div className={styles.bannerRight}>
                                <AutoplayVerticalCarousel elements={careers.map(c => c.thumbnail).slice(0, 4)} />
                                <AutoplayVerticalCarousel reverse elements={careers.map(c => c.thumbnail).slice(careers.length - 5, careers.length - 1)} />
                            </div>
                        </div>
                    </div>
                </div>
                {myCareer &&
                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <div className={styles.myCareer}>
                                <div className={styles.gradient} />
                                <div className={styles.myCareerInfos}>
                                    <div className={styles.myCareerHeader}>
                                        <div className={typo.headline}>
                                            LA TUA CARRIERA
                                        </div>
                                        <div className={typo.subtitle}>
                                            {myCareer.name}
                                        </div>
                                    </div>
                                    {width <= 540 &&
                                        <img src={myCareer.thumbnail} className={styles.myCareerThumbnail} alt="" />
                                    }
                                    <div className={styles.myCareerDescription}>
                                        {myCareer.description}
                                    </div>
                                    <Button inverse fullWidth={width <= 540} onClick={() => {navigate(`/careers/${myCareer.slug}`)}}>VEDI INFO</Button>
                                </div>
                                {width > 540 &&
                                    <img src={myCareer.thumbnail} className={styles.myCareerThumbnail} alt="" />
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.header}>
                            <div className={typo.subtitle}>
                                {t('careers.title')}
                            </div>
                            <div className={typo.body}>
                                {t('careers.description')}
                            </div>
                        </div>
                        <div className={styles.careers} id="careers">
                            <Each of={splittedCareers} render={(column, c_index) => {
                                return (
                                    <div className={styles.column}>
                                        {!loading &&
                                            <Each of={column} render={(career, index) => {
                                                const colorIndex = (c_index + index) % palette.length
                                                career.color = palette[colorIndex]
                                                return (
                                                    <CareerCard career={career} />
                                                )
                                            }} />
                                        }
                                        {loading &&
                                            <Each of={[1, 2, 3]} render={(e, i) => {
                                                return (
                                                    <Skeleton type="rect" borderRadius="12px" width="100%" height={`${skeletonHeights[e * (c_index + i)]}px`} />
                                                )
                                            }} />
                                        }
                                    </div>
                                )
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Careers
