import styles from "./Card.module.css"

const Card = ({ children, style, onClick = () => { } }) => {

    return (
        <div className={styles.container} style={style} onClick={onClick}>
            {children}
        </div>
    )

}

export default Card
